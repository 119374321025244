import React, { Component } from 'react';

import CheckConfirmation from '../assets/check-confirmation';
import axios from 'axios';
import { wrapComponent } from '../utils';
import queryString from 'query-string';
import ImageHeaderSubHeader from '../components/hidden_pages/ImageHeaderSubheader';
import { linkExpired } from '../constants';
import Image from '../components/images/Image';
import { trackPageViewForCurrentPath } from '../tracking';

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: null,
      unknownError: false,
      knownError: false,
    };
  }

  componentDidMount() {
    trackPageViewForCurrentPath();

    const queryStringValues = queryString.parse(this.props.location.search);
    const userToken = queryStringValues.token;

    if (!userToken) {
      this.setState({
        unknownError: true,
      });
      return;
    }

    this.confirmEmailWithBackend(userToken);
  }

  async confirmEmailWithBackend(userToken) {
    try {
      const response = await axios.get(`/user/email/confirm/${userToken}/`);
      this.setState({
        success: response.data.success,
      });
    } catch (error) {
      const backendHandledStatuses = [404, 410, 412];
      if (
        error.response &&
        backendHandledStatuses.includes(error.response.status)
      ) {
        this.setState({
          success: error.response.data.success,
          knownError: true,
        });
      } else {
        this.setState({
          unknownError: true,
        });
      }
      console.error(error);
    }
  }

  textAndImageToDisplay() {
    if (this.state.unknownError) {
      return {
        header: 'Oops! There was a problem.',
        subheader: 'Please try refreshing, or try again in a few minutes.',
      };
    } else if (this.state.success) {
      return {
        header: 'Thanks! Your email address is confirmed.',
        subheader: 'Return to the Heartline app to continue.',
        image: <CheckConfirmation style={{ marginTop: '-1.3rem' }} />,
      };
    } else if (this.state.knownError) {
      return {
        header: 'This link has expired',
        subheader:
          'To confirm your email address, go back to the Heartline app and try again.',
        image: <Image src={linkExpired} alt="Link expired." dontUse2x />,
      };
    } else {
      return {
        header: '',
        subheader: '',
        image: '',
      };
    }
  }

  render() {
    return (
      <ImageHeaderSubHeader
        hasError={this.state.unknownError}
        contentToDisplay={this.textAndImageToDisplay()}
      />
    );
  }
}

export default wrapComponent(ConfirmEmail);
