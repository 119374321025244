import React from 'react';
import { Link } from '@reach/router';

import { Footnote, SubHead, SubHeadList } from '../LandingAssets';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import {
  cdcAtrialFibrillationUrl,
  FAQ_ROUTE,
  whatIPhoneModelScrollName,
  urlUnderstandingMedicare,
} from '../../constants';

export const aboutHeaderText = 'About the Study';
export const aboutQuestions = [
  {
    question: 'What is the Heartline Study?',
    answer: (
      <React.Fragment>
        <SubHead>
          The Heartline™ Study is a research study from Johnson & Johnson in
          collaboration with Apple. The study aims to analyze the impact of an
          app-based heart health program with Apple Watch on the early detection
          of irregular heart rhythms consistent with atrial fibrillation (Afib),
          and the potential to improve heart health outcomes, including reducing
          the risk of stroke and other cardiovascular conditions.
        </SubHead>
        <SubHead>
          Individuals with and without an existing AFib diagnosis may be
          eligible to participate. At the core of Heartline app is the Heartline
          engagement program. All participants will have access to tips and
          activities that may help improve your sleep, fitness, and wellness,
          and help you become more engaged with your overall health.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question:
      'What are the goals of the study? What are researchers looking to learn?',
    answer: (
      <React.Fragment>
        <SubHead>
          The primary objective is to assess if the use of everyday technology
          such as iPhone and Apple Watch, with a heart health engagement
          program, can help with early detection of AFib and potentially improve
          clinical outcomes.
        </SubHead>
        <SubHead>Other study goals include:</SubHead>
        <SubHeadList>
          <li>
            Determining the impact of a heart health engagement program paired
            with a medication adherence intervention for participants taking an
            oral anticoagulant medication who have previously been diagnosed
            with AFib.
          </li>
          <li>
            Finding patterns in the study data that may help identify, prevent,
            or treat other medical conditions beyond those that involve the
            heart.
          </li>
          <li>
            Supporting other research and health-related product development
            purposes, including development of new clinical studies and
            improving the design of future clinical studies.
          </li>
        </SubHeadList>
      </React.Fragment>
    ),
  },
  {
    question: 'Who is involved in the Heartline Study?',
    answer: (
      <SubHead>
        Janssen Scientific Affairs, LLC, an affiliate of Johnson & Johnson, is
        the sponsor of the Heartline™ Study. Apple is supporting the study
        technology and design of the app. Evidation Health provides the
        technology and study operations that enable the Heartline app and study
        experience for participants. Best Buy operates the Apple Watch
        distribution program for eligible study participants.
      </SubHead>
    ),
  },
  {
    question: 'How long will the study last?',
    answer: (
      <SubHead>
        The Heartline Study will last three years for each participant. This will 
        consist of two phases: The first phase lasts two years, and the second phase 
        lasts one year. During the first phase, participants are asked to complete 
        actions in the Heartline app. During the second phase, participants are not 
        required to complete any actions, but Medicare healthcare claims and iPhone 
        and Apple Watch data (if applicable) is collected to assess clinical outcomes.
      </SubHead>
    ),
  },
  {
    question:
      'What is atrial fibrillation (AFib) and why is it important to study it?',
    answer: (
      <React.Fragment>
        <SubHead>
          Atrial fibrillation (AFib) is a type of an irregular heart rhythm or
          arrhythmia. It occurs when the upper chambers of the heart beat out of
          sync with the lower chambers and this creates an irregular pattern.
          According to{' '}
          <a
            href={cdcAtrialFibrillationUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              trackGoogleAnalyticsEvent({
                category: 'cdc_atrial_fibrillation',
                action: 'click',
                label: `link:${cdcAtrialFibrillationUrl};location:faq_about_study_what_is_afib`,
              })
            }
          >
            Centers for Disease Control (CDC)
          </a>
          , it affects three to six million people in the United States every
          year. It may occur without any symptoms, or may cause symptoms like
          heart palpitations (flutters), light-headedness, and shortness of
          breath.
        </SubHead>
        <SubHead>
          AFib is a leading cause of stroke, yet it often goes undetected and
          undiagnosed. Up to 30 percent of people living with AFib aren't
          diagnosed until life-threatening complications occur, because it's
          often not associated with any symptoms.
        </SubHead>
        <SubHead>
          Appropriate diagnosis and treatment of AFib is important because it
          substantially reduces the risk of stroke. It is important to study
          AFib to explore whether earlier diagnosis can lead to better health
          outcomes, including prevention of stroke.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'Who is eligible to enroll in the Heartline Study?',
    answer: (
      <React.Fragment>
        <SubHead>To be eligible for this study, participants must*:</SubHead>
        <SubHeadList>
          <li>Be age 65 or older</li>
          <li>
            Be a resident of the United States for the duration of the study
          </li>
          <li>
            Be a user of an iPhone 6s or later, with iOS 15 or later{' '}
            <Link
              to={FAQ_ROUTE}
              state={{ scrollTarget: whatIPhoneModelScrollName }}
              onClick={() =>
                trackGoogleAnalyticsEvent({
                  category: 'faqs',
                  action: 'click',
                  label: 'location:faq_about_study_who_is_eligible',
                })
              }
            >
              (Learn more)
            </Link>
          </li>
          <li>
            Have Medicare coverage{' '}
            <a
              href={urlUnderstandingMedicare}
              target="_blank"
              rel="noopener noreferrer"
            >
              (any type of Medicare plan — including Original Medicare and
              Medicare Advantage)
            </a>
          </li>
        </SubHeadList>
        <SubHead>
          For the purpose of this research study, eligible participants were randomly 
          assigned to a group (like flipping a coin). One group participated by only 
          using the Heartline app on their iPhone. Another group was asked to obtain 
          an Apple Watch in addition to participating using the app on their iPhone. 
          There is also a group participating using their own Apple Watches and the 
          Heartline app on their iPhone.
        </SubHead>
        <Footnote>
          <em>
            * Key participation requirements only; other eligibility
            participation requirements will apply. You can check your
            eligibility in the Heartline app.
          </em>
        </Footnote>
      </React.Fragment>
    ),
  },
  {
    question:
      'Why are Johnson & Johnson and Apple collaborating on this study?',
    answer: (
      <React.Fragment>
        <SubHead>
          Johnson & Johnson and Apple teams worked together to jointly develop 
          the research study and the Heartline app. The study brings together 
          Johnson & Johnson's health expertise with Apple’s expertise in design, 
          technology, and privacy. Both companies hope to improve cardiovascular 
          health outcomes related to AFib.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'Why is Medicare required?',
    answer: (
      <React.Fragment>
        <SubHead>
          In order to evaluate study goals and assess clinical outcomes, the
          researchers need access to participants' healthcare claims data.
        </SubHead>
        <SubHead>
          If Heartline isn’t able to access participants’ claims data, we can’t 
          conduct the research needed.
        </SubHead>
        <SubHead>
          Heartline is working with Original Medicare (also known as Traditional Medicare) 
          and Medicare Advantage plans to obtain access to the healthcare claims data for 
          those who participate. All participants are asked to provide access to any 
          healthcare claims data available through Blue Button / Medicare. If participants 
          have Medicare Advantage, they were asked to authorize their Medicare Advantage 
          plan to share their healthcare claims data.
        </SubHead>
      </React.Fragment>
    ),
  },
];
