import React from 'react';

import ActionConfirmation from '../../components/hidden_pages/ActionConfirmation';

const UnlockAccount = props => {
  return (
    <ActionConfirmation
      endpointUrl="/user/unlock_account"
      successHeaderText="Thanks! Your account is now unlocked."
      successSubheaderText="Return to the Heartline app to continue."
      {...props}
    />
  );
};

export default UnlockAccount;
