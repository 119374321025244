import React, { Component } from 'react';
import { rem } from 'polished';
import Select from 'react-select';
import { Link } from '@reach/router';
import _ from 'lodash';
import axios from 'axios';
import styled from 'styled-components';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { SubHead, LargeHeading, LoadingIndicator } from '../LandingAssets';
import Grid from '../grid';
import CheckConfirmation from '../../assets/check-confirmation';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import {
  PRIVACY_ROUTE,
  inputPlaceholderColor,
  inputTextColor,
  sans,
  inputFontSize,
  inputLineHeight,
  inputLetterSpacing,
  inputMarginBottom,
  slateColor,
  slateLight30Color,
} from '../../constants';

const defaultSelectStyles = {
  color: inputTextColor,
  fontFamily: sans,
  fontSize: inputFontSize,
  lineHeight: inputLineHeight,
  letterSpacing: inputLetterSpacing,
  paddingLeft: rem(11),
};

const selectInputStyles = {
  control: styles => ({ ...styles, marginBottom: inputMarginBottom }),
  input: styles => ({ ...styles, ...defaultSelectStyles }),
  option: styles => ({ ...styles, ...defaultSelectStyles }),
  placeholder: styles => ({
    ...styles,
    ...defaultSelectStyles,
    color: inputPlaceholderColor,
  }),
  singleValue: styles => ({ ...styles, ...defaultSelectStyles }),
};

const SubmitButton = styled.button`
  margin: '1rem 0';
  background-color: ${() => slateColor};
  &:disabled {
    background-color: ${() => slateLight30Color};
  }
`;

const nameFieldIdentifier = 'name';
const emailFieldIdentifier = 'email';
const questionTopicFieldIdentifier = 'question_topic';
const detailsFieldIdentifier = 'details';

const zendeskUrl = 'https://evidation-pascal.zendesk.com/api/v2/requests.json';

const defaultState = {
  hasSubmitted: false,
  submitting: false,
  success: false,
  error: false,
  [nameFieldIdentifier]: '',
  [emailFieldIdentifier]: '',
  [questionTopicFieldIdentifier]: null,
  [detailsFieldIdentifier]: '',
  fieldWithError: '',
  validationErrors: [],
};

class TellUsMore extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, defaultState);

    this.fieldChanged = this.fieldChanged.bind(this);
    this.fieldDeselected = this.fieldDeselected.bind(this);
    this.selectFieldDeselected = this.selectFieldDeselected.bind(this);
    this.handleSelectChanged = this.handleSelectChanged.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.runValidations = this.runValidations.bind(this);
  }

  fieldChanged(e) {
    const shouldClearError = this.state.fieldWithError === e.target.name;

    this.setState({
      [e.target.name]: e.target.value,
      validationErrors: shouldClearError ? [] : this.state.validationErrors,
      fieldWithError: shouldClearError ? '' : this.state.fieldWithError,
    });
  }

  fieldDeselected(e) {
    this.runValidations(e);
  }

  selectFieldDeselected() {
    if (isEmpty(this.state[questionTopicFieldIdentifier])) {
      this.setState({
        fieldWithError: questionTopicFieldIdentifier,
        validationErrors: ['Please select a question topic.'],
      });
    }
  }

  handleSelectChanged(selectedOption) {
    const shouldClearError =
      this.state.fieldWithError === questionTopicFieldIdentifier;

    this.setState({
      [questionTopicFieldIdentifier]: selectedOption,
      validationErrors: shouldClearError ? [] : this.state.validationErrors,
      fieldWithError: shouldClearError ? '' : this.state.fieldWithError,
    });
  }

  validateEmail(email) {
    // Regex taken from: https://stackoverflow.com/a/32686261/2662329
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  runValidations(e) {
    switch (e.target.name) {
      case nameFieldIdentifier:
        if (isEmpty(this.state[nameFieldIdentifier])) {
          this.setState({
            fieldWithError: nameFieldIdentifier,
            validationErrors: ['Please type your name.'],
          });
        }
        break;
      case emailFieldIdentifier:
        if (!this.validateEmail(this.state.email)) {
          this.setState({
            fieldWithError: emailFieldIdentifier,
            validationErrors: ['Email address is not valid.'],
          });
        }
        break;
      case detailsFieldIdentifier:
        if (isEmpty(this.state[detailsFieldIdentifier])) {
          this.setState({
            fieldWithError: detailsFieldIdentifier,
            validationErrors: ['Please describe your issue.'],
          });
        }
        break;
      default:
      // do nothing
    }
  }

  async submitForm(e) {
    e.preventDefault();

    if (!_.isEmpty(this.state.validationErrors)) {
      return;
    }

    trackGoogleAnalyticsEvent({
      category: 'contact',
      action: 'click',
      label: `submit`,
    });

    this.setState({
      submitting: true,
      error: false,
      hasSubmitted: false,
    });

    try {
      const response = await axios.post(zendeskUrl, {
        request: {
          requester: {
            name: this.state[nameFieldIdentifier],
            email: this.state[emailFieldIdentifier],
          },
          subject: this.state[detailsFieldIdentifier].substring(0, 50),
          comment: { body: this.state[detailsFieldIdentifier] },
          tags: [
            this.state[questionTopicFieldIdentifier].value.replace(/\s+/g, '_'),
          ],
        },
      });

      if (response.status === 201) {
        this.setState({
          success: true,
        });

        setTimeout(() => {
          this.setState(Object.assign({}, defaultState));
        }, 8000);
      }
    } catch {
      this.setState({
        error: true,
      });
    } finally {
      this.setState({
        hasSubmitted: true,
        submitting: false,
      });
    }
  }

  textAndImageToDisplay() {
    if (this.state.error) {
      return {
        header: 'Oops! There was a problem.',
        subheader: 'Please try refreshing, or try again in a few minutes.',
        image: '',
      };
    }

    if (this.state.success) {
      return {
        header: '',
        subheader:
          "Message sent! We'll review it and get back to you as soon as we can.",
        image: (
          <CheckConfirmation
            style={{ margin: '0 auto', width: '6rem', height: '6rem' }}
          />
        ),
      };
    }
  }

  render() {
    const contentToDisplay = this.textAndImageToDisplay();

    if (!this.props.location.state) {
      window.history.back();
    }

    const { questionTopicOptions } = this.props.location.state;
    const { hasSubmitted, error, validationErrors } = this.state;

    return (
      <React.Fragment>
        <LargeHeading>Tell Us More</LargeHeading>
        <Grid.Flex flexWrap="wrap" alignItems="left" justifyContent="center">
          <Grid.Box textAlign="left" mb={rem(10)}>
            <SubHead>
              The information you provide will be used to respond to your
              inquiries. Our{' '}
              <Link
                to={PRIVACY_ROUTE}
                onClick={() =>
                  trackGoogleAnalyticsEvent({
                    category: 'view_privacy_policy',
                    action: 'click',
                    label: `location:tell_us_more_description`,
                  })
                }
              >
                Privacy Policy
              </Link>{' '}
              governs the use of the information you provide. By providing this
              information and submitting the form, you indicate that you read,
              understand, and agree to these terms.
            </SubHead>
          </Grid.Box>
          <Grid.Box textAlign="center">
            <p className="validation-errors">{validationErrors}</p>
          </Grid.Box>
        </Grid.Flex>
        <form onSubmit={this.submitForm}>
          <input
            type="text"
            name={nameFieldIdentifier}
            value={this.state.name}
            placeholder="Enter name here"
            onChange={this.fieldChanged}
            onBlur={this.fieldDeselected}
            className={cx({
              error: this.state.fieldWithError === nameFieldIdentifier,
            })}
            disabled={this.state.submitting || this.state.success}
          />
          <input
            type="text"
            name={emailFieldIdentifier}
            value={this.state.email}
            placeholder="Enter email address here"
            onChange={this.fieldChanged}
            onBlur={this.fieldDeselected}
            className={cx({
              error: this.state.fieldWithError === emailFieldIdentifier,
            })}
            disabled={this.state.submitting || this.state.success}
          />
          {!_.isEmpty(questionTopicOptions) && (
            <Select
              options={questionTopicOptions}
              placeholder="What is your question about?"
              onChange={this.handleSelectChanged}
              onBlur={this.selectFieldDeselected}
              styles={selectInputStyles}
              className={cx('select-dropdown', {
                error:
                  this.state.fieldWithError === questionTopicFieldIdentifier,
              })}
              isDisabled={this.state.submitting || this.state.success}
              value={this.state[questionTopicFieldIdentifier]}
            />
          )}
          <textarea
            name={detailsFieldIdentifier}
            value={this.state.details}
            placeholder="Tell us the details"
            onChange={this.fieldChanged}
            onBlur={this.fieldDeselected}
            style={{ height: rem(123) }}
            className={cx({
              error: this.state.fieldWithError === detailsFieldIdentifier,
            })}
            disabled={this.state.submitting || this.state.success}
          />
          {!this.state.success && (
            <SubmitButton
              type="submit"
              disabled={
                !this.state.name ||
                !this.state.email ||
                (!_.isEmpty(questionTopicOptions) &&
                  !this.state.question_topic) ||
                !this.state.details ||
                this.state.submitting ||
                this.state.fieldWithError
              }
            >
              {this.state.submitting ? (
                <LoadingIndicator length={30} />
              ) : (
                <span>Submit</span>
              )}
            </SubmitButton>
          )}
        </form>
        {(hasSubmitted || error) && (
          <React.Fragment>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
            >
              <Grid.Box width={1}>{contentToDisplay.image}</Grid.Box>
              <Grid.Box width={1} textAlign="center">
                <SubHead>{contentToDisplay.header}</SubHead>
              </Grid.Box>
              <Grid.Box width={1} textAlign="center">
                <SubHead>{contentToDisplay.subheader}</SubHead>
              </Grid.Box>
            </Grid.Flex>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default TellUsMore;
