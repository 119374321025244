import React from 'react';

import { SubHead, SubHeadList } from '../LandingAssets';

export const dataPrivacyHeaderText = 'Data and Privacy';
export const dataPrivacyQuestions = [
  {
    question: 'Why will participant data be collected?',
    answer: (
      <SubHead>
        Sharing participant data in this study will allow us to answer our 
        research questions.
      </SubHead>
    ),
  },
  {
    question: 'What data will be collected?',
    answer: (
      <React.Fragment>
        <SubHead>
          Heartline Study participants are asked to share different kinds of data and 
          information. This includes:
        </SubHead>
        <SubHeadList>
          <li>
            Information they enter in the Heartline app, including their contact 
            information, demographic information, and general health history.
          </li>
          <li>
            Data from the iPhone and Apple Watch (if applicable) they use during the 
            study, including health and activity metrics, health sensor metrics, and 
            device usage.
          </li>
          <li>Their Medicare healthcare claims data</li>
        </SubHeadList>
        <SubHead>
          Full details are included in the study consent form.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'What is healthcare claims data?',
    answer: (
      <SubHead>
        When a healthcare provider treats an individual, the healthcare provider sends 
        a bill of the services received to a health insurance provider. This bill is 
        called a "claim". The claim uses standard codes to describe the services an 
        individual received and why the individual received the services (in other words, 
        a diagnosis). This data is called healthcare claims data. Claims data also includes 
        clinic visits and provider billing information, as well as demographics and 
        administrative elements of an individual’s insurance coverage. It is not the same as 
        electronic health records (EHR).
      </SubHead>
    ),
  },
  {
    question:
      'Will you be collecting data from participant electronic health record (EHR)?',
    answer: (
      <SubHead>
        An electronic health record (EHR) is a digital version of a patient’s medical chart. 
        No, this study will not be collecting, analyzing, or using data from participants’ 
        EHR.
      </SubHead>
    ),
  },
  {
    question: 'Who will have access to participant data?',
    answer: (
      <React.Fragment>
        <SubHead>
          Authorized third-party companies responsible for data collection, storage, and 
          processing will have access to participant study data, including contact 
          information, so they can communicate with participants during the study if needed.
        </SubHead>
        <SubHead>
          Johnson & Johnson and Apple will only have access to “Coded Study Data.” This means 
          directly identifying information such as participants’ names and contact information 
          was replaced with a code that is randomly assigned and unique to each participant.
        </SubHead>
        <SubHead>
          Johnson & Johnson may have access to information that directly identifies 
          participants only to the extent necessary to comply with applicable law. In 
          addition, if participants call Heartline Support for assistance with ECG or 
          IRN-related issues or certain other technical issues, Heartline Support may 
          ask if they want to be transferred to Apple Support for additional help. With 
          their consent, they may be transferred to Apple Support for such assistance, 
          in which case Apple may have access to certain information that directly 
          identifies them.
        </SubHead>
        <SubHead>
          Full details on other individuals and entities that may have access to the study 
          data are included in the study consent form which participants were required to 
          read and sign if they wanted to join the study.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'How will participant data be kept secure?',
    answer: (
      <React.Fragment>
        <SubHead>
          All study data collected is stored encrypted in a study database retained by an 
          authorized third-party company. The third party company is required to protect the 
          privacy and security of the study data. Records of participation in this study, 
          including participant study data, will be kept confidential except if disclosure 
          is required by law, or is otherwise required or allowed as described in the study 
          consent form.
        </SubHead>
        <SubHead>
          Participant privacy and the confidentiality and security of participant data are 
          important to us. We’ll make every reasonable effort to keep participant information 
          safe and protect the confidentiality of that data; however, total confidentiality 
          cannot be guaranteed. There is still a risk of unauthorized access to or disclosure 
          of personally identifiable participant information, including health information.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'How long will participant data be stored?',
    answer: (
      <React.Fragment>
        <SubHead>
          The study data will be stored for the minimum amount of time necessary
          for the purposes of the study (as described in the study informed
          consent form), but no more than 50 years after completion of the
          study.
        </SubHead>
      </React.Fragment>
    ),
  },
];
