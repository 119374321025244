import React, { useState } from 'react';
import ReactGA from 'react-ga';

export const EligibilityModalContext = React.createContext({
  showModal: false,
  setShowModal: () => {},
});

export const EligibilityModalContextProvider = props => {
  const setShowModal = showModal => {
    setState({ ...state, showModal });

    if (showModal) {
      ReactGA.modalview('eligibility_modal');
    }
  };

  const initState = {
    showModal: false,
    setShowModal,
  };

  const [state, setState] = useState(initState);

  return (
    <EligibilityModalContext.Provider value={state}>
      {props.children}
    </EligibilityModalContext.Provider>
  );
};
