import React from 'react';

import ActionConfirmation from '../../components/hidden_pages/ActionConfirmation';

const WaitlistWithdrawal = props => {
  return (
    <ActionConfirmation
      endpointUrl="/user/waitlist_withdraw"
      successHeaderText="We've removed you from the waitlist"
      successSubheaderText="You'll no longer receive updates about the Heartline Study."
      {...props}
    />
  );
};

export default WaitlistWithdrawal;
