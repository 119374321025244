import React from 'react';
import { Router } from '@reach/router';

import {
  HOME_ROUTE,
  ABOUT_ROUTE,
  TEAM_ROUTE,
  PROVIDERS_ROUTE,
  FAQ_ROUTE,
  CONTACT_ROUTE,
  PRIVACY_ROUTE,
  APP_PRIVACY_ROUTE,
  TERMS_ROUTE,
  CONTACT_US_ROUTE,
  TELL_US_MORE_SUBROUTE,
  HCP_SUBROUTE,
  PRESS_SUBROUTE,
  UNSUBSCRIBE_ROUTE,
  WAITLIST_WITHDRAWAL_ROUTE,
  REPORT_LOST_WATCH_ROUTE,
  SHIPPING_INSTRUCTIONS_ROUTE,
  UNLOCK_ACCOUNT_ROUTE,
} from './constants';
import Landing from './pages/Landing';
import About from './pages/About';
import OurTeam from './pages/OurTeam';
import ForProviders from './pages/ForProviders';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import ContactUs from './pages/ContactUs';
import ContactUsByEmail from './components/contact_us/ContactUsByEmail';
import TellUsMore from './components/contact_us/TellUsMore';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AppPrivacyPolicy from './pages/AppPrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import ConfirmEmail from './pages/ConfirmEmail';
import SupportTeam from './pages/SupportTeam';
import ResetPassword from './pages/ResetPassword';
import ReportLostWatch from './pages/ReportLostWatch';
import ShippingInstructions from './pages/ShippingInstructions';
import UnsubscribeEmail from './pages/action_confirmation/UnsubscribeEmail';
import WaitlistWithdrawal from './pages/action_confirmation/WaitlistWithdrawal';
import UnlockAccount from './pages/action_confirmation/UnlockAccount';
import EligibilityModal from './components/eligibility_modal/EligibilityModal';
import { EligibilityModalContextProvider } from './components/eligibility_modal/EligibilityModalContextManagement';
import PrivacyDisclaimer from './components/banners/PrivacyDisclaimer';
import RedirectAndDefaultRoutes from './components/RedirectAndDefaultRoutes';

const App = () => {
  return (
    <EligibilityModalContextProvider>
      <EligibilityModal />
      <Router>
        <Landing path={HOME_ROUTE} />

        <About path={ABOUT_ROUTE} />
        <OurTeam path={TEAM_ROUTE} />
        <ForProviders path={PROVIDERS_ROUTE} />
        <FAQ path={FAQ_ROUTE} />
        <Contact path={CONTACT_ROUTE} />
        <ContactUs path={CONTACT_US_ROUTE}>
          <ContactUsByEmail path={HCP_SUBROUTE} />
          <ContactUsByEmail path={PRESS_SUBROUTE} />
          <TellUsMore path={TELL_US_MORE_SUBROUTE} />
        </ContactUs>
        <TermsOfUse path={TERMS_ROUTE} />
        <PrivacyPolicy path={PRIVACY_ROUTE} />
        <AppPrivacyPolicy path={APP_PRIVACY_ROUTE} />
        <SupportTeam path="/support_team/:action" />
        <ConfirmEmail path="/confirm_email" />
        <ResetPassword path="/reset_password" />
        <ReportLostWatch path={REPORT_LOST_WATCH_ROUTE} />
        <ShippingInstructions path={SHIPPING_INSTRUCTIONS_ROUTE} />
        <UnsubscribeEmail path={UNSUBSCRIBE_ROUTE} />
        <WaitlistWithdrawal path={WAITLIST_WITHDRAWAL_ROUTE} />
        <UnlockAccount path={UNLOCK_ACCOUNT_ROUTE} />
        <RedirectAndDefaultRoutes default />
      </Router>
      <PrivacyDisclaimer />
    </EligibilityModalContextProvider>
  );
};

export default App;
