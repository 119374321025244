import { rem } from 'polished';
import React from 'react';
import { Arrow } from '../LandingAssets';

const UpDownArrow = props => {
  return (
    <React.Fragment>
      {props.open ? (
        <Arrow up style={{ paddingTop: rem(props.pt || 22) }} />
      ) : (
        <Arrow down style={{ paddingBottom: rem(props.pb || 16) }} />
      )}
    </React.Fragment>
  );
};

export default UpDownArrow;
