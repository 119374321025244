import { rem } from 'polished';
import React from 'react';
import { Link } from '@reach/router';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { InnerGrid, Raised, SubHead } from './LandingAssets';
import MediaQuery, {
  Breakpoint,
  isSmallScreenQuery,
} from '../components/breakpoint';
import Grid from '../components/grid';
import { trackGoogleAnalyticsEvent } from '../tracking';

import {
  PRIVACY_ROUTE,
  CONTACT_ROUTE,
  TERMS_ROUTE,
  CONTACT_US_ROUTE,
  PRESS_SUBROUTE,
  ABOUT_ROUTE,
  FAQ_ROUTE,
  TEAM_ROUTE,
  PROVIDERS_ROUTE,
  participantToolkitPDFFilename,
  whiteBgColor,
  sans,
} from '../constants';

const FooterMenuItem = ({ route, linkText, externalLink }) => {
  const isSmallScreen = useMediaQuery(isSmallScreenQuery);
  const boxWidth = isSmallScreen ? 1 : null;
  const xPadding = isSmallScreen ? 0 : rem(20);

  return (
    <Grid.Box width={boxWidth} textAlign="center" px={xPadding}>
      <SubHead
        color={whiteBgColor}
        fontWeight={isSmallScreen ? 'bold' : 'normal'}
      >
        {externalLink ? (
          <a
            href={route}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              trackGoogleAnalyticsEvent({
                category: 'downloads',
                action: 'click',
                label: `file:${participantToolkitPDFFilename};location:footer`,
              })
            }
          >
            {linkText} &rsaquo;
          </a>
        ) : (
          <Link to={route}>{linkText} &rsaquo;</Link>
        )}
      </SubHead>
    </Grid.Box>
  );
};

const FooterCopyright = styled.p`
  text-align: center;
  font-size: ${rem(13)};
  margin-bottom: 0;
  padding-bottom: ${rem(30)};
  color: ${() => whiteBgColor};
  opacity: 0.35;
  font-family: 'SFProDisplay-Regular', ${() => sans};
  letter-spacing: ${rem(-0.03)};
  line-height: ${rem(18)};
`;

const Footer = ({ children }) => {
  return (
    <Raised bg="#3e4156" as="footer" role="complementary">
      <InnerGrid
        multiplier={12}
        pt={[rem(60), 0, 0, 0]}
        pb={[rem(30), rem(70), rem(70), rem(70)]}
        px={[rem(10), rem(10), 0, 0]}
      >
        {children ? (
          children
        ) : (
          <MediaQuery minWidth={Breakpoint.SMALL}>
            <div style={{ height: 100 }} />
          </MediaQuery>
        )}
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          mb={[rem(34), rem(49), rem(57), rem(57)]}
        >
          <FooterMenuItem route={CONTACT_ROUTE} linkText={'Contact Us'} />
          <FooterMenuItem
            route={`${CONTACT_US_ROUTE}${PRESS_SUBROUTE}`}
            linkText={'Press'}
          />
          <FooterMenuItem route={TERMS_ROUTE} linkText={'Terms of Use'} />
          <FooterMenuItem route={PRIVACY_ROUTE} linkText={'Privacy Policy'} />
          <FooterMenuItem route={ABOUT_ROUTE} linkText={'About the Study'} />
          <FooterMenuItem
            route={FAQ_ROUTE}
            linkText={'Frequently Asked Questions'}
          />
          <FooterMenuItem route={TEAM_ROUTE} linkText={'Our Team'} />
          <FooterMenuItem route={PROVIDERS_ROUTE} linkText={'For Providers'} />
        </Grid.Flex>
      </InnerGrid>
      <FooterCopyright>
        &copy; Janssen Scientific Affairs, LLC. 2021. All Rights Reserved. This
        information is intended for a United States audience.
      </FooterCopyright>
    </Raised>
  );
};

export default Footer;
