import React, { Component } from 'react';
import { rem } from 'polished';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';

import Grid from '../grid';
import { SocialCollection, SocialButtons } from '../LandingAssets';
import { getHostname } from '../../utils';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import {
  facebookImage,
  twitterImage,
  pinterestImage,
  linkedinImage,
  emailImage,
  forProvidersHeroImage,
} from '../../constants';

const sendButtonGA = (platform, placementIdentifierForAnalytics) => {
  trackGoogleAnalyticsEvent({
    category: `platform:${platform}`,
    action: 'click',
    label: `location:${placementIdentifierForAnalytics}`,
  });
};

const facebookQuote =
  'The Heartline™ Study, a nationwide heart health research study from @jnj, in collaboration with @apple, explores how wearable technology and a mobile app can help detect atrial fibrillation (AFib), a leading cause of stroke, earlier. Now enrolling adults 65+ with an iPhone. Visit Heartline.com for more details about the study, its eligibility requirements, and how to participate from home.';
const pinterestQuote =
  'The Heartline™ Study is a virtual heart health research study from Johnson & Johnson, in collaboration with Apple. It explores how wearable technology and a mobile app can help detect atrial fibrillation (AFib), a leading cause of stroke, earlier. Now enrolling adults 65+ with an iPhone. Visit Heartline.com for more details about the study, its eligibility requirements, and how to participate from home.';
const twitterTitle =
  'The Heartline Study, a heart health research study from @JNJNews, in collaboration with @Apple, explores how wearable technology & a mobile app can help detect #Afib, a leading cause of stroke, earlier. Now enrolling adults 65+ with an iPhone to participate from home.';
const linkedInTitle =
  'The Heartline™ Study - Join Heartline to contribute to important heart health research and help advance science.';
const linkedInSummary =
  'The Heartline™ Study is a virtual heart health research study from @Johnson & Johnson, in collaboration with @Apple, exploring how wearable technology and a mobile app can help detect atrial fibrillation (AFib), a leading cause of stroke, earlier.';
const emailSubject =
  'You’ve been invited to learn more about the Heartline Study';
const emailButton = `Learn More about the Heartline Study

The Heartline Study is a heart health research study from Johnson & Johnson in collaboration with Apple.

The study is exploring how everyday technology like the iPhone and Apple Watch can enable earlier detection of atrial fibrillation (AFib), a leading cause of stroke.  Seniors with and without an existing AFib diagnosis may be eligible.

The study is conducted through the Heartline app on an iPhone, which provides a rich engagement program with activities around sleep, fitness, and wellness to help participants become more engaged in their overall heart health. And you can participate completely from the comfort of your own home!

Some study participants will be asked to obtain and use an Apple Watch throughout their participation. You do not need to have an Apple Watch in order to participate.

I thought you might be interested in learning more about the study. To read more, including whether you are eligible, download the Heartline app or visit Heartline.com.

Visit heartline.com/email

Questions? Contact Heartline Support at (877) 594-4021 or help@heartline.com

        `;

class SocialItem extends Component {
  render() {
    const { component } = this.props;
    return (
      <Grid.Box width={1} pt={rem(0)} pb={rem(0)} pl={rem(15)}>
        {component}
      </Grid.Box>
    );
  }
}

const SocialButtonsCollection = ({
  vertical,
  placementIdentifierForAnalytics,
}) => {
  return (
    <SocialCollection
      style={{ display: vertical ? '' : 'flex' }}
      footer={vertical}
    >
      {socialButtonsInfo.map(socialButton => (
        <SocialItem
          component={socialButton.getComponent(placementIdentifierForAnalytics)}
          key={socialButton.name}
        />
      ))}
    </SocialCollection>
  );
};

export default SocialButtonsCollection;

const socialButtonsInfo = [
  {
    name: 'Facebook',
    getComponent: placementIdentifierForAnalytics => (
      <FacebookShareButton
        url={`${getHostname()}facebook`}
        style={{ margin: 0 }}
        quote={facebookQuote}
        beforeOnClick={() =>
          sendButtonGA('Facebook', placementIdentifierForAnalytics)
        }
      >
        <SocialButtons src={facebookImage} />
      </FacebookShareButton>
    ),
  },
  {
    name: 'Twitter',
    getComponent: placementIdentifierForAnalytics => (
      <TwitterShareButton
        url={`${getHostname()}twitter`}
        style={{ margin: 0 }}
        title={twitterTitle}
        beforeOnClick={() =>
          sendButtonGA('Twitter', placementIdentifierForAnalytics)
        }
      >
        <SocialButtons src={twitterImage} />
      </TwitterShareButton>
    ),
  },
  {
    name: 'Pinterest',
    getComponent: placementIdentifierForAnalytics => (
      <PinterestShareButton
        url={`${getHostname()}pinterest`}
        style={{ margin: 0 }}
        media={forProvidersHeroImage}
        description={pinterestQuote}
        beforeOnClick={() =>
          sendButtonGA('Pinterest', placementIdentifierForAnalytics)
        }
      >
        <SocialButtons src={pinterestImage} />
      </PinterestShareButton>
    ),
  },
  {
    name: 'LinkedIn',
    getComponent: placementIdentifierForAnalytics => (
      <LinkedinShareButton
        url={`${getHostname()}linkedin`}
        style={{ margin: 0 }}
        title={linkedInTitle}
        summary={linkedInSummary}
        beforeOnClick={() =>
          sendButtonGA('LinkedIn', placementIdentifierForAnalytics)
        }
      >
        <SocialButtons src={linkedinImage} />
      </LinkedinShareButton>
    ),
  },
  {
    name: 'Email',
    getComponent: placementIdentifierForAnalytics => (
      <EmailShareButton
        subject={emailSubject}
        body={emailButton}
        url={''}
        style={{ margin: 0 }}
        beforeOnClick={() =>
          sendButtonGA('Email', placementIdentifierForAnalytics)
        }
      >
        <SocialButtons src={emailImage} />
      </EmailShareButton>
    ),
  },
];
