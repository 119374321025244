import React from 'react';

const CheckConfirmation = props => (
  <svg
    width="200px"
    height="200px"
    viewBox="0 0 200 200"
    style={{ display: 'block', ...props.style }}
  >
    <title>Thanks! Your email address is confirmed.</title>
    <g
      id="Check-200"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Check-136" transform="translate(32.000000, 32.000000)">
        <g>
          <circle id="Oval" fill="#59C6B0" cx="68" cy="68" r="68"></circle>
          <polyline
            id="Path-4"
            stroke="#FFFFFF"
            strokeWidth="8"
            points="39 69.8664297 59.1146989 87 98 50"
          ></polyline>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckConfirmation;
