import React, { Component } from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

import CheckConfirmation from '../assets/check-confirmation';
import {
  InnerGrid,
  Raised,
  SubHead,
  TextGroupHeader,
} from '../components/LandingAssets';
import ImageHeaderSubHeader from '../components/hidden_pages/ImageHeaderSubheader';
import axios from 'axios';
import { wrapComponent } from '../utils';
import queryString from 'query-string';
import { lightGreenBgColor, slateColor, slateLight30Color } from '../constants';
import { linkExpired } from '../constants';
import Image from '../components/images/Image';
import { trackPageViewForCurrentPath } from '../tracking';

const SubmitButton = styled.button`
  margin: '1rem 0';
  background-color: ${() => slateColor};
  &:disabled {
    background-color: ${() => slateLight30Color};
  }
`;

class ReportLostWatch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: false,
      unknownError: false,
      knownError: false,
      hasSubmitted: false,
    };
  }

  componentDidMount() {
    trackPageViewForCurrentPath();

    const queryStringValues = queryString.parse(this.props.location.search);
    const lostWatchEmailToken = queryStringValues.token;

    if (!lostWatchEmailToken) {
      this.setState({
        unknownError: true,
      });
    }
  }

  handleClick() {
    const queryStringValues = queryString.parse(this.props.location.search);
    const lostWatchEmailToken = queryStringValues.token;

    if (!lostWatchEmailToken) {
      this.setState({
        unknownError: true,
      });
      return;
    }

    this.setState(state => ({
      hasSubmitted: true,
    }));

    this.reportLostWatchWithBackend(lostWatchEmailToken);
  }

  async reportLostWatchWithBackend(lostWatchEmailToken) {
    try {
      const response = await axios.post(
        `/watch_retrieval/report_lost_watch?token=${lostWatchEmailToken}`,
      );

      if (response.status === 200) {
        if (response.data.success) {
          this.setState({
            success: true,
          });
        } else {
          this.setState({
            unknownError: true,
          });
        }
      }
    } catch (error) {
      const backendHandledStatuses = [400, 404];
      if (
        error.response &&
        backendHandledStatuses.includes(error.response.status)
      ) {
        this.setState({
          knownError: true,
        });
      } else {
        this.setState({
          unknownError: true,
        });
      }
      console.error(error);
    }
  }

  textAndImageToDisplay() {
    if (this.state.unknownError) {
      return {
        header: 'Oops! There was a problem.',
        subheader: 'Please try refreshing, or try again in a few minutes.',
      };
    } else if (this.state.success) {
      return {
        header: 'Thanks for reporting your lost Apple Watch.',
        subheader:
          'Your participation in the Heartline Study has come to an end. We appreciate your time and effort.',
        image: <CheckConfirmation style={{ marginTop: '-1.3rem' }} />,
      };
    } else if (this.state.knownError) {
      return {
        header: 'This link has expired',
        subheader: 'You have already reported your watch lost.',
        image: (
          <Image
            src={linkExpired}
            style={{ textAlign: 'center' }}
            alt="Link expired."
            dontUse2x
          />
        ),
      };
    } else {
      return {
        header: '',
        subheader: '',
        image: '',
      };
    }
  }

  render() {
    return (
      <Raised bg={lightGreenBgColor} role="main">
        <section role="main">
          <InnerGrid
            multiplier={4.5}
            px={rem(10)}
            pt={[rem(10), rem(10), rem(60), rem(60)]}
            pb={[rem(10), rem(10), rem(40), rem(40)]}
            mb={[rem(60), 0, 0, 0]}
          >
            {!this.state.hasSubmitted &&
              !this.state.knownError &&
              !this.state.unknownError && (
                <React.Fragment>
                  <TextGroupHeader
                    className="status-header"
                    style={{ textAlign: 'center' }}
                  >
                    Has your Apple Watch been lost?
                  </TextGroupHeader>
                  <div className="subheader">
                    <SubHead style={{ textAlign: 'center' }}>
                      When you received an Apple Watch on loan from us for use
                      with the Heartline™ Study, you agreed to return it when
                      your participation was over.
                    </SubHead>
                    <SubHead SubHead style={{ textAlign: 'center' }}>
                      However, if the watch we loaned you was lost, please
                      confirm it below. You are not responsible for any costs
                      associated with your lost watch.
                    </SubHead>
                    <SubmitButton onClick={() => this.handleClick()}>
                      Yes, the Apple Watch has been lost
                    </SubmitButton>
                    <SubHead SubHead style={{ textAlign: 'center' }}>
                      If you report that your watch was lost, you will not be
                      eligible to receive the final study compensation from
                      Heartline.
                    </SubHead>
                  </div>
                </React.Fragment>
              )}
            {(this.state.hasSubmitted || this.state.unknownError) && (
              <ImageHeaderSubHeader
                hasError={this.state.unknownError}
                contentToDisplay={this.textAndImageToDisplay()}
              />
            )}
          </InnerGrid>
        </section>
      </Raised>
    );
  }
}

export default wrapComponent(ReportLostWatch);
