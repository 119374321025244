import React from 'react';
import { rem } from 'polished';
import { Link } from '@reach/router';

import Grid from '../grid';
import { InnerGrid, PageHeader, SubHead } from '../LandingAssets';
import MediaQuery, { Breakpoint } from '../breakpoint';
import Image from '../images/Image';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import {
  forProvidersHeroImage,
  forHCProvidersHeaderScrollName,
  FAQ_ROUTE,
  hcpStudyMaterialsPDF,
  hcpStudyMaterialsPDFFilename,
} from '../../constants';

const ForProvidersHero = () => {
  const textContainer = (
    <Grid.Box
      width={[1, 1, 1 / 2, 1 / 2]}
      px={[rem(20), rem(20), 0, 0]}
      pb={[rem(60), rem(60), 0, 0]}
    >
      <PageHeader>The Heartline Study</PageHeader>
      <div className="hero-text-container">
        <SubHead>
          The Heartline Study is a pragmatic, randomized, controlled, virtual
          research study, from Johnson & Johnson, in collaboration with Apple.
          The primary objective is to investigate a heart health wellness
          program that uses a mobile app combined with features of the Apple
          Watch Series 4 (or later) to identify potential heart beat
          irregularities such as atrial fibrillation (AFib). Additionally the
          study seeks to determine the impact of a heart health engagement
          program paired with a medication adherence intervention among those
          participants receiving an oral anticoagulant therapy who have been
          previously diagnosed with AFib. The data will also be used to find
          novel markers to identify, predict, or evaluate other health
          conditions.
        </SubHead>
      </div>
      <SubHead>
        <Link
          className="bold-text padding-top-small"
          to={FAQ_ROUTE}
          state={{ scrollTarget: forHCProvidersHeaderScrollName }}
          onClick={() =>
            trackGoogleAnalyticsEvent({
              category: 'faqs',
              action: 'click',
              label: 'location:hc_providers_hero_heartline_study',
            })
          }
        >
          Learn more about the study &rsaquo;
        </Link>
      </SubHead>
      <SubHead>
        <a
          href={hcpStudyMaterialsPDF}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackGoogleAnalyticsEvent({
              category: 'downloads',
              action: 'click',
              label: `file:${hcpStudyMaterialsPDFFilename};location:how_to_get_your_patients_involved`,
            })
          }
        >
          Download study materials &rsaquo;
        </a>
      </SubHead>
    </Grid.Box>
  );

  const imageContainer = (
    <Grid.Box width={[1, 1, 1 / 2, 1 / 2]} mb={[rem(20), rem(20), 0, 0]}>
      <Image
        src={forProvidersHeroImage}
        alt="Doctor with patient"
        style={{ width: '100%' }}
        dontUse2x
      />
    </Grid.Box>
  );

  return (
    <InnerGrid
      multiplier={11}
      px={[0, 0, rem(20), rem(20)]}
      py={[0, 0, rem(40), rem(60)]}
    >
      <Grid.Flex
        flexWrap="wrap"
        alignItems="flex-start"
        my={[rem(0), rem(10), rem(40), rem(60)]}
      >
        <MediaQuery minWidth={Breakpoint.MEDIUM}>
          {textContainer}
          {imageContainer}
        </MediaQuery>
        <MediaQuery maxWidth={Breakpoint.MEDIUM}>
          {imageContainer}
          {textContainer}
        </MediaQuery>
      </Grid.Flex>
    </InnerGrid>
  );
};

export default ForProvidersHero;
