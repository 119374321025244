import { rem } from 'polished';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Link } from '@reach/router';
import ReactPixel from 'react-facebook-pixel';

import { SubHead, TextGroupHeader, Footnote } from '../LandingAssets';
import Grid from '../grid';
import { EligibilityModalContext } from './EligibilityModalContextManagement';
import Image from '../images/Image';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import {
  badgeEnrollmentClosed204,
  downloadAppFromAppStoreUrl,
  downloadAppFromAppStoreTarget,
  FAQ_ROUTE,
  whatIPhoneModelScrollName,
  urlUnderstandingMedicare,
  facebookPixelDownloadAppEventName,
} from '../../constants';

// Using this to center the modal vertically and horizontally.
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
  },
};

if (process.env.NODE_ENV !== 'test') {
  // Used by ReactModal for app accessibility.
  ReactModal.setAppElement('#root');
}

const BoxClose = styled.span`
  cursor: pointer;
  color: #fff;
  border-radius: ${rem(30)};
  background: black;
  font-size: ${rem(20)};
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding: ${rem(11)} ${rem(6)};
  &:before {
    content: '×';
  }
`;

const EligibilityCriteria = () => {
  const state = useContext(EligibilityModalContext);

  const eligibilityCriteriaList = [
    <SubHead>Be age 65 or older</SubHead>,
    <SubHead>
      Be a resident of the United States for the duration of the study
    </SubHead>,
    <SubHead>
      Be a user of an iPhone 6s or later, with iOS 15 or later{' '}
      <Link
        to={FAQ_ROUTE}
        state={{ scrollTarget: whatIPhoneModelScrollName }}
        onClick={() => {
          trackGoogleAnalyticsEvent({
            category: 'faqs',
            action: 'click',
            label: 'location:eligibility_modal_iPhone_model',
          });

          state.setShowModal(false);
        }}
      >
        (Learn more)
      </Link>
    </SubHead>,
    <SubHead>
      Have Medicare coverage{' '}
      <a
        href={urlUnderstandingMedicare}
        target="_blank"
        rel="noopener noreferrer"
      >
        (any type of Medicare plan — including Original Medicare and Medicare
        Advantage)
      </a>
    </SubHead>,
  ];

  return eligibilityCriteriaList.map((criterion, index) => (
    <React.Fragment key={`criterion_${index}`}>
      <Grid.Box width={4 / 5} mb={rem(10)}>
        {criterion}
      </Grid.Box>
      <Grid.Box width={1 / 5} textAlign="right">
        <SubHead>&#10004;</SubHead>
      </Grid.Box>
    </React.Fragment>
  ));
};

const EligibilityModal = () => {
  const state = useContext(EligibilityModalContext);

  return (
    <ReactModal
      isOpen={state.showModal}
      onRequestClose={() => state.setShowModal(false)}
      style={customStyles}
      contentLabel="Eligibility Modal"
    >
      <Grid.Flex
        flexWrap="wrap"
        justifyContent="flex-end"
        alignItems="flex-start"
        mt={[0, rem(-30), rem(-30), rem(-30)]}
        mr={[0, rem(-30), rem(-30), rem(-30)]}
        mb={[rem(20), rem(20), rem(20), rem(20)]}
      >
        <BoxClose onClick={() => state.setShowModal(false)} />
      </Grid.Flex>
      <Grid.Flex
        flexWrap="wrap"
        justifyContent="center"
        alignItems="flex-start"
        mt={rem(0)}
      >
        <Grid.Box width={1} mb={[rem(5), rem(12), rem(12), rem(12)]}>
          <TextGroupHeader lineHeight={32}>
            You can check your eligibility in the Heartline app. To participate,
            you will need to*:
          </TextGroupHeader>
        </Grid.Box>
      </Grid.Flex>
      <Grid.Flex
        flexWrap="wrap"
        justifyContent="center"
        alignItems="flex-start"
        mt={rem(30)}
      >
        <EligibilityCriteria />
        <Grid.Box width={1} mt={rem(20)} textAlign="center">
          <a
            href={downloadAppFromAppStoreUrl()}
            target={downloadAppFromAppStoreTarget}
            rel="noopener noreferrer"
            onClick={() => {
              trackGoogleAnalyticsEvent({
                category: 'app_store',
                action: 'click',
                label: 'location:eligibility_modal',
              });

              ReactPixel.track(facebookPixelDownloadAppEventName);
            }}
          >
            <Image
              src={badgeEnrollmentClosed204}
              alt="Download on the App Store"
            />
          </a>
        </Grid.Box>
        <Grid.Box width={1} mt={rem(25)} textAlign="center">
          <Footnote>
            * Key participation requirements only; other eligibility
            participation requirements will apply.
          </Footnote>
        </Grid.Box>
      </Grid.Flex>
    </ReactModal>
  );
};

export default EligibilityModal;
