import React, { Component } from 'react';
import { rem } from 'polished';

import Grid from '../grid';
import { ConfirmationButton, SubHead } from '../LandingAssets';
import { slateColor } from '../../constants';

const privacyDisclaimerBoxStyles = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  backgroundColor: slateColor,
  color: 'white',
  fontSize: rem(15),
  lineHeight: rem(20),
  paddingTop: rem(30),
  paddingBottom: rem(20),
  width: '100%',
};

const hasConfirmedPrivacyDisclaimerKey = 'hasConfirmedPrivacyDisclaimer';
const hasViewedValue = 'true';

class PrivacyDisclaimer extends Component {
  constructor(props) {
    super(props);

    this.handleButtonClicked = this.handleButtonClicked.bind(this);
  }

  handleButtonClicked() {
    localStorage.setItem(hasConfirmedPrivacyDisclaimerKey, hasViewedValue);

    this.forceUpdate();
  }

  render() {
    if (
      localStorage.getItem(hasConfirmedPrivacyDisclaimerKey) === hasViewedValue
    ) {
      return null;
    }

    return (
      <Grid.Box
        style={privacyDisclaimerBoxStyles}
        px={[rem(20), rem(10), rem(20), 0]}
      >
        <Grid.Flex
          flexWrap="wrap"
          justifyContent={['flex-start', 'center', 'center', 'center']}
          alignItems="center"
        >
          <Grid.Box
            width={[1, 4 / 5, 4 / 5, 7 / 10]}
            mr={[0, rem(14), rem(50), rem(80)]}
            mb={[rem(10), 0, 0, 0]}
          >
            <SubHead style={{ color: 'white' }}>
              Clicking on any of the links on this website will take you to an
              online service, the content for which Janssen Scientific Affairs,
              LLC is not responsible and to which this Privacy Policy does not
              apply. We encourage you to read the Privacy Policy of every online
              service you visit.
            </SubHead>
          </Grid.Box>
          <Grid.Box width={[[1, 1 / 5, 1 / 5, 3 / 10]]}>
            <ConfirmationButton onClick={this.handleButtonClicked}>
              OK
            </ConfirmationButton>
          </Grid.Box>
        </Grid.Flex>
      </Grid.Box>
    );
  }
}

export default PrivacyDisclaimer;
