import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import { googleAnalyticsExternalRedirectTrackerName } from './constants';

export const trackPageViewForCurrentPath = () => {
  // We are using both Google Analytics and Facebook Pixel for tracking.
  ReactGA.pageview(window.location.pathname);
  ReactPixel.pageView();
};

export const trackGoogleAnalyticsEvent = ({
  category,
  action,
  label,
  nonInteraction,
  trackerName,
}) => {
  let trackerNames;
  if (trackerName) {
    trackerNames = [trackerName];
  }

  const eventAttributes = {
    category,
    action,
    label,
  };
  if (nonInteraction) {
    eventAttributes.nonInteraction = nonInteraction;
  }

  // We are using a different tracker name (i.e. not the default tracker) for when we handle external redirects.
  // I think the intention was to prevent the main tracking statistics from being polluted by the external redirects.
  ReactGA.event(eventAttributes, trackerNames);
};

const initializeGoogleAnalytics = () => {
  // Updated on Aug 7, 2019
  const GA_TRACKING_ID = 'UA-142447578-1';

  ReactGA.initialize(GA_TRACKING_ID, {
    debug: process.env.NODE_ENV === `development`,
    testMode: process.env.NODE_ENV === `test`,
    alwaysSendToDefaultTracker: false,
  });
  ReactGA.addTrackers(googleAnalyticsExternalRedirectTrackerName);
};

const initializeFBPixel = () => {
  const FB_PIXEL_TRACKING_ID = '426834258593087';

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };

  ReactPixel.init(FB_PIXEL_TRACKING_ID, null, options);
};

export const initializeTracking = () => {
  initializeGoogleAnalytics();
  initializeFBPixel();
};
