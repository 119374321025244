import { rem } from 'polished';
import React, { useState } from 'react';
import { Link } from '@reach/router';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { Raised } from './LandingAssets';
import Grid from '../components/grid';
import MediaQuery, { Breakpoint, isMediumScreenQuery } from './breakpoint';
import Image from './images/Image';

import {
  HOME_ROUTE,
  ABOUT_ROUTE,
  TEAM_ROUTE,
  PROVIDERS_ROUTE,
  FAQ_ROUTE,
  badgeEnrollmentClosed108,
  badgeEnrollmentClosed204,
  heartlineLogo,
} from '../constants';

export const SmallScreenNavItem = styled.span`
  a {
    color: black;
    font-family: Helvetica, Arial, sans-serif;
    font-size: ${rem(34)};
    letter-spacing: 0px;
    font-weight: normal;
    line-height: 40px;
  }
`;

const smallScreenBreakpoint = Breakpoint.SMALL;

const SmallScreenMenu = () => {
  const [open, setOpen] = useState(false);

  const smallScreenMenuItems = [
    {
      name: <React.Fragment>About &rsaquo;</React.Fragment>,
      route: ABOUT_ROUTE,
    },
    {
      name: <React.Fragment>FAQ &rsaquo;</React.Fragment>,
      route: FAQ_ROUTE,
    },
    {
      name: <React.Fragment>Our Team &rsaquo;</React.Fragment>,
      route: TEAM_ROUTE,
    },
    {
      name: <React.Fragment>For Providers &rsaquo;</React.Fragment>,
      route: PROVIDERS_ROUTE,
    },
  ];

  return (
    <MediaQuery maxWidth={smallScreenBreakpoint}>
      <Grid.Box width={1 / 5} textAlign="right">
        <p onClick={() => setOpen(!open)}>
          <span className="header-link">{open ? 'Close' : 'Menu'}</span>
        </p>
      </Grid.Box>
      {open && (
        <Grid.Flex flexWrap="wrap" alignItems="center" mt={rem(66)}>
          {smallScreenMenuItems.map(menuItem => (
            <Grid.Box width={1} mb={rem(16)} key={menuItem.route}>
              <SmallScreenNavItem>
                <Link to={menuItem.route}>{menuItem.name}</Link>
              </SmallScreenNavItem>
            </Grid.Box>
          ))}
          <Grid.Box width={1} textAlign="center" my={rem(30)}>
            <Image src={badgeEnrollmentClosed204} alt="Enrollment Closed" />
          </Grid.Box>
        </Grid.Flex>
      )}
    </MediaQuery>
  );
};

const HeaderLink = ({ route, linkText }) => {
  return (
    <Grid.Box textAlign="center" mr={[0, rem(10), rem(10), rem(30)]}>
      <p
        style={{
          fontSize: useMediaQuery(isMediumScreenQuery) ? '0.7rem' : 'inherit',
        }}
      >
        <Link to={route}>{linkText} &rsaquo;</Link>
      </p>
    </Grid.Box>
  );
};

const Header = () => {
  return (
    <Raised bg="white" as="header" role="banner">
      <Grid
        pl={rem(20)}
        pr={[rem(20), rem(0), rem(20), rem(20)]}
        py={[rem(15), rem(20), rem(35), rem(35)]}
      >
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid.Box>
            <Link to={HOME_ROUTE}>
              <Image
                src={heartlineLogo}
                alt="Heartline Logo"
                style={{ width: rem(166) }}
                dontUse2x
              />
            </Link>
          </Grid.Box>
          <SmallScreenMenu />
          <MediaQuery minWidth={smallScreenBreakpoint}>
            <Grid.Flex flexWrap="wrap" alignItems="center">
              <HeaderLink route={ABOUT_ROUTE} linkText="About the Study" />
              <HeaderLink
                route={FAQ_ROUTE}
                linkText="Frequently Asked Questions"
              />
              <HeaderLink route={TEAM_ROUTE} linkText="Our Team" />
              <HeaderLink route={PROVIDERS_ROUTE} linkText="For Providers" />
              <Grid.Box textAlign="center" mr={[0, rem(10), 0, 0]}>
                <Image src={badgeEnrollmentClosed108} alt="Enrollment Closed" />
              </Grid.Box>
            </Grid.Flex>
          </MediaQuery>
        </Grid.Flex>
      </Grid>
    </Raised>
  );
};

export default Header;
