import { rem } from 'polished';
import React from 'react';
import styled from 'styled-components';

import Grid from '../../components/grid';
import { SubHead } from '../LandingAssets';
import Image from '../images/Image';
import {
  sans,
  ECMichaelGibson,
  ECJohnSpertus,
  ECJaredBunch,
  ECAnneCurtis,
  ECSchuylerJones,
  ECDJLakkireddy,
  ECRodPassman,
  ECEricPeterson,
  ECJeremyRuskin,
  ECStevenSteinhubl,
  ECMichaelTarino,
  ECNassirMarrouche,
  ECLeslieSaxon,
} from '../../constants';

const MemberName = styled.p`
  color: black;
  font-size: ${rem(19)};
  font-family: Helvetica-Bold, ${() => sans};
  font-weight: bold;
  text-align: center;
  letter-spacing: ${rem(0.01)};
  line-height: ${rem(24)};
  margin: ${rem(30)} 0 ${rem(8)};
`;

const ExecutiveCommitteeMembers = () => {
  const memberData = [
    {
      name: 'C. Michael Gibson, M.D.\nCo-Chair',
      description: 'Professor of Medicine, Harvard Medical School',
      imageUrl: ECMichaelGibson,
    },
    {
      name: 'John A. Spertus, M.D.\nCo-Chair',
      description: 'Chair and Professor, University of Missouri',
      imageUrl: ECJohnSpertus,
    },
    {
      name: 'William Schuyler Jones, M.D.',
      description:
        'Assoc. Professor of Medicine, Duke University Medical Center',
      imageUrl: ECSchuylerJones,
    },
    {
      name: 'T. Jared Bunch, M.D.',
      description:
        'Dir., Electrophysiology Lab, University of Utah School of Medicine',
      imageUrl: ECJaredBunch,
    },
    {
      name: 'Anne B. Curtis, M.D.',
      description:
        'Chair & Distinguished Professor, State University of NY at Buffalo',
      imageUrl: ECAnneCurtis,
    },
    {
      name: 'Dhanunjaya (DJ) Lakkireddy, M.D.',
      description:
        'Exec. Medical Dir., Electrophysiology, Kansas City Heart Rhythm Institute',
      imageUrl: ECDJLakkireddy,
    },
    {
      name: 'Rod S. Passman, M.D.',
      description:
        'Professor of Medicine, Northwestern University School of Medicine',
      imageUrl: ECRodPassman,
    },
    {
      name: 'Eric D. Peterson, M.D.',
      description:
        'Distinguished Professor of Medicine, Duke University Medical Center',
      imageUrl: ECEricPeterson,
    },
    {
      name: 'Jeremy N. Ruskin, M.D.',
      description: 'Professor of Medicine, Harvard Medical School',
      imageUrl: ECJeremyRuskin,
    },
    {
      name: 'Steven R. Steinhubl, M.D.',
      description:
        'Dir., Digital Medicine, Scripps Research Translational Institute',
      imageUrl: ECStevenSteinhubl,
    },
    {
      name: 'Michael Tarino, M.B.A.',
      description: 'Chief Experience Officer,\nBIND BENEFITS, Inc.',
      imageUrl: ECMichaelTarino,
    },
    {
      name: 'Nassir Marrouche, M.D.',
      description: 'Professor of Medicine in Cardiology,\nTulane University',
      imageUrl: ECNassirMarrouche,
    },
    {
      name: 'Leslie A. Saxon, M.D.',
      description:
        'Professor of Medicine and Clinical Scholar,\nKeck School of Medicine, University of Southern California',
      imageUrl: ECLeslieSaxon,
    },
  ];

  const formatNewlines = str => {
    return str.split('\n').map(strPart => {
      return (
        <React.Fragment key={strPart}>
          <span>{strPart}</span>
          <br />
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <Grid.Flex
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="center"
        mb={[0, 0, rem(-70), rem(-70)]}
      >
        {memberData.map(member => (
          <Grid.Box
            width={[1, 1, 1 / 3, 1 / 3]}
            textAlign="center"
            key={member.name}
          >
            <Image
              src={member.imageUrl}
              alt={member.name}
              style={{ width: rem(200) }}
            />
            <div style={{ maxWidth: rem(250), margin: '0 auto' }}>
              <MemberName>{formatNewlines(member.name)}</MemberName>
              <SubHead style={{ marginBottom: rem(78) }}>
                {formatNewlines(member.description)}
              </SubHead>
            </div>
          </Grid.Box>
        ))}
      </Grid.Flex>
    </React.Fragment>
  );
};

export default ExecutiveCommitteeMembers;
