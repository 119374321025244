import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { textAlign, space } from 'styled-system';

import { Breakpoint, getBreakpoint } from './breakpoint';
import {
  serif,
  sans,
  darkGrayTextColor,
  almostBlackTextColor,
  grayBackground,
  slateColor,
  blackTextColor,
  lightBlueTextColor,
  linkBlueTextColor,
  whiteBgColor,
} from '../constants';
import Grid from './grid';

const subheadStyles = css`
  font-family: ${props => props.fontFamily || sans};
  font-size: ${rem(19)};
  font-weight: ${props =>
    props.fontWeight ? props.fontWeight : props.bold ? '600' : 'normal'};
  color: ${() => blackTextColor};
  letter-spacing: ${rem(0.1)};
  line-height: ${rem(24)};

  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(18)};
  }
`;

const subheadLinkStyles = css`
  letter-spacing: ${rem(0.3)};
  font-family: ${() => sans};
`;

export const SubHead = styled.p`
  ${subheadStyles}
  ${space}
  a {
    ${subheadStyles}
    ${subheadLinkStyles}
    color: ${props => props.color || 'rgb(62, 65, 86)'};
    font-weight: ${props => props.fontWeight || 'bold'};
  }
`;

export const HeroSubHead = styled.p`
  font-family: 'SFProDisplay-Regular', ${() => sans};
  font-size: ${rem(24)};
  color: ${() => blackTextColor};
  line-height: ${rem(32)};

  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(19)};
  }

  a {
    color: ${() => linkBlueTextColor};
  }
`;

export const Card = styled.div`
  background-color: ${() => whiteBgColor};
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: ${props => (props.height ? props.height : undefined)};
  margin: 8px;
  padding: 32px 16px;
`;

export const LargeStatistic = styled.p`
  font-family: ${() => sans};
  font-weight: bold;
  font-size: ${rem(60)};
  color: ${() => blackTextColor};
  line-height: 0;
  ${space}
`;

export const SubHeadList = styled.ul`
  li {
    ${subheadStyles}
    padding-bottom: ${props => (props.addPaddingAfterListItems ? rem(14) : 0)};
  }
  li a {
    ${subheadLinkStyles}
  }
`;

export const PreHeaderText = styled.p`
  font-family: ${() => sans};
  font-size: ${rem(21)};
  color: ${() => lightBlueTextColor};
  font-weight: 500;
  margin-bottom: 0;
`;

export const SidebarItem = styled.p`
  font-size: ${rem(17)};
  color: ${props => props.color || 'black'};
  font-family: ${() => sans};
  line-height: ${rem(22)};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;

const FootnoteStyles = css`
  color: ${() => darkGrayTextColor};
  font-size: ${rem(15)};
  font-family: ${() => sans};
  letter-spacing: ${rem(-0.03)};
  line-height: ${rem(18)};
`;

export const Footnote = styled.p`
  ${FootnoteStyles}
  a {
    ${FootnoteStyles}
  }
`;

export const PageHeader = styled.p`
  color: ${() => blackTextColor};
  font-size: ${rem(48)};
  line-height: ${rem(60)};
  font-family: ${() => serif};
  font-weight: 600;
  margin-bottom: ${props => rem(props.marginBottom || 24)};
  ${textAlign}
  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(34)};
    line-height: ${rem(42)};
  }
`;

export const LargeHeading = styled.p`
  color: black;
  font-family: ${() => serif};
  font-size: ${rem(60)};
  line-height: ${rem(72)};
  margin-bottom: ${rem(22)};
  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(34)};
    font-family: ${() => serif};
    font-weight: 600;
    letter-spacing: 0px;
    line-height: ${rem(42)};
  }
`;

export const SectionHeader = styled.p`
  color: black;
  font-family: ${() => serif};
  font-size: ${rem(40)};
  letter-spacing: 0px;
  line-height: ${rem(48)};
  text-align: ${props => props.textAlign || 'center'};
  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(34)};
    line-height: ${rem(42)};
  }
`;

export const LandingSectionHeader = styled.p`
  color: ${() => blackTextColor};
  font-family: ${() => serif};
  font-size: ${rem(40)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(48)};
  text-align: ${props => props.textAlign || 'left'};
  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(34)};
    line-height: ${rem(42)};
  }
  ${space}
`;

export const TextGroupHeader = styled.p`
  font-family: ${() => serif};
  font-size: ${rem(24)};
  font-weight: ${props => props.fontWeight || 500};
  line-height: ${props => rem(props.lineHeight || 24)};
  ${space}
`;

export const Arrow = styled.span`
  display: inline-block;
  transform: rotate(
    ${props => {
      if (props.up) {
        return '0deg';
      }
      if (props.down) {
        return '180deg';
      }
      if (props.right) {
        return '90deg';
      }
      return '0deg';
    }}
  );
  &::before {
    content: '\u2303';
  }
`;

export const ConfirmationButton = styled.button`
  color: ${() => slateColor};
  background-color: ${() => grayBackground};
  width: 6.5rem;
  border-radius: ${rem(10)};
`;

export const BlueLink = styled.a`
  font-family: ${() => sans};
  font-size: ${rem(17)};
  color: ${() => linkBlueTextColor};
`;

export const LoadingIndicator = styled.div`
  display: inline-block;
  width: ${props => rem(props.length ? props.length : 50)};
  height: ${props => rem(props.length ? props.length : 50)};
  border: ${rem(3)} solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: loading-spin 1s ease-in-out infinite;
  -webkit-animation: loading-spin 1s ease-in-out infinite;

  @keyframes loading-spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loading-spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;

export const GridGroupHeader = styled.div`
  color: ${() => almostBlackTextColor};
  font-family: 'IBM Plex Sans', ${() => sans};
  font-size: ${rem(60)};
  font-weight: bold;
  height: ${rem(50)};
  letter-spacing: 0px;
  line-height: ${rem(60)};
`;

export const GridGroupSubHeader = styled.div`
  color: rgb(120, 122, 137);
  font-family: 'SFProText-Bold', ${() => sans};
  font-size: ${rem(19)};
  font-weight: bold;
  height: ${rem(24)};
  letter-spacing: ${rem(-0.49)};
  line-height: ${rem(24)};
  margin-bottom: ${rem(12)};
`;

const GridStatsBox = styled(Grid.Box)`
  p {
    color: ${() => darkGrayTextColor};
    font-family: ${() => sans};
    font-size: ${rem(19)};
    letter-spacing: ${rem(-0.29)};
    line-height: ${rem(24)};
    @media screen and (min-width: ${getBreakpoint(Breakpoint.MEDIUM)}) {
      max-width: 80%;
    }
  }
`;

export const GridStats = ({ heading, body, last }) => (
  <GridStatsBox
    width={[1, 1, 1 / 3, 1 / 3]}
    pr={[0, 0, 0, last ? 0 : rem(40)]}
    mb={[rem(60), rem(40), 0, 0]}
  >
    {heading}
    <p>{body}</p>
  </GridStatsBox>
);

GridStats.propTypes = {
  heading: PropTypes.node.isRequired,
  body: PropTypes.string.isRequired,
};

export const Raised = styled.section`
  background: ${props => props.bg};
`;

Raised.propTypes = {
  bg: PropTypes.string.isRequired,
};

Raised.defaultProps = { bg: `#eee` };

export const InnerGrid = styled(Grid)`
  max-width: ${props =>
    rem((props.theme.gridSize / 11) * (props.multiplier || 9))};
`;

export const SocialCollection = styled.div`
  position: relative;
  z-index: 0;

  @media screen and (max-width: ${getBreakpoint(Breakpoint.MEDIUM)}) {
    display: none;
  }
`;

export const SocialButtons = styled.img`
  width: ${rem(40)};
`;
