import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactResponsiveMediaQuery from 'react-responsive';
import { withTheme } from 'styled-components';
import toPx from 'to-px';

import { defaultBreakpoints, defaultSmallestBreakpoint } from '../theme';
import theme from '../theme';

export const getThemeValue = (name, defaultValue = '#eb3f54') => props =>
  _.get(props, `theme.${name}`, defaultValue);

export const getBreakpoint = size => props =>
  getThemeValue(`breakpoints[${size}]`, defaultSmallestBreakpoint)(props);

export const Breakpoint = {
  SMALL: 0,
  MEDIUM: 1,
  LARGE: 2,
  LARGER: 3,
};

const MediaQuery = withTheme(({ maxWidth, minWidth, ...props }) => {
  return (
    <ReactResponsiveMediaQuery
      minWidth={
        !_.isUndefined(minWidth) ? toPx(getBreakpoint(minWidth)(props)) + 1 : 0
      }
      maxWidth={
        !_.isUndefined(maxWidth) ? toPx(getBreakpoint(maxWidth)(props)) : 99999
      }
      {...props}
    />
  );
});

export const isSmallScreenQuery = {
  query: `(max-width: ${toPx(getBreakpoint(Breakpoint.SMALL)({ theme }))}px)`,
};

export const isMediumScreenQuery = {
  minWidth: `${toPx(getBreakpoint(Breakpoint.SMALL)({ theme }))}px`,
  maxWidth: `${toPx(getBreakpoint(Breakpoint.MEDIUM)({ theme }))}px`,
};

export const isLargeScreenQuery = {
  minWidth: `${toPx(getBreakpoint(Breakpoint.LARGE)({ theme }))}px`,
};

MediaQuery.defaultProps = {
  theme: {
    breakpoints: defaultBreakpoints,
  },
};

const WidthPropTypes = PropTypes.oneOf(Object.values(Breakpoint));

MediaQuery.propTypes = {
  theme: PropTypes.object.isRequired,
  minWidth: WidthPropTypes,
  maxWidth: WidthPropTypes,
};

export default MediaQuery;
