import { rem } from 'polished';

export const defaultSmallestBreakpoint = '32em';
export const defaultBreakpoints = [
  defaultSmallestBreakpoint,
  '51em',
  '64em',
  '78em',
];

const theme = {
  breakpoints: defaultBreakpoints,
  gridSize: 1200,
  space: [
    0,
    rem(4),
    rem(8),
    rem(16),
    rem(32),
    rem(64),
    rem(128),
    rem(256),
    rem(512),
  ],
};

export default theme;
