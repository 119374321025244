import React, { Component } from 'react';
import { rem } from 'polished';
import ReactPixel from 'react-facebook-pixel';

import Grid from '../grid';
import { ConfirmationButton, SubHead } from '../LandingAssets';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import {
  disabledGrayBackgroundColor,
  downloadAppFromAppStoreTarget,
  downloadAppFromAppStoreUrl,
  facebookPixelDownloadAppEventName,
} from '../../constants';

const covid19BannerBoxStyles = {
  top: 0,
  left: 0,
  fontSize: rem(15),
  lineHeight: rem(20),
  paddingTop: rem(20),
  width: '100%',
};

const hasViewedCovid19BannerKey = 'hasViewedCovid19Banner';
const hasViewedValue = 'true';

class Covid19Banner extends Component {
  constructor(props) {
    super(props);

    this.handleButtonClicked = this.handleButtonClicked.bind(this);
  }

  handleButtonClicked() {
    localStorage.setItem(hasViewedCovid19BannerKey, hasViewedValue);

    this.forceUpdate();
  }

  render() {
    if (localStorage.getItem(hasViewedCovid19BannerKey) === hasViewedValue) {
      return null;
    }

    return (
      <Grid.Flex
        flexWrap="wrap"
        justifyContent={['flex-start', 'center', 'center', 'center']}
        alignItems="center"
        style={{
          ...covid19BannerBoxStyles,
          backgroundColor: disabledGrayBackgroundColor,
        }}
        px={[rem(20), rem(10), rem(20), 0]}
      >
        <Grid.Box
          width={[1, 4 / 5, 4 / 5, 7 / 10]}
          mr={[0, rem(14), rem(50), rem(80)]}
          mb={[rem(10), 0, 0, 0]}
        >
          <SubHead>
            <strong>COVID-19 Update</strong>: The Heartline Study remains open
            for enrollment. You can participate right from your phone, in the
            comfort of your home.{' '}
            <a
              href={downloadAppFromAppStoreUrl()}
              target={downloadAppFromAppStoreTarget}
              rel="noopener noreferrer"
              onClick={() => {
                trackGoogleAnalyticsEvent({
                  category: 'app_store',
                  action: 'click',
                  label: 'location:covid_19_banner',
                });

                ReactPixel.track(facebookPixelDownloadAppEventName);
              }}
            >
              Download the app today
            </a>{' '}
            to see if you’re eligible.
          </SubHead>
        </Grid.Box>
        <Grid.Box width={[[1, 1 / 5, 1 / 5, 3 / 10]]}>
          <ConfirmationButton onClick={this.handleButtonClicked}>
            OK
          </ConfirmationButton>
        </Grid.Box>
      </Grid.Flex>
    );
  }
}

export default Covid19Banner;
