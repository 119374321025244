import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/isEmpty';

import { LocationProvider, createHistory } from '@reach/router';

import App from './App';
import * as serviceWorker from './serviceWorker';

import axios from 'axios';
import queryString from 'query-string';
import { getBackendHost, handleExternalRedirects } from './utils';
import {
  utmMediumKey,
  utmSourceKey,
  utmCampaignKey,
  utmContentKey,
} from './constants';
import { initializeTracking } from './tracking';

const queryStringValues = queryString.parse(window.location.search);

const backendQueryString = queryStringValues.backend;
axios.defaults.baseURL = getBackendHost(backendQueryString);

[utmSourceKey, utmMediumKey, utmCampaignKey, utmContentKey].forEach(utmKey => {
  const utmKeyValue = queryStringValues[utmKey];
  if (!isEmpty(utmKeyValue)) {
    window.sessionStorage.setItem(utmKey, utmKeyValue);
  } else {
    window.sessionStorage.removeItem(utmKey);
  }
});

const history = createHistory(window);

initializeTracking();

const performedExternalRedirect = handleExternalRedirects();
// If an external redirect was performed (e.g. to the Apple App Store),
// do not load the app. Simple redirect the user.
if (!performedExternalRedirect) {
  history.listen(() => {
    // scrolling all links to top of page
    window.scrollTo({ top: 0, left: 0 });
  });

  ReactDOM.render(
    <LocationProvider history={history}>
      <App />
    </LocationProvider>,
    document.getElementById('root'),
  );

  serviceWorker.unregister();
}
