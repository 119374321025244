import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CheckConfirmation from '../../assets/check-confirmation';
import axios from 'axios';
import { wrapComponent } from '../../utils';
import queryString from 'query-string';
import ImageHeaderSubHeader from './ImageHeaderSubheader';

class ActionConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: null,
      error: false,
    };
  }

  componentDidMount() {
    const queryStringValues = queryString.parse(this.props.location.search);
    const userToken = queryStringValues.token;

    if (!userToken) {
      this.setState({
        error: true,
      });
      return;
    }

    this.sendRequestToBackend(userToken);
  }

  async sendRequestToBackend(userToken) {
    try {
      let axiosHandle = axios.post;
      if (this.props.requestMethod) {
        axiosHandle = axios[this.props.requestMethod];
      }

      const response = await axiosHandle(this.props.endpointUrl, {
        token: userToken,
      });
      this.setState({
        success: response.data.success,
      });
    } catch (error) {
      this.setState({
        success: false,
        error: true,
      });
    }
  }

  textAndImageToDisplay() {
    if (this.state.error) {
      return {
        header: 'Oops! There was a problem.',
        subheader: 'Please try refreshing, or try again in a few minutes.',
      };
    } else if (this.state.success) {
      return {
        header: this.props.successHeaderText,
        subheader: this.props.successSubheaderText,
        image: <CheckConfirmation style={{ marginTop: '-1.3rem' }} />,
      };
    } else {
      return {
        header: '',
        subheader: '',
        image: '',
      };
    }
  }

  render() {
    return (
      <ImageHeaderSubHeader
        hasError={this.state.error}
        contentToDisplay={this.textAndImageToDisplay()}
      />
    );
  }
}

ActionConfirmation.propTypes = {
  endpointUrl: PropTypes.string.isRequired,
  requestMethod: PropTypes.oneOf(['put', 'post']),
  successHeaderText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  successSubheaderText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

export default wrapComponent(ActionConfirmation);
