import React from 'react';
import { HiddenPagesCss } from '../../constants';
import { InnerGrid } from '../LandingAssets';
import { rem } from 'polished';

const ImageHeaderSubHeader = props => {
  return (
    <React.Fragment>
      <HiddenPagesCss />
      <section role="main">
        <InnerGrid
          multiplier={4}
          px={rem(10)}
          my={
            props.hasError
              ? [rem(100), rem(150), rem(175)]
              : [rem(40), rem(60), rem(70)]
          }
        >
          {!props.hasError && props.contentToDisplay.image}
          <h4 className="status-header">{props.contentToDisplay.header}</h4>
          <p>{props.contentToDisplay.subheader}</p>
        </InnerGrid>
      </section>
    </React.Fragment>
  );
};

export default ImageHeaderSubHeader;
