import React, { Component } from 'react';
import { rem } from 'polished';
import { scroller } from 'react-scroll';

import Grid from '../grid';
import {
  aboutHeaderScrollName,
  dataPrivacyHeaderScrollName,
  aboutAppleWatchHeaderScrollName,
  forHCProvidersHeaderScrollName,
} from '../../constants';
import { SidebarItem } from '../LandingAssets';

const navSections = [
  {
    name: 'About the Study',
    target: aboutHeaderScrollName,
  },
  {
    name: 'Data and Privacy',
    target: dataPrivacyHeaderScrollName,
  },
  {
    name: 'About the Apple Watch',
    target: aboutAppleWatchHeaderScrollName,
  },
  {
    name: 'For Healthcare Providers',
    target: forHCProvidersHeaderScrollName,
  },
];

class FAQNavigatorItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredOver: false,
    };

    this.toggleHover = this.toggleHover.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  toggleHover() {
    this.setState(prevState => ({
      hoveredOver: !prevState.hoveredOver,
    }));
  }

  handleClick() {
    scroller.scrollTo(this.props.target, {
      smooth: true,
      offset: -15,
    });
  }

  render() {
    const { name } = this.props;

    return (
      <Grid.Box
        width={1}
        pt={rem(14)}
        pb={rem(0)}
        pl={rem(20)}
        className="hover--light-red-bg utils--cursor-pointer"
        onMouseEnter={this.toggleHover}
        onMouseLeave={this.toggleHover}
        onClick={this.handleClick}
      >
        <SidebarItem className={this.state.hoveredOver ? 'utils--bold' : ''}>
          {name}
        </SidebarItem>
      </Grid.Box>
    );
  }
}

const FAQNavigator = () => {
  return (
    <Grid.Flex
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      mr={[0, 0, rem(20), rem(20)]}
    >
      {navSections.map(navSection => (
        <FAQNavigatorItem
          target={navSection.target}
          name={navSection.name}
          key={navSection.name}
        />
      ))}
    </Grid.Flex>
  );
};

export default FAQNavigator;
