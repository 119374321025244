import React from 'react';
import { Helmet } from 'react-helmet';

import { Raised } from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import HomeHero from '../components/landing/HomeHero';
import EligibilityRequirements from '../components/landing/EligibilityRequirements';
import HeartlineStudyExplanation from '../components/landing/HeartlineStudyExplanation';
import PrivacyAndSecurity from '../components/landing/PrivacyAndSecurity';
import ReviewsAndRatings from '../components/landing/ReviewsAndRatings';
import { trackPageViewForCurrentPath } from '../tracking';

import { grayBackground, whiteBgColor, beigeBgColor } from '../constants';

const Landing = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <Wrapper>
      <Helmet>
        <title>Welcome to the Heartline Study</title>
        <meta
          name="description"
          content="Wear your heart. Share your heart. Now technology makes it possible for you to understand and directly engage in your heart health by helping to detect irregular heart beats consistent with AFib."
        />
      </Helmet>
      <React.Fragment>
        <Raised bg="#FEF4ED" role="main">
          <HomeHero />
        </Raised>
        <Raised bg={grayBackground} role="main">
          <HeartlineStudyExplanation />
        </Raised>
        <Raised bg={whiteBgColor} role="main">
          <EligibilityRequirements />
        </Raised>
        <Raised bg={beigeBgColor} role="main">
          <ReviewsAndRatings />
        </Raised>
        <Raised bg={whiteBgColor} role="main">
          <PrivacyAndSecurity />
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default Landing;
