import React from 'react';

import { SubHead } from '../LandingAssets';
import { trackGoogleAnalyticsEvent } from '../../tracking';

import { learnMoreAppleWatchHealthUrl } from '../../constants';

export const aboutAppleWatchHeaderText = 'About the Apple Watch';
export const aboutAppleWatchQuestions = [
  {
    question:
      'How will the Apple Watch be used in this study and what will participants need to do?',
    answer: (
      <React.Fragment>
        <SubHead>
          Some participants were assigned to a study group requiring an Apple Watch. These 
          participants need they to wear their watch every day for the duration of the 
          two-year, active phase and the one-year, passive phase of the study.
        </SubHead>
        <SubHead>
          Their participation and contribution will help researchers understand whether the 
          features on the Apple Watch and the Heartline app can help with early detection of 
          AFib and improve overall health outcomes.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'Where can I learn more about Apple Watch?',
    answer: (
      <SubHead>
        Learn more about the Apple Watch{' '}
        <a
          href={learnMoreAppleWatchHealthUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            trackGoogleAnalyticsEvent({
              category: 'learn_more_watch_heart_health',
              action: 'click',
              label: `link:${learnMoreAppleWatchHealthUrl};location:faq_about_apple_watch`,
            })
          }
        >
          here
        </a>
        .
      </SubHead>
    ),
  },
];
