import React from 'react';
import { rem } from 'polished';

import { InnerGrid, LandingSectionHeader, SubHead } from '../LandingAssets';
import Grid from '../grid';
import EligibilityRequirementsIconGroup from '../for_providers/EligibilityRequirementsIconGroup';

const EligibilityRequirements = () => {
  return (
    <React.Fragment>
      <InnerGrid
        multiplier={11}
        px={[rem(20)]}
        py={[rem(75), rem(75), rem(75), rem(90)]}
      >
        <LandingSectionHeader>Eligibility requirements</LandingSectionHeader>
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="left"
          mt={rem(-10)}
          mb={rem(60)}
        >
          <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
            <SubHead>
              These are the key participation requirements; other eligibility participation 
              requirements apply.
            </SubHead>
          </Grid.Box>
        </Grid.Flex>
        <EligibilityRequirementsIconGroup />
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="left"
          mt={rem(-10)}
          mb={rem(60)}
        >
          <Grid.Box width={1}>
            <SubHead>
              The Heartline Study is interested in learning about the health outcomes of 
              people who don’t own an Apple Watch, and may use it for the first time for 
              the study.
            </SubHead>
          </Grid.Box>
        </Grid.Flex>
      </InnerGrid>
    </React.Fragment>
  );
};

export default EligibilityRequirements;
