import { rem } from 'polished';
import React, { useState } from 'react';
import { scroller, Element as ScrollElement } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet';

import {
  InnerGrid,
  Raised,
  SectionHeader,
  TextGroupHeader,
} from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import Grid from '../components/grid';
import QuestionAndAnswer from '../components/faq/QuestionAndAnswer';
import FAQNavigator from '../components/faq/FAQNavigator';
import UpDownArrow from '../components/faq/UpDownArrow';
import { trackPageViewForCurrentPath } from '../tracking';

import {
  aboutHeaderText,
  aboutQuestions,
} from '../components/faq/aboutStudyQuestions';
import {
  dataPrivacyHeaderText,
  dataPrivacyQuestions,
} from '../components/faq/dataAndPrivacyQuestions';
import {
  aboutAppleWatchHeaderText,
  aboutAppleWatchQuestions,
} from '../components/faq/aboutAppleWatch';
import {
  forHCProvidersHeaderText,
  forHCProvidersQuestions,
} from '../components/faq/forHCProviders';
import MediaQuery, {
  Breakpoint,
  isSmallScreenQuery,
} from '../components/breakpoint';

import {
  whiteBgColor,
  aboutHeaderScrollName,
  dataPrivacyHeaderScrollName,
  aboutAppleWatchHeaderScrollName,
  forHCProvidersHeaderScrollName,
} from '../constants';

const FAQGroups = [
  {
    headerText: aboutHeaderText,
    questions: aboutQuestions,
    scrollName: aboutHeaderScrollName,
  },
  {
    headerText: dataPrivacyHeaderText,
    questions: dataPrivacyQuestions,
    scrollName: dataPrivacyHeaderScrollName,
  },
  {
    headerText: aboutAppleWatchHeaderText,
    questions: aboutAppleWatchQuestions,
    scrollName: aboutAppleWatchHeaderScrollName,
  },
  {
    headerText: forHCProvidersHeaderText,
    questions: forHCProvidersQuestions,
    scrollName: forHCProvidersHeaderScrollName,
  },
];

const FAQGroup = props => {
  const isSmallScreen = useMediaQuery(isSmallScreenQuery);

  // Collapse question list by default on smallest screen size.
  const [open, setOpen] = useState(!isSmallScreen);

  const QAs = (
    <React.Fragment>
      {props.questions.map(qa => (
        <QuestionAndAnswer qa={qa} key={qa.question} />
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <ScrollElement name={props.scrollName}>
        <Grid.Flex
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={rem(0)}
          pr={rem(14)}
          onClick={() => setOpen(!open)}
          className="utils--cursor-pointer"
        >
          <Grid.Box width={4 / 5}>
            <TextGroupHeader lineHeight={32} pl={rem(14)}>
              {props.headerText}
            </TextGroupHeader>
          </Grid.Box>
          <Grid.Box width={1 / 5} textAlign="right">
            <MediaQuery maxWidth={Breakpoint.SMALL}>
              <UpDownArrow open={open} pt={11} pb={5} />
            </MediaQuery>
          </Grid.Box>
        </Grid.Flex>
      </ScrollElement>
      <MediaQuery maxWidth={Breakpoint.SMALL}>{open && QAs}</MediaQuery>
      <MediaQuery minWidth={Breakpoint.SMALL}>{QAs}</MediaQuery>
      <MediaQuery maxWidth={Breakpoint.SMALL}>
        <hr style={{ marginTop: rem(14) }} />
      </MediaQuery>
    </React.Fragment>
  );
};

const FAQ = props => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();

    const routerPassedState = props.location.state;
    const scrollTarget = routerPassedState && routerPassedState.scrollTarget;
    if (scrollTarget) {
      scroller.scrollTo(scrollTarget, {
        smooth: true,
        offset: -15,
      });
    }
  }, [props.location.state]);

  const FAQContent = (
    <React.Fragment>
      <SectionHeader textAlign="left" style={{ paddingLeft: rem(14) }}>
        Frequently Asked Questions
      </SectionHeader>
      <MediaQuery maxWidth={Breakpoint.SMALL}>
        <hr style={{ marginTop: rem(100), marginBottom: rem(38) }} />
      </MediaQuery>
      <Grid.Flex
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-start"
        mt={[rem(30), rem(80), rem(80), rem(80)]}
        mb={rem(100)}
      >
        {FAQGroups.map((FAQGroupInfo, index) => (
          <Grid.Box
            width={[1, 1, 1, 7 / 9]}
            mt={index === 0 ? 0 : [rem(30), rem(80), rem(40), rem(80)]}
            key={FAQGroupInfo.scrollName}
          >
            <FAQGroup
              headerText={FAQGroupInfo.headerText}
              questions={FAQGroupInfo.questions}
              scrollName={FAQGroupInfo.scrollName}
            />
          </Grid.Box>
        ))}
      </Grid.Flex>
    </React.Fragment>
  );

  const FAQContentGridBox = (
    <Grid.Box width={[1, 2 / 3, 3 / 4, 3 / 4]}>{FAQContent}</Grid.Box>
  );

  const FAQNavigatorGridBox = (
    <Grid.Box
      width={[0, 1 / 3, 1 / 4, 1 / 4]}
      style={{ position: 'sticky', top: 0 }}
    >
      <FAQNavigator />
    </Grid.Box>
  );

  return (
    <Wrapper>
      <Helmet>
        <title>Frequently Asked Questions about the Heartline Study</title>
        <meta
          name="description"
          content="Wondering how to enroll in the Heartline study and if you are eligible? Curious to know how long the study will be? Participants and providers: get your questions answered"
        />
      </Helmet>
      <React.Fragment>
        <Raised bg={whiteBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={[0, rem(20), rem(20), rem(20)]}
            pt={[rem(60), rem(60), rem(60), rem(60)]}
            pb={[rem(30), rem(10), rem(40), rem(40)]}
          >
            <Grid.Flex
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="center"
            >
              <MediaQuery minWidth={Breakpoint.MEDIUM}>
                {FAQNavigatorGridBox}
              </MediaQuery>
              {FAQContentGridBox}
              <MediaQuery
                minWidth={Breakpoint.SMALL}
                maxWidth={Breakpoint.MEDIUM}
              >
                {FAQNavigatorGridBox}
              </MediaQuery>
            </Grid.Flex>
            <MediaQuery minWidth={Breakpoint.SMALL}>
              <hr />
            </MediaQuery>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-end"
              mt={rem(42)}
              mb={[rem(100), rem(40), rem(10), rem(0)]}
            ></Grid.Flex>
          </InnerGrid>
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default FAQ;
