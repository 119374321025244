import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { rem } from 'polished';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { Breakpoint, getBreakpoint, isSmallScreenQuery } from '../breakpoint';
import { sans, blackTextColor, reviews5StarsSmall } from '../../constants';
import Grid from '../grid';
import { Card } from '../LandingAssets';
import Image from '../images/Image';
import './carousel.custom.css';

export const ReviewTitle = styled.p`
  font-family: 'SFProDisplay-Regular', ${() => sans};
  font-size: ${rem(19)};
  color: ${() => blackTextColor};
  line-height: ${rem(26)};
  font-weight: 600;
  text-align: left;
  margin-bottom: 4px;

  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(21)};
    line-height: ${rem(25)};
  }
`;

export const ReviewDescription = styled.p`
  font-family: 'SFProDisplay-Regular', ${() => sans};
  font-size: ${rem(17)};
  color: ${() => blackTextColor};
  line-height: ${rem(24)};
  text-align: left;

  @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
    font-size: ${rem(19)};
    line-height: ${rem(26)};
  }
`;

const reviews = [
  {
    title: 'Learn More About Your Heart',
    description:
      'App that teaches users more about their heart and heart health. Easy to use and full of useful information.',
  },
  {
    title: 'Easy to use and fun',
    description:
      'I have been in the heart study for about 6 months and love the watch, the app, and the way I am learning as well as making a positive influence on my health ...',
  },
  {
    title: 'Heartline helps advance research',
    description:
      'Heartline helps advance research on people over 65 years of age through use of the Apple Watch. Johnson and Johnson, Apple and users are working together. Users can earn gift cards through points. I have earned more than $50 so far! Join us!',
  },
  {
    title: 'Great motivator',
    description:
      "... this has been a game changer for me, I'm 72 and always been active, but this year was having trouble staying motivated to exercise ... this is just what I needed and I can't say thanks enough...",
  },
  {
    title: 'Great App for the AFIB Study',
    description:
      'I wanted to participate in this study after taking care of my mother who had afib ... Thanks to Apple and J&J for backing research into better health for seniors.',
  },
  {
    title: 'Great info',
    description:
      'Just started recently in the study and have learned a lot about what I can do to improve my heart health by exercise and diet.',
  },
  {
    title: 'A positive approach to health',
    description:
      'Heartline is a helpful and interesting approach to becoming and staying more healthy ... Heartline helps me to think about my choices and my actions.',
  },
];

const AppStoreReviews = () => {
  const isSmallScreen = useMediaQuery(isSmallScreenQuery);
  return (
    <Carousel
      centerMode={isSmallScreen ? false : true}
      centerSlidePercentage={isSmallScreen ? undefined : 25}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
    >
      {reviews.map((review, index) => (
        <Card height="90%" key={`review_${index}`}>
          <ReviewTitle>{review.title}</ReviewTitle>
          <Grid.Box width={[1 / 2]} mb={[rem(16), rem(18)]}>
            <Image src={reviews5StarsSmall} alt="App store rating" />
          </Grid.Box>
          <ReviewDescription>{review.description}</ReviewDescription>
        </Card>
      ))}
    </Carousel>
  );
};

export default AppStoreReviews;
