import { rem } from 'polished';
import React from 'react';
import { Link } from '@reach/router';
import { css } from 'styled-components';
import { Helmet } from 'react-helmet';

import {
  InnerGrid,
  Raised,
  SectionHeader,
  SubHead,
  Arrow,
} from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import Grid from '../components/grid';
import { useMediaQuery } from 'react-responsive';
import { isSmallScreenQuery } from '../components/breakpoint';
import { trackPageViewForCurrentPath } from '../tracking';

import {
  lightGreenBgColor,
  CONTACT_US_ROUTE,
  TELL_US_MORE_SUBROUTE,
  HCP_SUBROUTE,
} from '../constants';

const tellUsMorePath = `${CONTACT_US_ROUTE}${TELL_US_MORE_SUBROUTE}`;
const healthcareProfessionalPath = `${CONTACT_US_ROUTE}${HCP_SUBROUTE}`;

const haveAppOptions = [
  { value: 'Study sign-up process', label: 'Study sign-up process' },
  { value: 'Apple Watch or iPhone', label: 'Apple Watch or iPhone' },
  {
    value: 'ECG app or irregular rhythm',
    label: 'ECG app or irregular rhythm',
  },
  { value: 'Points and rewards', label: 'Points and rewards' },
  { value: 'Heartline actions', label: 'Heartline actions' },
  { value: 'Other', label: 'Other' },
];

const dontHaveAppOptions = [
  { value: 'Study in general', label: 'Study in general' },
  { value: 'Data privacy', label: 'Data privacy' },
  { value: 'Eligibility', label: 'Eligibility' },
  { value: 'Other', label: 'Other' },
];

const supportParticipantOptions = [
  { value: 'Study in general', label: 'Study in general' },
  { value: 'Data privacy', label: 'Data privacy' },
  {
    value: 'Unsubscribe from support team',
    label: 'Unsubscribe from support team',
  },
  {
    value: 'Support team member responsibilities',
    label: 'Support team member responsibilities',
  },
];

const options = [
  {
    description: 'I have the Heartline app',
    path: tellUsMorePath,
    questionTopicOptions: haveAppOptions,
  },
  {
    description: 'I don’t have the Heartline app',
    path: tellUsMorePath,
    questionTopicOptions: dontHaveAppOptions,
  },
  {
    description: "I'm supporting a Heartline participant",
    path: tellUsMorePath,
    questionTopicOptions: supportParticipantOptions,
  },
  {
    description: "I'm a healthcare professional",
    path: healthcareProfessionalPath,
  },
  {
    description: 'Other',
    path: tellUsMorePath,
  },
];

const helpOptionStyles = css`
  background: white;
  border-radius: ${rem(3)};
  border: ${rem(1)} solid rgb(218, 218, 218);
  margin-bottom: ${rem(12)};
`;

const StillNeedHelpOption = props => {
  const { description, path, questionTopicOptions } = props.option;

  return (
    <React.Fragment>
      <Link to={path} state={{ questionTopicOptions }}>
        <Grid.Flex
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={rem(0)}
          px={rem(14)}
          css={helpOptionStyles}
        >
          <Grid.Box width={4 / 5}>
            <SubHead style={{ margin: `${rem(14)} ${rem(10)}` }}>
              {description}
            </SubHead>
          </Grid.Box>
          <Grid.Box width={1 / 5} textAlign="right">
            <Arrow right style={{ paddingTop: rem(34) }} />
          </Grid.Box>
        </Grid.Flex>
      </Link>
    </React.Fragment>
  );
};

const Contact = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <Wrapper>
      <Helmet>
        <title>Need Help with the Heartline Study?</title>
        <meta
          name="description"
          content="Get assistance for any questions about account information, the Heartline app, the Apple Watch, AFib in general, or data and privacy."
        />
      </Helmet>
      <React.Fragment>
        <Raised bg={lightGreenBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={[rem(20)]}
            pt={[rem(60), rem(60), rem(60), rem(60)]}
            pb={[rem(30), rem(10), rem(40), rem(40)]}
          >
            <SectionHeader
              textAlign={useMediaQuery(isSmallScreenQuery) ? 'left' : 'center'}
            >
              First, tell us about yourself
            </SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={[rem(120), rem(80), rem(80), rem(40)]}
              mb={rem(40)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                {options.map(option => (
                  <StillNeedHelpOption
                    option={option}
                    key={option.description}
                  />
                ))}
              </Grid.Box>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default Contact;
