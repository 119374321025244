import React, { Component } from 'react';
import axios from 'axios';
import queryString from 'query-string';

import { InnerGrid, LoadingIndicator } from '../components/LandingAssets';
import { HiddenPagesCss, supportTeamNo, supportTeamYes } from '../constants';
import { rem } from 'polished';
import { wrapComponent } from '../utils';
import Image from '../components/images/Image';
import { trackPageViewForCurrentPath } from '../tracking';

const VALID_ACTIONS = {
  CONFIRM: 'confirm',
  DECLINE: 'decline',
  WITHDRAW: 'withdraw',
};

class SupportTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      success: false,
      error: false,
      errorStatusCode: null,
      participant_first_name: null,
      participant_last_name: null,
    };
  }

  componentDidMount() {
    trackPageViewForCurrentPath();

    const queryStringValues = queryString.parse(this.props.location.search);
    const partnerId = queryStringValues.partner_id;
    const emailToken = queryStringValues.email_token;

    const validActions = [
      VALID_ACTIONS.CONFIRM,
      VALID_ACTIONS.DECLINE,
      VALID_ACTIONS.WITHDRAW,
    ];
    if (
      !validActions.includes(this.props.action) ||
      !emailToken ||
      !partnerId
    ) {
      this.setState({
        error: true,
        loading: false,
      });
      return;
    }

    this.runSelectedActionAgainstBackend(partnerId, emailToken);
  }

  async runSelectedActionAgainstBackend(partnerId, emailToken) {
    try {
      const response = await axios.get(
        `/partners/${partnerId}/${this.props.action}`,
        {
          params: {
            email_token: emailToken,
          },
        },
      );
      if (response.status === 200) {
        this.setState({
          loading: false,
          success: response.data.success,
          participant_first_name: response.data.participant_first_name,
          participant_last_name: response.data.participant_last_name,
        });
      }
    } catch (error) {
      const errorResponse = error.response;
      const responseData = errorResponse && errorResponse.data;
      this.setState({
        loading: false,
        error: true,
        participant_first_name: responseData.participant_first_name,
        errorStatusCode: errorResponse.status,
      });
    }
  }

  textAndImageToDisplay() {
    if (this.state.error) {
      let defaultHeaderMessage = 'Oops! There was a problem.';
      let defaultSubheaderMessage =
        'Please try refreshing, or try again in a few minutes.';
      if (this.state.errorStatusCode === 403) {
        defaultHeaderMessage = 'This invitation has expired.';
        defaultSubheaderMessage = `Contact ${this.state
          .participant_first_name ||
          'the study participant'} if you'd like to be invited to their Heartline support team again.`;
      }
      return {
        header: defaultHeaderMessage,
        subheader: defaultSubheaderMessage,
      };
    }

    if (this.state.loading) {
      return {
        header: 'Loading, please wait...',
        image: <LoadingIndicator />,
      };
    }

    if (this.state.success) {
      let displayFullName = 'the participant';
      let displayFirstName = displayFullName;
      const firstName = this.state.participant_first_name;
      const lastName = this.state.participant_last_name;
      if (firstName && lastName) {
        displayFullName = `${firstName.trim()} ${lastName.trim()}`;
        displayFirstName = firstName.trim();
      }

      if (this.props.action === VALID_ACTIONS.CONFIRM) {
        return {
          header: `You've joined ${displayFullName}'s support team`,
          subheader: `You'll be notified if ${displayFirstName}'s Apple Watch detects an irregular heart rhythm.`,
          image: (
            <Image src={supportTeamYes} alt="Joined Support Team" dontUse2x />
          ),
        };
      } else if (this.props.action === VALID_ACTIONS.DECLINE) {
        return {
          header: `You've declined ${displayFullName}'s invitation`,
          subheader: `You won't be added to ${displayFirstName}'s support team or receive notifications about ${displayFirstName}'s heart health.`,
          image: (
            <Image src={supportTeamNo} alt="Declined Support Team" dontUse2x />
          ),
        };
      } else if (this.props.action === VALID_ACTIONS.WITHDRAW) {
        return {
          header: `You're no longer on ${displayFullName}'s support team`,
          subheader: `You'll no longer receive notifications about ${displayFirstName}'s heart health.`,
          image: (
            <Image
              src={supportTeamNo}
              alt="Withdrew from Support Team"
              dontUse2x
            />
          ),
        };
      }
    }
  }

  render() {
    const contentToDisplay = this.textAndImageToDisplay();

    return (
      <React.Fragment>
        <HiddenPagesCss />
        <section role="main">
          <InnerGrid
            multiplier={4}
            px={rem(10)}
            my={
              this.state.error
                ? [rem(100), rem(150), rem(175)]
                : [rem(40), rem(60), rem(70)]
            }
          >
            <React.Fragment>
              {contentToDisplay.image}
              <h4 className="status-header">{contentToDisplay.header}</h4>
              <p>{contentToDisplay.subheader}</p>
            </React.Fragment>
          </InnerGrid>
        </section>
      </React.Fragment>
    );
  }
}

export default wrapComponent(SupportTeam);
