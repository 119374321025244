import { rem } from 'polished';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { InnerGrid } from '../components/LandingAssets';
import { wrapComponent } from '../utils';
import { trackPageViewForCurrentPath } from '../tracking';

export const Tab = styled.div`
  margin-left: ${props => rem(props.margin ? props.margin : 80)};
`;

export const PrivacyPolicyStyle = createGlobalStyle`
  section[role=main] {
    h1 {
      font-size: ${rem(31)};
    }
    
    p {
      font-family: Helvetica, sans-serif;
      font-size: ${rem(16)};
      line-height: ${rem(30)};
      margin-bottom: ${rem(35)};
    }

    ul {
      margin-left: ${rem(30)};
    }

    li {
      font-size: ${rem(14)};
      line-height: ${rem(30)};
    }

    .uppercase {
      text-transform: uppercase;
    }
  }
`;

const PrivacyPolicy = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <section role="main">
      <PrivacyPolicyStyle />
      <InnerGrid
        mt={[rem(25), rem(50), rem(150)]}
        mb={[rem(25), rem(50), rem(100)]}
        px={rem(10)}
      >
        <h1>Privacy Policy</h1>

        <p>
          Janssen Scientific Affairs, LLC, an affiliate of Johnson & Johnson
          (Janssen) cares about your privacy and wants you to be familiar with
          how we collect, use, and disclose information. This Privacy Policy
          describes our practices in connection with information (including
          personal information) that we, or our Website providers (Third-Party
          Study Representatives) collect through the Website from which you are
          accessing this Privacy Policy. By providing personal information to us
          or by using the Website, you acknowledge that you have read and
          understand this Privacy Policy.
        </p>

        <p>
          <strong>USE BY MINORS</strong>
          <br />
          The Website is not directed to individuals under the age of 18, and we
          request that these individuals not provide any information through the
          Website. If your child has submitted information and you would like to
          request that such information be removed, please contact us as
          explained below under Contacting Us.
        </p>

        <p>
          <strong>INFORMATION COLLECTION</strong>
          <br />
          This Website may ask you to submit information, including personal
          information, in order for you to benefit from certain features (such
          as updating your password or contacting the support team). “Personal
          information” is data that is associated with an identifiable
          individual, such as contact information (for example, name, email
          address, or phone number).
        </p>
        <p>
          We may combine account information from the Website with account
          information you submit through the Heartline Application (e.g., to
          allow us to reset your password on the website and connect this
          information back to your app account information).
        </p>

        <p>
          <strong>SENSITIVE INFORMATION</strong>
          <br />
          Unless we specifically request or invite it, we ask that you not send
          us, and you not disclose, any sensitive personal information (e.g.,
          Social Security numbers, information related to racial or ethnic
          origin, sex life or sexual orientation, health, criminal background,
          or biometric or genetic data for the purpose of uniquely identifying
          an individual) on or through the Website or otherwise to us.
        </p>

        <p>
          <strong>AUTOMATIC INFORMATION COLLECTION AND USE</strong>
          <br />
          We and our Third-Party Study Representatives may automatically collect
          and use information in the following ways as you navigate around the
          Website:
        </p>

        <Tab>
          <p>
            <strong>Through your browser:</strong> Certain information is
            collected by most browsers, such as your computer type (Windows or
            Mac), screen resolution, operating system name and version, and
            Internet browser type and version. We may collect similar
            information, such as your device type, if you access the Website
            through a mobile device. We use this information to ensure that the
            Website functions properly.
          </p>
          <p>
            <strong>Using cookies:</strong> Cookies are pieces of information
            stored directly on the computer you are using. Cookies allow us to
            collect information such as browser type, time spent on the Website,
            pages visited, and language preferences. We and our Third-Party
            Study Representatives use the information for security purposes, to
            facilitate navigation, display information more effectively, and to
            personalize your experience while using the Website. We also use
            cookies to recognize your computer. In addition, we use cookies to
            gather statistical information about Website usage in order to
            continually improve its design and functionality, understand how
            individuals use it, and to assist us with resolving questions
            regarding it. Cookies further allow us to select which of our
            advertisements or offers are most likely to appeal to you and
            display them while you are on the Service. We may also use cookies
            in online advertising to track consumer responses to our
            advertisements.
          </p>
          <p>
            You can refuse to accept these cookies by following your browser’s
            instructions; however, if you do not accept them, you may experience
            some inconvenience in your use of the Website. To learn more about
            cookies, please visit{' '}
            <a
              href="https://www.allaboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.allaboutcookies.org
            </a>
            .
          </p>
          <p>
            <strong>
              Using pixel tags, web beacons, clear GIFs, or other similar
              technologies:
            </strong>{' '}
            These may be used in connection with some website pages and social
            media sites to, among other things, track the actions of users,
            measure the success of our marketing campaigns, create new audiences
            to enhance our ad placement, and compile statistics about our
            website’s usage.
          </p>
          <p>
            <strong>IP address:</strong> Your IP address is a number that is
            automatically assigned to your computer by your internet Website
            provider. An IP address is identified and logged automatically in
            the server log files of our Third-Party Study Representatives
            whenever a user visits the Website, along with the time of the visit
            and the pages visited. Collecting IP addresses is standard practice
            and is done automatically by many online Websites. Our Third-Party
            Study Representatives may use IP addresses for purposes such as
            calculating Website usage levels, diagnosing server problems, and
            administering the Website, and may also derive your approximate
            location from your IP address.
          </p>
        </Tab>

        <p>
          <strong>HOW WE USE AND DISCLOSE INFORMATION</strong>
          <br />
          We use and disclose information you provide to us as described in this
          Privacy Policy.
        </p>
        <p>
          Third-Party Study Representatives will have access to your information
          on behalf of Janssen and the Study Research Collaborator, Apple Inc.,
          to support certain activities, including to provide the functionality
          of the Website to you and to provide you with related customer
          service.
        </p>
        <p>
          We may also disclose your information that is collected through the
          Website to the following individuals and entities to support the
          Website, limited to only the minimum amount of information needed and
          only permitted to be used for the purposes described above:
        </p>
        <Tab margin={40} style={{ marginBottom: rem(70) }}>
          <ul>
            <li>
              to other members of the Johnson & Johnson group of companies which
              are legal affiliates or subsidiaries, and subject to internal
              Johnson & Johnson review processes and approvals, for the purposes
              described in this Privacy Policy. A list of our affiliates is
              available at{' '}
              <a
                href="http://www.investor.jnj.com/governance/secfilings.cfm"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://www.investor.jnj.com/governance/secfilings.cfm
              </a>{' '}
              (click on the link for Form 10K, Exhibit 21, under “SEC Filings”).
              Janssen Scientific Affairs, LLC is the party responsible for the
              management of the jointly used personal information;
            </li>
            <li>
              to our Third-Party Study Representatives, such as contractors and
              consultants working for or on behalf of Janssen
            </li>
            <li>
              to the Study Research Collaborator, Apple Inc. The Study Research
              Collaborator will not receive any information that directly
              identifies you.
            </li>
          </ul>
        </Tab>

        <p>
          We may use and disclose information we collect automatically as
          described above under “Automatic Information Collection and Use.”
        </p>
        <p>
          In addition, we may use and disclose your information as we believe to
          be necessary or appropriate: (a) to comply with legal process or
          applicable law; (b) as permitted by applicable law to respond to
          requests from public and government authorities; (c) to enforce our
          terms and conditions; and (d) to protect our rights, privacy, safety,
          or property, and/or that of our affiliates, you, or others.
        </p>
        <p>
          In addition, we may use and disclose information that is not in
          personally identifiable form for any purpose permitted by law and in
          support of the Heartline program.
        </p>
        <p>
          Except in connection with the sale, transfer, merger, consolidation or
          other transaction involving all or any part of our company, we will
          not sell, license, or otherwise transfer any rights to your personal
          information to any third party, except as specifically described
          above.
        </p>
        <p>
          If we combine information that is not in personally identifiable form
          with information that is identifiable, we will treat the combined
          information as personal information as long as it is combined.
        </p>

        <p>
          <strong>SECURITY</strong>
          <br />
          We seek to use reasonable organizational, technical, and
          administrative measures designed to protect personal information under
          our control. Unfortunately, no data transmission over the Internet or
          data storage system can be guaranteed to be 100% secure. If you have
          reason to believe that your interaction with us is no longer secure
          (for example, if you feel that the security of any account you have
          with us has been compromised), please immediately notify us in
          accordance with the “Contacting Us” section below.
        </p>
        <p>
          <strong>RETENTION PERIOD</strong>
          <br />
          We will retain your personal information collected from the Website
          for as long as needed or permitted for the purpose(s) for which it was
          obtained as described in this Privacy Policy.
        </p>
        <p>
          <strong>THIRD PARTY WEBSITES</strong>
          <br />
          This Privacy Policy does not address, and we are not responsible for,
          the privacy, information, or other practices of any third parties,
          including any third party operating any site or online Website
          (including, without limitation, any application) that is available
          through this Website or to which this contains a link. The
          availability of, or inclusion of a link to, any such site or property
          on the Website does not imply endorsement of it by us or by our
          affiliates.
        </p>
        <p>
          <strong>CONTACTING US</strong>
          <br />
          If you have any questions about this Privacy Policy, please contact
          Heartline Support at <a href="tel:+18775944021">877-594-4021</a>. or
          via email at{' '}
          <a
            href="mailto:help@heartline.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            help@heartline.com
          </a>
          .
        </p>

        <p>
          <strong>UPDATES TO THIS PRIVACY POLICY</strong>
          <br />
          We may change this Privacy Policy. Any changes to this Privacy Policy
          will become effective when we post the revised Privacy Policy on the
          Website. Your use of the Website following these changes means that
          you accept the revised Privacy Policy. We recommend that you regularly
          review the Privacy Policy when you visit the Website. This Privacy
          Policy was last updated February 2021.
        </p>
      </InnerGrid>
    </section>
  );
};

export default wrapComponent(PrivacyPolicy);
