import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';

import {
  InnerGrid,
  Raised,
  SubHead,
  SectionHeader,
  PageHeader,
} from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import Grid from '../components/grid';
import ExecutiveCommitteeMembers from '../components/our_team/ExecutiveCommitteeMembers';
import { trackPageViewForCurrentPath } from '../tracking';

import { grayBackground, whiteBgColor } from '../constants';

const OurTeam = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <Wrapper>
      <Helmet>
        <title>
          Meet the Heartline Study Team: Johnson & Johnson and Apple
        </title>
        <meta
          name="description"
          content="Johnson & Johnson and Apple are working together to develop the Heartline Study and the Heartline app, bringing together expertise in treating cardiovascular disease, design, technology, and privacy."
        />
      </Helmet>
      <React.Fragment>
        <Raised bg={grayBackground} role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            py={[rem(20), rem(30), rem(40), rem(60)]}
          >
            <PageHeader textAlign="center">
              Why Johnson & Johnson and Apple
            </PageHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(10)}
            >
              <Grid.Box width={[1, 8 / 9, 7 / 9, 6 / 9]}>
                <SubHead>
                  Johnson & Johnson and Apple teams worked together to jointly 
                  develop the research study and the Heartline app.
                  The study brings together Johnson & Johnson’s health expertise
                  with Apple’s expertise in design, technology, and privacy.
                </SubHead>
                <SubHead>
                  Janssen Scientific Affairs, LLC, an affiliate of Johnson &
                  Johnson, is the sponsor of the Heartline™ Study. Apple is
                  supporting the study technology and design of the app.
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
        <Raised bg={whiteBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            py={[rem(30), rem(10), rem(40), rem(80)]}
          >
            <SectionHeader>Our Partners</SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(80)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead>
                  Evidation Health provides the technology and study operations
                  that enable the Heartline app and study experience for
                  participants.
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
            <hr />
          </InnerGrid>
        </Raised>
        <Raised bg={whiteBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            pb={[rem(30), rem(10), rem(40), rem(80)]}
          >
            <SectionHeader>Executive Committee</SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(80)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead>
                  The Executive Committee of the Heartline Study is a group of
                  highly recognized leaders in the fields of clinical research,
                  cardiology, wearable devices, real-world evidence, technology,
                  healthcare insurance, and claims data analysis. The committee
                  members have been engaged by Johnson & Johnson to provide
                  independent scientific oversight and guidance for the duration
                  of the study, including protocol and content development,
                  recruitment and retention of participants, study data results,
                  and publications.
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
            <ExecutiveCommitteeMembers />
          </InnerGrid>
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default OurTeam;
