import { rem } from 'polished';
import React from 'react';

import { SubHead } from '../LandingAssets';
import Grid from '../../components/grid';
import Image from '../images/Image';

import { usResidents, medicareImage, eligibilityPhone } from '../../constants';

const EligibilityRequirementsIconGroup = () => {
  const iconGroups = [
    {
      imageUrl: usResidents,
      imageAltText: 'United States Flag',
      eligibilityDescription: (
        <span>
          U.S. Residents
          <br />
          Ages 65+
        </span>
      ),
    },
    {
      imageUrl: eligibilityPhone,
      imageAltText: 'iPhone 6s or later',
      eligibilityDescription: 'iPhone 6s or later',
    },
    {
      imageUrl: medicareImage,
      imageAltText: 'Medicare',
      eligibilityDescription: 'Medicare coverage',
    },
  ];

  return (
    <Grid.Flex
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {iconGroups.map(iconGroup => (
        <Grid.Box
          width={[1, 1, 1 / 5, 1 / 5]}
          mb={[rem(50), rem(50), rem(40), rem(40)]}
          textAlign="center"
          key={iconGroup.imageAltText}
        >
          <Grid.Flex
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid.Box width={1} mb={[rem(20), rem(20), rem(30), rem(30)]}>
              <Image
                src={iconGroup.imageUrl}
                alt={iconGroup.imageAltText}
                style={{ width: rem(200) }}
              />
            </Grid.Box>
            <Grid.Box width={1}>
              <SubHead bold>{iconGroup.eligibilityDescription}</SubHead>
            </Grid.Box>
          </Grid.Flex>
        </Grid.Box>
      ))}
    </Grid.Flex>
  );
};

export default EligibilityRequirementsIconGroup;
