import React from 'react';

import ActionConfirmation from '../../components/hidden_pages/ActionConfirmation';

const UnsubscribeEmail = props => {
  return (
    <ActionConfirmation
      endpointUrl="/settings/email_unsubscribe"
      requestMethod="put"
      successHeaderText="You've unsubscribed from Heartline emails"
      successSubheaderText="We're sorry to see you go. You'll no longer receive updates about the Heartline Study."
      {...props}
    />
  );
};

export default UnsubscribeEmail;
