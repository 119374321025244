import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';

import Footer from '../components/Footer';
import GlobalStyle from '../components/GlobalStyle';
import Header from '../components/Header';
import theme from '../components/theme';
import { utmSourceKey } from '../constants';
import Covid19Banner from './banners/Covid19Banner';

const Wrapper = ({ children, footer, useCovidBanner }) => {
  React.useEffect(() => {
    // Save off the utm_source, if present, which may get removed from the URL after an action.
    // We can then pass it to, for example, the App Store.
    const queryStringValues = queryString.parse(window.location.search);
    const utmSourceQueryString = queryStringValues[utmSourceKey];
    if (!isEmpty(utmSourceQueryString)) {
      window.sessionStorage.setItem(utmSourceKey, utmSourceQueryString);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        {useCovidBanner && <Covid19Banner />}
        <Header />
        {children}
        <Footer>{footer && footer}</Footer>
      </React.Fragment>
    </ThemeProvider>
  );
};

Wrapper.propTypes = {
  useCovidBanner: PropTypes.bool,
  footer: PropTypes.node,
};

Wrapper.defaultProps = {
  useCovidBanner: false,
};

export default Wrapper;
