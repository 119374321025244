import React from 'react';
import { rem } from 'polished';

import Grid from '../grid';
import { HeroSubHead, PageHeader, SubHead } from '../LandingAssets';
import { getTheAppIcon, jnjLogo } from '../../constants';
import Image from '../images/Image';

const HomeHeroTextContainer = () => {
  return (
    <Grid.Box
      width={[1, 1, 3 / 5, 3 / 5]}
      px={rem(0)}
      pt={rem(40)}
      pb={rem(60)}
    >
      <Grid.Box width={1} mb={[rem(10), 0, 0, 0]}>
        <Image
          src={getTheAppIcon}
          alt="Heartline App Icon"
          style={{ width: rem(60) }}
        />
      </Grid.Box>
      <PageHeader marginBottom={16}>Welcome to Heartline</PageHeader>
      <div className="hero-text-container">
        <HeroSubHead>
          The Heartline study by Johnson & Johnson, in collaboration with Apple,
          explores how everyday technology, like the iPhone and Apple Watch, may
          help with earlier detection of atrial fibrillation (AFib) in people
          age 65 and older.
        </HeroSubHead>
        <HeroSubHead>
          <h5>Enrollment is now closed.</h5>
          This website is for informational purposes only. For more details
          about the Heartline study, future study publications and future
          results, please visit{' '}
          <a
            href="https://clinicaltrials.gov/ct2/show/NCT04276441"
            target="_blank"
            rel="noopener noreferrer"
          >
            ClinicalTrials.gov
          </a>
        </HeroSubHead>
        <SubHead mt={rem(26)} mb={rem(6)}>
          A health study conducted by
        </SubHead>
        <Image
          src={jnjLogo}
          alt="Johnson & Johnson Logo"
          style={{ width: rem(203) }}
          dontUse2x
        />
      </div>
    </Grid.Box>
  );
};

export default HomeHeroTextContainer;
