import React from 'react';
import { rem } from 'polished';

import Grid from '../grid';
import { SubHead } from '../LandingAssets';
import {
  studyPhonesAdvanceResearch,
  studyPhonesEarnRewards,
  studyPhonesHeartHealthyLife,
} from '../../constants';
import Image from '../images/Image';

const StudyBenefitDescription = ({ title, description }) => {
  return (
    <Grid.Flex flexWrap="wrap" alignItems="center" px={rem(16)}>
      <SubHead bold mb={rem(4)} mt={rem(10)}>
        {title}
      </SubHead>
      <SubHead>{description}</SubHead>
    </Grid.Flex>
  );
};

const studyBenefitItems = [
  {
    imgSrc: studyPhonesHeartHealthyLife,
    imgAlt: 'Heart healthy life screenshot',
    description: (
      <StudyBenefitDescription
        title="Live a heart-healthy life"
        description="Participants learn about heart health and participate in activities that may help improve their sleep, fitness, and overall wellness."
      />
    ),
  },
  {
    imgSrc: studyPhonesEarnRewards,
    imgAlt: 'Earn Rewards screenshot',
    description: (
      <StudyBenefitDescription
        title="Earn rewards"
        description="Participants complete easy and fun activities and earn rewards worth $150 or more, depending on their study group."
      />
    ),
  },
  {
    imgSrc: studyPhonesAdvanceResearch,
    imgAlt: 'Advance research screenshot',
    description: (
      <StudyBenefitDescription
        title="Advance research"
        description="Participants help advance heart health research for themselves and future generations. They’ll help determine whether earlier detection of AFib can help improve heart health and reduce the risk of stroke."
      />
    ),
  },
];

const StudyBenefits = () => {
  return (
    <Grid.Flex
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      {studyBenefitItems.map(studyBenefitItem => (
        <Grid.Box width={[1, 1, 1 / 3, 1 / 4]} key={studyBenefitItem.imgAlt}>
          <Grid.Box textAlign="center">
            <Image
              src={studyBenefitItem.imgSrc}
              alt={studyBenefitItem.imgAlt}
              width={['80%', '70%', '100%', '100%']}
            />
          </Grid.Box>
          <Grid.Box mt={[rem(16), rem(16), rem(16), 0]} mb={rem(30)}>
            {studyBenefitItem.description}
          </Grid.Box>
        </Grid.Box>
      ))}
    </Grid.Flex>
  );
};

export default StudyBenefits;
