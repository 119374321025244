import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';

import { InnerGrid, Raised } from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import Grid from '../components/grid';
import MediaQuery, { Breakpoint } from '../components/breakpoint';
import Image from '../components/images/Image';
import { trackPageViewForCurrentPath } from '../tracking';

import {
  contactUsHorn215,
  contactUsHorn350,
  lightGreenBgColor,
} from '../constants';

const ContactUs = props => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  const textContainer = textAlign => (
    <Grid.Box
      textAlign={textAlign}
      width={[1, 3 / 4, 2 / 5, 3.25 / 10]}
      px={[0, rem(20), 0, 0]}
    >
      {props.children}
    </Grid.Box>
  );

  const imageContainer = isSmallScreen => {
    let imgSrc = contactUsHorn350;
    let maxWidth = '342px';
    if (isSmallScreen === true) {
      imgSrc = contactUsHorn215;
      maxWidth = '212px';
    }

    return (
      <Grid.Box
        textAlign="center"
        width={[1, 1, 1 / 2, 1 / 2]}
        mb={[rem(20), rem(20), 0, 0]}
      >
        <Image
          src={imgSrc}
          alt="Contact Us"
          style={{ width: '100%', maxWidth }}
        />
      </Grid.Box>
    );
  };

  return (
    <Wrapper>
      <Helmet>
        <title>Contact Us Today to Learn More about the Heartline Study</title>
        <meta
          name="description"
          content="For general questions about the study or the Heartline app contact us to learn more."
        />
      </Helmet>
      <React.Fragment>
        <Raised bg={lightGreenBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={[rem(20)]}
            pt={[rem(10), rem(10), rem(60), rem(60)]}
            pb={[rem(10), rem(10), rem(40), rem(40)]}
            mb={[rem(60), 0, 0, 0]}
          >
            <Grid.Flex
              flexWrap="wrap"
              justifyContent="center"
              alignItems="flex-start"
              my={rem(60)}
            >
              <MediaQuery minWidth={Breakpoint.MEDIUM}>
                {textContainer()}
                {imageContainer()}
              </MediaQuery>
              <MediaQuery maxWidth={Breakpoint.MEDIUM}>
                {imageContainer(true)}
                {textContainer('center')}
              </MediaQuery>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default ContactUs;
