import { rem } from 'polished';
import React from 'react';

import { InnerGrid } from '../components/LandingAssets';
import { wrapComponent } from '../utils';
import { PrivacyPolicyStyle } from './PrivacyPolicy';
import { trackPageViewForCurrentPath } from '../tracking';

const TermsOfUse = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <section role="main">
      <PrivacyPolicyStyle />
      <InnerGrid
        mt={[rem(25), rem(50), rem(150)]}
        mb={[rem(25), rem(50), rem(50)]}
        px={rem(10)}
      >
        <h1 style={{ marginBottom: 40 }}>HEARTLINE - TERMS OF USE</h1>

        <p>
          <strong>
            Your access to and use of this website is subject to the following
            terms and conditions ("Terms of Use") and all applicable laws.
          </strong>
        </p>

        <p>
          <strong>Changes to these Terms of Use</strong>
        </p>

        <p>
          By accessing and using this website, you accept these Terms of Use,
          without limitation or qualification. Janssen Scientific Affairs, LLC,
          an affiliate of Johnson & Johnson, ("Janssen") retains the sole and
          exclusive right to amend these Terms of Use from time to time at a
          time and manner of its election. You hereby covenant that your
          continued use of this website constitutes acceptance of such changes
          in these Terms of Use. These Terms of Use were last revised on
          February 25, 2020.
        </p>

        <p>
          <strong>Copyright and Trademark Ownership</strong>
        </p>

        <p>
          Unless otherwise stated, the contents of this website including, but
          not limited to, the text and images contained herein and their
          arrangement are either the property of Janssen or of their respective
          licensors. All trademarks used or referred to in this website are
          either the property of Janssen or of their respective owners.
        </p>

        <p>
          Nothing contained in this website shall be construed as conferring by
          implication, estoppel, or otherwise, any license or right to any
          copyright, patent, trademark or other proprietary interest of Janssen
          or any third party. This website and the content provided on this
          website, including, but not limited to, graphic images, audio, video,
          html code, metadata, buttons and text, may not be copied, reproduced,
          republished, uploaded, posted, transmitted, or distributed in any way,
          without the prior written consent of Janssen or the applicable third
          party, except that you may download, display, and print one copy of
          the materials on any single computer solely for your personal,
          non-commercial use, provided that you do not modify the material in
          any way and you keep intact all copyright, trademark, and other
          proprietary notices.
        </p>

        <p>
          <strong>Your Conduct</strong>
        </p>

        <p>
          You may provide a link to the homepage of this website; however, you
          shall (i) not provide such a link for any derogatory purpose or for
          such other purpose that is against public policy, (ii) refrain from
          deep linking, framing, phishing, introducing any malicious code or
          disabling this website or its attendant infrastructure, (iii) not use
          any bots or spiders or such other algorithms to access the contents of
          this website, and (iv) not access the password protected portion of
          this website, other than through the password provided to you by
          Janssen to access such password protected portion of this website.
          Further, you hereby agree to remove any references and links to this
          website immediately upon receipt of a removal request from Janssen.
        </p>

        <p>
          <strong>No Business or Professional Services Relationship</strong>
        </p>

        <p>
          The information provided on this website is free of charge and for
          informational purposes only and does not create a business or
          professional services relationship or any other type of commercial
          relationship between you and Janssen. Specifically, any information
          provided on this website shall not be deemed to be medical information
          or analysis suitable to your health condition.
        </p>

        <p>
          <strong>Links to Other Websites and Online Services</strong>
        </p>

        <p>
          Links on this website may lead to services or sites not operated by
          Janssen. No judgment or warranty is made with respect to such other
          services or sites and Janssen takes no responsibility for such other
          sites or services. A link to another site or service is not an
          endorsement of that site or service. Any use you make of the
          information provided on this website, or any site or service linked to
          by this website, is at your own risk.
        </p>

        <p>
          <strong>Disclaimer of Warranties</strong>
        </p>

        <p className="uppercase">
          This website and its contents are provided "as is" and Janssen makes
          no representation or warranty of any kind with respect to this website
          or any site or service accessible through this website, including that
          access to, and use of, this website shall be uninterrupted or
          error-free. Janssen expressly disclaims all express and implied
          warranties including, but not limited to, the implied warranties of
          merchantability, fitness for a particular purpose, title, and
          non-infringement.
        </p>

        <p>
          <strong>Limitation of Liability</strong>
        </p>

        <p className="uppercase">
          In no event will Janssen be liable to any party for any direct,
          indirect, incidental, special, exemplary, consequential, or other
          damages (including, but not limited to, lost profits, business
          interruption, loss of programs or data) without regard to the form of
          action and whether in contract, tort, negligence, strict liability, or
          otherwise, arising out of or in connection with this website, any
          content on or accessed through this website or any site or service
          linked to, or any copying, displaying, or use thereof.
        </p>

        <p>
          <strong>Choice of Law</strong>
        </p>

        <p>
          Subject to binding arbitration provided below, these Terms, as well as
          any claim related to or pursuant to these Terms, whether grounded in
          tort, contract, law or equity, shall be governed by, construed, and
          enforced in accordance with the laws of the State of New York, without
          regard to its choice of law principles.
        </p>

        <p>
          <strong>Dispute Resolution And Class Action Waiver</strong>
        </p>

        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AND JANSSEN AGREE TO
          SUBMIT EXCLUSIVELY ANY CLAIM, CONTROVERSY OR DISPUTE ARISING OUT OF OR
          RELATING TO THIS WEBSITE, THE SERVICES, THESE TERMS OR ANY OTHER
          POLICIES OR OTHER TERMS INCORPORATED THEREIN (INCLUDING THE BREACH,
          TERMINATION, ENFORCEMENT, INTERPRETATION, ENFORCEABILITY, VALIDITY, OR
          RIGHTS UNDER ANY OF ANY OF THE FOREGOING) (EACH, A “DISPUTE”) FOR
          RESOLUTION BY CONFIDENTIAL, INDIVIDUAL, BINDING ARBITRATION, EXCEPT
          THAT YOU MAY ASSERT CLAIMS IN SMALL CLAIMS COURT IF YOUR CLAIMS
          QUALIFY.
        </p>

        <p>
          THE PARTIES AGREE THAT THE ARBITRATOR, AND NOT ANY FEDERAL, STATE,
          PROVINCIAL OR LOCAL COURT OR AGENCY, SHALL HAVE EXCLUSIVE AUTHORITY TO
          RESOLVE ANY DISPUTES RELATING TO THE INTERPRETATION, APPLICABILITY,
          ENFORCEABILITY OR FORMATION OF THIS AGREEMENT TO ARBITRATE, INCLUDING
          ANY CLAIM THAT ALL OR ANY PART OF THIS AGREEMENT TO ARBITRATE IS VOID
          OR VOIDABLE. THE ARBITRATOR SHALL ALSO BE RESPONSIBLE FOR DETERMINING
          ALL THRESHOLD ARBITRABILITY ISSUES, INCLUDING ISSUES RELATING TO
          WHETHER THE TERMS ARE UNCONSCIONABLE OR ILLUSORY AND ANY DEFENSE TO
          ARBITRATION, INCLUDING WAIVER, DELAY, LACHES OR ESTOPPEL.
        </p>

        <p className="uppercase">
          Neither JANSSEN nor you will have the right to litigate any claim
          arising out of, or pursuant to, or relating to this Agreement in court
          or have a jury trial on that claim. Further, JANSSEN and you will not
          have the right to participate in a representative capacity or as a
          member of any class pertaining to any claim subject to arbitration.
          Arbitration procedures are generally simpler than the rules that apply
          in court, and discovery is more limited. The arbitrator's decisions
          are as enforceable as any court order and are subject to very limited
          review by a court. Except as set forth below, the arbitrator's
          decision will be final and binding. Other rights JANSSEN or you would
          have in court may also not be available in arbitration. Before
          beginning arbitration, JANSSEN or you must first send a claim notice.
          Claims will be referred to either JAMS or AAA, as selected by the
          party electing arbitration. Claims will be resolved pursuant to this
          Arbitration provision and the selected organization's rules in effect
          when the claim is filed, except where those rules conflict with this
          Agreement. Either JANSSEN or you may delay enforcing or not exercise
          rights under this Arbitration provision, including the right to
          arbitrate a claim, without waiving the right to exercise or enforce
          those rights. There will be no right or authority for any claims to be
          arbitrated on a class action basis or on bases involving claims
          brought in a purported representative capacity on behalf of the
          general public, other members, or other persons similarly situated.
          The arbitrator's authority is limited to claims solely between JANSSEN
          and you. Claims may not be joined or consolidated unless JANSSEN and
          you agree in writing. An arbitration award and any judgment confirming
          it will apply only to the specific case and cannot be used in any
          other case except to enforce the award. Notwithstanding any other
          provision and without waiving the right to appeal such decision, if
          any portion of this section is deemed invalid or unenforceable, then
          this entire section (other than this sentence) will not apply. Subject
          to this section, the arbitrator may otherwise award any relief
          available in court. The arbitration will be confidential, but you may
          notify any government authority of your claim. At any party's request,
          the arbitrator will provide a brief written explanation of the award.
          The arbitrator's award will be final and binding, except for any right
          of appeal provided by the FAA; however, any party will have 30 days to
          appeal the award by notifying the arbitration organization and all
          parties in writing. The originally selected arbitration organization
          will appoint a three (3) arbitrator panel to decide anew, by majority
          vote based on written submissions, any section of the decision
          objected to. Judgment upon any award may be entered in any court
          having jurisdiction. All arbitration hearings will take place in
          Middlesex County, New Jersey. SUBJECT TO JANSSEN PAYING A MAXIMUM OF
          US $2,500 TOWARDS THE INITIAL ARBITRATION FEES (EXCEPTING FILING
          FEES), JANSSEN and you shall equally bear all arbitration fees, and
          you will be responsible for paying your share of any arbitration
          organization fees (including filing, administrative, hearing or other
          fees), but only up to the amount of the filing fees you would have
          incurred if you had brought a claim in court. JANSSEN will be
          responsible for any additional arbitration fees. You may reject this
          Arbitration provision by sending a written rejection notice to JANSSEN
          at the address stated at the end of this Agreement. You must mail your
          rejection notice within 45 days after the date of this Agreement. Your
          rejection notice must state that you reject this DISPUTE Resolution
          AND CLASS ACTION WAIVER section and must also include your name,
          address and personal signature. No one else may sign the rejection
          notice on your behalf. If your rejection notice complies with these
          requirements, this Claim Resolution section will not apply to you,
          except for any claims subject to pending litigation or arbitration at
          the time you send your rejection notice. Rejection of this Arbitration
          provision will not affect your other rights or responsibilities under
          this section or the Agreement. Rejecting this Arbitration provision
          will not affect your ability to use the Product. This section will
          survive termination of this Agreement, any legal proceeding to collect
          a debt, and any bankruptcy.
        </p>

        <p>
          <strong>Privacy</strong>
        </p>

        <p>
          Our collection, use, and disclosure of information that you provide
          us, or that we collect about you, over this website is subject to our
          website privacy policy, which can be found at heartline.com/privacy.
        </p>

        <p>
          <strong>Ideas Submissions not Treated Confidentially</strong>
        </p>

        <p>
          Janssen does not accept unauthorized idea submissions outside of
          established business relationships. To protect its own interests and
          those of its current clients, Janssen must treat the issue of such
          submissions with great care. Importantly, without a clear business
          relationship, Janssen cannot and does not treat any such submissions
          in confidence. Accordingly, please do not communicate any idea
          submissions to Janssen not pre-approved by Janssen in a prior express
          writing through this website. Any ideas disclosed to Janssen outside a
          pre-existing and documented confidential business relationship are not
          confidential and Janssen may therefore develop, use and freely
          disclose or publish similar ideas without compensating you or
          accounting to you. Janssen will make every reasonable effort to return
          or destroy any unauthorized idea submissions without detailed review
          of them. However, if a review is necessary in Janssen's sole
          discretion, it will be with the understanding that Janssen assumes no
          obligation to protect the confidentiality of your idea or compensate
          you for its disclosure or use. By submitting an idea or other detailed
          submission to Janssen through this website, you agree to be bound by
          these Terms of Use.
        </p>

        <p>
          <strong>Forward-Looking Statements</strong>
        </p>

        <p>
          This website contains "forward-looking statements" as defined in the
          Private Securities Litigation Reform Act of 1995. You’re cautioned not
          to rely on these forward-looking statements. These statements, at the
          time they were made, were based on then-current expectations of future
          events.
        </p>

        <p>
          If underlying assumptions prove or have proven to be inaccurate, or if
          known or unknown risks or uncertainties materialize or have
          materialized, actual results could vary or already may have varied
          materially from Janssen's expectations and projections contained or
          reflected in these statements.
        </p>

        <p>
          These risks and uncertainties include, but are not limited to,
          economic and financial market factors, such as interest rate and
          currency exchange rate fluctuations; competition, including
          technological advances, new products and patents attained by
          competitors; challenges inherent in product research and development,
          including uncertainty of clinical success and obtaining regulatory
          approvals; challenges to patents; the impact of patent expirations;
          significant adverse litigation or government action, including related
          to product liability claims; the ability of Janssen to successfully
          execute strategic plans including restructuring plans; the impact of
          business combinations and divestitures and the possibility that the
          expected benefits and opportunities of an acquisition are not realized
          in the anticipated time frame or at all; changes to applicable laws
          and regulations including tax laws and global health care reforms;
          trends toward health care cost containment; changes in behavior and
          spending patterns or financial distress of purchasers of health care
          products and services; financial instability of international
          economies and legal systems and sovereign risk; manufacturing
          difficulties or delays, internally or within the supply chain;
          increased scrutiny of the health care industry by government agencies;
          product efficacy or safety concerns resulting in product recalls or
          regulatory action; and the potential failure to meet obligations in
          compliance agreements with government bodies.
        </p>

        <p>
          A further list and description of these risks, uncertainties and other
          factors can be found in Johnson & Johnson's most recently filed Annual
          Report on Form 10-K and Quarterly Report on Form 10-Q, including in
          the sections captioned "Risk Factors" and "Cautionary Note Regarding
          Forward-Looking Statements," and in its subsequent filings with the
          Securities and Exchange Commission. Copies of these filings are
          available online at 
          <a
            href="https://www.sec.gov"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.sec.gov
          </a>
          , on{' '}
          <a
            href="https://www.jnj.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.jnj.com
          </a>{' '}
          under "Investors—SEC Filings", or on request from Janssen.
        </p>

        <p>
          Janssen assumes no obligation to update any forward-looking statement
          as a result of new information or future events or developments.
        </p>

        <p>
          <strong>Digital Millennium Copyright Act Notice</strong>
        </p>

        <p>
          Complaints regarding any content or information made available on this
          website may be made to Janssen' registered agent for notice under 17
          U.S.C. § 512, as registered with the United States Copyright Office,
          at the following address:
        </p>
      </InnerGrid>
      <InnerGrid>
        <p style={{ textAlign: `center` }}>
          Janssen Medical Information
          <br />
          PO Box 200
          <br />
          Titusville, NJ 08560
        </p>
      </InnerGrid>

      <InnerGrid
        mt={[rem(25), rem(50), rem(75)]}
        mb={[rem(25), rem(50), rem(150)]}
        px={rem(10)}
      >
        <p>
          <strong>Questions</strong>
        </p>

        <p>
          If you have any other questions about these terms of use, please
          contact us at{' '}
          <a
            href="mailto:help@heartline.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            help@heartline.com
          </a>
          .
        </p>
      </InnerGrid>
    </section>
  );
};

export default wrapComponent(TermsOfUse);
