import { rem } from 'polished';
import React from 'react';

import { InnerGrid } from '../components/LandingAssets';
import { wrapComponent } from '../utils';
import { PrivacyPolicyStyle, Tab } from './PrivacyPolicy';
import { trackPageViewForCurrentPath } from '../tracking';

const AppPrivacyPolicy = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <section role="main">
      <PrivacyPolicyStyle />
      <InnerGrid
        mt={[rem(25), rem(50), rem(150)]}
        mb={[rem(25), rem(50), rem(100)]}
        px={rem(10)}
      >
        <h1>Heartline Privacy Policy</h1>

        <p>
          Janssen Scientific Affairs, LLC, an affiliate of Johnson & Johnson
          (Janssen, we, or us), the publisher of the Heartline Application
          (Heartline App) and the sponsor of the Heartline Study (Study), values
          your trust and is committed to the responsible management, use, and
          protection of your information.
        </p>
        <p>
          This Heartline Privacy Policy (Privacy Policy) describes our practices
          in connection with the information (including personal information)
          that we, our agent, or our service providers (Third-Party Study
          Representatives) collect, use, and disclose during the Study through
          the Heartline App, from which you are accessing this Privacy Policy.
          By providing information through the Heartline App or by using the
          Heartline App, you acknowledge that you have read and understand this
          Privacy Policy.
        </p>
        <p>
          This Privacy Policy covers information collected from individuals who
          complete the Informed Consent Form for the Study.
        </p>

        <p>
          <strong>USE BY MINORS</strong>
          <br />
          The Heartline App is not directed to individuals under the age of 18,
          and we request that these individuals not provide any information
          through the Heartline App. If your child has submitted information and
          you would like to request that such information be removed from the
          Study database, please contact us as explained below under Contacting
          Us.
        </p>

        <p>
          <strong>INFORMATION COLLECTION</strong>
          <br />
          The Heartline App may ask you to submit or provide access to
          information, including personal information, in connection with your
          participation in the Study, as described in the Informed Consent Form.
          “Personal information” is data that is associated with an identifiable
          individual, such as contact information (for example, a name, physical
          address or email address) and certain health information (for example,
          personally identifiable healthcare claims information).
        </p>
        <p>
          The Informed Consent Form provides a full listing of information that
          may be collected, used, or disclosed as part of the Study through the
          Heartline App.
        </p>
        <p>
          The information you enter in or that is collected by the Heartline App
          will generally be automatically sent from your iPhone and Apple Watch
          (if applicable) to a Third-Party Study Representative for storage in
          the Study database. The Study Research Collaborator, Apple Inc., will
          have access to raw data collected from certain sensor streams such as
          PPG and ECG. The raw data does not include your personal information.
          Before sending these sensor data to Janssen or Third-Party Study
          Representatives, the Study Research Collaborator will process these
          data into a readable format that will exclude certain hardware and
          algorithm information.
        </p>
        <p>
          You may be asked to submit personal information in order for you to
          benefit from certain features or as part of a particular activity
          (such as surveys). You will be told what information is required and
          what information is optional.
        </p>
        <p>
          We may combine the information you submit with other information we
          have collected from you as part of the Study, including, for example,
          through your iPhone and Apple Watch, if applicable. We may also
          combine it with information we receive about you from other sources as
          part of the Study, such as your Medicare healthcare claims data.
        </p>

        <p>
          <strong>HOW WE USE AND DISCLOSE INFORMATION</strong>
          <br />
          The information you provide or permit access to through the Heartline
          App may be used for the purposes described in the Informed Consent
          Form.
        </p>
        <p>
          Such information may also be used to provide the functionality of the
          Heartline App, including:
        </p>
        <Tab margin={40} style={{ marginBottom: rem(70) }}>
          <ul>
            <li>to provide you with related technical support;</li>
            <li>
              to respond to your inquiries and collect your responses to surveys
              and other activities; and
            </li>
            <li>
              to send you important information regarding our relationship with
              you or regarding the Study, changes to our terms, conditions, and
              policies and/or other administrative information.
            </li>
          </ul>
        </Tab>
        <p>
          In addition, such information may be used for the following business
          purposes:
        </p>
        <Tab margin={40} style={{ marginBottom: rem(70) }}>
          <ul>
            <li>for improving the efficiency of the Heartline App;</li>
            <li>
              for identifying Heartline usage trends, for example, understanding
              which parts of the Heartline App are of most interest to users;
            </li>
            <li>
              for compliance with applicable law or as permitted by applicable
              law to respond to requests from public and government authorities;
            </li>
            <li>for enforcement our terms and conditions; and</li>
            <li>
              for protection of our rights, privacy, safety, or property, and/or
              that of our affiliates, you, or others.
            </li>
          </ul>
        </Tab>
        <p>
          In addition, we may use and disclose information that is not in
          personally identifiable form for any purpose permitted by law and in
          support of the Study or as otherwise described in the Informed Consent
          Form.
        </p>
        <p>
          Third-Party Study Representatives will have access to your information
          to support the Study activities, including supporting the Heartline
          App, managing Heartline Support, and storing the Study Data on behalf
          of Janssen and the Study Research Collaborator. These Third-Party
          Study Representatives will have access to your personal information,
          including your directly identifying information (such as your name and
          telephone number), so they can communicate with you during the Study
          if needed.
        </p>
        <p>
          We, along with the Study Research Collaborator, will only receive
          access to “coded information,” which is information from which
          directly identifying information have been replaced with a code that
          is random and unique to you, but which is not based on any information
          about you.
        </p>
        <p>
          We may also disclose your coded information that is collected or
          accessed through the Heartline App to the following individuals and
          entities to support the Study and/or to verify such information,
          limited to only the minimum amount of information needed and only
          permitted to be used for the purposes described above:
        </p>
        <Tab margin={40} style={{ marginBottom: rem(70) }}>
          <ul>
            <li>
              to other members of the Johnson & Johnson group of companies which
              are legal affiliates or subsidiaries, and subject to internal
              Johnson & Johnson review processes and approvals, for the purposes
              described in this Privacy Policy. A list of our affiliates is
              available at{''}
              <a
                href="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings
              </a>{' '}
              (click on the link for Form 10K, Exhibit 21, under “SEC Filings”).
              Janssen Scientific Affairs, LLC is the party responsible for the
              management of the jointly used personal information;
            </li>
            <li>
              to our Third-Party Study Representatives, such as contractors and
              consultants working for or on behalf of us or the Study Research
              Collaborator; and
            </li>
            <li>
              Members of the Institutional Review Board overseeing this Study
              (as applicable).
            </li>
          </ul>
        </Tab>
        <p>
          Except in connection with the sale, transfer, merger, consolidation or
          other transaction involving all or any part of our company, we will
          not sell, license, or otherwise transfer any rights to your personal
          information to any third party, except as specifically described
          above.
        </p>
        <p>
          If we combine information that is not in personally identifiable form
          with information that is identifiable (such as combining your name
          with your geographical location), we will treat the combined
          information as personal information as long as it is combined.
        </p>

        <p>
          <strong>CHOICES AND ACCESS</strong>
          <br />
          <strong>
            Your choices regarding our use and disclosure of your personal
            information
          </strong>
          <br />
          If you prefer that your personal information no longer be collected,
          you can withdraw from the Study within the Heartline App. You must go
          to the ‘Withdraw from Program’ button in the Profile/Settings tab of
          the Heartline App to confirm your decision to withdraw from the Study.
          Neither deleting the Heartline App nor ceasing to complete the Study
          activities will withdraw you from the Study. For additional
          information, refer to the Informed Consent Form section “Can I Change
          My Mind?”
        </p>
        <p>
          If you withdraw or are withdrawn from the Study, we will not collect
          or receive any new information from your iPhone, Apple Watch (if
          applicable), or your healthcare claims data from Medicare within 24
          hours of withdrawal. However, information already collected,
          processed, and stored up to the time your withdrawal request is
          received will not be deleted, and may continue to be used for Study
          purposes as described in the Informed Consent Form.
        </p>
        <p>
          If you withdraw from the Study, you will not be able to continue
          participating in the Study and cannot re-enter the Study at a later
          time.
        </p>
        <p>
          If you have any questions or concerns about your personal information
          or Study participation, please contact Heartline Support listed in the
          "Contacting Us" section below.
        </p>

        <p>
          <strong>SECURITY</strong>
          <br />
          We seek to use reasonable organizational, technical, and
          administrative measures designed to protect personal information under
          our control. Unfortunately, no data transmission over the Internet or
          data storage system can be guaranteed to be 100% secure. If you have
          reason to believe that your interaction with us is no longer secure
          (for example, if you feel that the security of any account you have
          with us has been compromised), please immediately notify us in
          accordance with the “Contacting Us” section below.
        </p>

        <p>
          <strong>RETENTION PERIOD</strong>
          <br />
          Your information will be retained by our Third-Party Study
          Representative for up to 50 years after completion of the Study. For
          additional information review the Informed Consent Form section “How
          long will Study Data be stored?”
        </p>

        <p>
          <strong>THIRD PARTY SITES AND SERVICES</strong>
          <br />
          This Privacy Policy does not address, and we are not responsible for,
          the privacy, information, or other practices of any third parties,
          including any third party operating any site or online service
          (including, without limitation, any application) that is available
          through the Heartline App or to which the Heartline App contains a
          link. The availability of, or inclusion of a link to, any such site or
          property on the Heartline App does not imply endorsement of it by us
          or by our affiliates.
        </p>

        <p>
          <strong>CONTACTING US</strong>
          <br />
          If you have any questions about this Privacy Policy, please contact
          Heartline Support at 877-594-4021 or via email at{' '}
          <a href="mailto:help@heartline.com">help@heartline.com</a>, or by
          going to the Profile/Settings tab in the Heartline App under ‘Contact
          Support’.
        </p>

        <p>
          <strong>UPDATES TO THIS PRIVACY POLICY</strong>
          <br />
          We may change this Privacy Policy. Any changes to this Privacy Policy
          will become effective when we post the revised Privacy Policy on the
          Heartline App. Your use of the Heartline App following these changes
          means that you accept the revised Privacy Policy. We recommend that
          you regularly review the Privacy Policy when you visit the Heartline
          App. This Privacy Policy was last updated on June 29, 2020.
        </p>
      </InnerGrid>
    </section>
  );
};

export default wrapComponent(AppPrivacyPolicy);
