import React from 'react';
import { rem } from 'polished';
import ReactPixel from 'react-facebook-pixel';
import { useMediaQuery } from 'react-responsive';

import Grid from '../grid';
import {
  InnerGrid,
  LandingSectionHeader,
  SubHead,
  BlueLink,
} from '../LandingAssets';
import AppStoreRating from '../app_store_rating/AppStoreRating';
import AppStoreReviews from '../app_store_reviews/AppStoreReviews';
import MediaQuery, { Breakpoint, isSmallScreenQuery } from '../breakpoint';
import { trackGoogleAnalyticsEvent } from '../../tracking';
import {
  downloadAppFromAppStoreTarget,
  downloadAppFromAppStoreUrl,
  sans,
  facebookPixelDownloadAppEventName,
} from '../../constants';

const ReviewsAndRatings = () => {
  const isSmallScreen = useMediaQuery(isSmallScreenQuery);
  return (
    <React.Fragment>
      <InnerGrid
        multiplier={11}
        px={[rem(20)]}
        py={[rem(75), rem(75), rem(75), rem(90)]}
      >
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid.Box width={[1, 1, 3 / 4, 3 / 5]}>
            <LandingSectionHeader style={{ marginBottom: rem(20) }}>
              See what others are saying
            </LandingSectionHeader>
            <MediaQuery maxWidth={Breakpoint.MEDIUM}>
              <AppStoreRating />
            </MediaQuery>
          </Grid.Box>
          <MediaQuery minWidth={Breakpoint.MEDIUM}>
            <Grid.Box width={[1, 1, 1 / 4, 1 / 5]}>
              <AppStoreRating />
            </Grid.Box>
          </MediaQuery>
        </Grid.Flex>
        <Grid.Flex flexWrap="wrap" alignItems="center" justifyContent="center">
          <Grid.Box width={1} mt={rem(24)}>
            <AppStoreReviews />
            <Grid.Flex
              alignItems="center"
              justifyContent={isSmallScreen ? 'flex-start' : 'center'}
              mt={rem(32)}
              mb={rem(24)}
            >
              <BlueLink
                href={downloadAppFromAppStoreUrl()}
                target={downloadAppFromAppStoreTarget}
                rel="noopener noreferrer"
                onClick={() => {
                  trackGoogleAnalyticsEvent({
                    category: 'app_reviews',
                    action: 'click',
                    label: 'location:see_what_others_are_saying',
                  });

                  ReactPixel.track(facebookPixelDownloadAppEventName);
                }}
              >
                See all the reviews on the App Store ›
              </BlueLink>
            </Grid.Flex>
            <SubHead>
              “Heartline™ is a study that has the potential to fundamentally
              change our understanding of how digital health tools, like the ECG
              app and irregular rhythm notification feature on Apple Watch,
              could lead to earlier detection of AFib, helping patients
              understand and directly engage in their heart health, prompting
              potentially life-saving conversations with their doctors, and
              improving health outcomes.”
            </SubHead>
            <SubHead fontFamily={sans} fontWeight={500}>
              Dr. C. Michael Gibson, Co-Chair of the Heartline™ Executive
              Committee and Professor of Medicine, Harvard Medical School and
              CEO, Baim Institute.
            </SubHead>
          </Grid.Box>
        </Grid.Flex>
      </InnerGrid>
    </React.Fragment>
  );
};

export default ReviewsAndRatings;
