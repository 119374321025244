import React from 'react';

import { SubHead, LargeHeading } from '../LandingAssets';

const ContactUsByEmail = ({ path }) => {
  // Use different email address depending on the path.
  const emailDomain = '@heartline.com';
  let localPart = 'hcp';
  if (path && path.includes('/press')) {
    localPart = 'press';
  }
  const emailAddress = `${localPart}${emailDomain}`;

  return (
    <React.Fragment>
      <LargeHeading>Contact Us</LargeHeading>
      <SubHead>
        Still have questions? Contact Heartline Support. We’re here to help!
      </SubHead>
      <SubHead>
        <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
      </SubHead>
    </React.Fragment>
  );
};

export default ContactUsByEmail;
