import React from 'react';
import { rem } from 'polished';

import Grid from '../grid';
import { privacyPhotoDesktop } from '../../constants';
import { LandingSectionHeader, SubHead } from '../LandingAssets';
import MediaQuery, { Breakpoint } from '../breakpoint';
import Image from '../images/Image';

const PrivacyAndSecurity = () => {
  const textContainer = (
    <Grid.Box
      width={[1, 1, 1 / 2, 1 / 2]}
      pl={[rem(20), rem(20), rem(20), rem(50)]}
      pr={[rem(20), rem(20), rem(50), rem(60)]}
    >
      <LandingSectionHeader mb={rem(20)} mt={[rem(40), rem(40), rem(40), 0]}>
        Privacy and security are built in
      </LandingSectionHeader>
      <SubHead mb={[rem(40), rem(40), rem(40), 0]}>
        The Heartline app was built with a deep commitment to your security and privacy. 
        All data is stored in a highly secure environment using industry-leading practices. 
        And before any participant data is shared with researchers for analysis, information 
        that could directly identify them — such as names and locations — is removed so that 
        their privacy is protected.
      </SubHead>
    </Grid.Box>
  );

  const imageContainer = (
    <Grid.Box width={[1, 1, 1 / 2, 1 / 2]} textAlign="center">
      <Image
        src={privacyPhotoDesktop}
        alt="Enroll from your phone"
        style={{ width: '100%', display: 'block' }}
      />
    </Grid.Box>
  );

  return (
    <React.Fragment>
      <Grid.Flex flexWrap="wrap" alignItems="center" justifyContent="center">
        <MediaQuery minWidth={Breakpoint.MEDIUM}>
          {imageContainer}
          {textContainer}
        </MediaQuery>
        <MediaQuery maxWidth={Breakpoint.MEDIUM}>
          {textContainer}
          {imageContainer}
        </MediaQuery>
      </Grid.Flex>
    </React.Fragment>
  );
};

export default PrivacyAndSecurity;
