import React, { Component } from 'react';
import Wrapper from './components/Wrapper';

import {
  googleAnalyticsExternalRedirectTrackerName,
  heartlineAppStoreUrl,
  heartlineStagingHostname,
  UTM_HEARTLINE_APP_ROUTE,
  UTM_HEARTLINE_APP_URL_PARAMS_STR,
} from './constants';
import {
  trackPageViewForCurrentPath,
  trackGoogleAnalyticsEvent,
} from './tracking';

// When using stage.heartline.com, you can add a query string to your URL to use a different backend.
// e.g. stage.heartline.com?backend=dev
// You can also do this when working locally if you change the function a little bit.
export const getBackendHost = backendQueryString => {
  const localDevHost = 'http://localhost:3000/2';
  if (process.env.NODE_ENV === 'development') {
    return localDevHost;
  }

  const pascalDevHost = 'https://pascal-dev.evidation.com/2';
  const pascalQAHost = 'https://pascal-qa.evidation.com/2';
  const pascalStageHost = 'https://backend-stage.heartline.com/2';
  const pascalProdHost = 'https://backend.heartline.com/2';

  if (window.location.hostname === heartlineStagingHostname) {
    switch (backendQueryString) {
      case 'dev':
        return pascalDevHost;
      case 'qa':
        return pascalQAHost;
      default:
        return pascalStageHost;
    }
  }

  return pascalProdHost;
};

export const getHostname = () => {
  if (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname === 'stage.heartline.com'
  ) {
    return 'https://stage.heartline.com/';
  }
  return 'https://www.heartline.com/';
};

export const wrapComponent = WrappedComponent => {
  return class extends Component {
    componentDidMount() {
      trackPageViewForCurrentPath();
    }

    render() {
      return (
        <Wrapper>
          <WrappedComponent {...this.props} />
        </Wrapper>
      );
    }
  };
};

export const handleExternalRedirects = () => {
  if (window.location.pathname === UTM_HEARTLINE_APP_ROUTE) {
    trackGoogleAnalyticsEvent({
      category: 'app_store',
      action: 'redirect',
      label: 'external_email',
      nonInteraction: true,
      trackerName: googleAnalyticsExternalRedirectTrackerName,
    });

    window.location.replace(
      `${heartlineAppStoreUrl}?${UTM_HEARTLINE_APP_URL_PARAMS_STR}`,
    );
    return true;
  }

  return false;
};
