import React from 'react';
import { rem } from 'polished';

import { InnerGrid, LandingSectionHeader, SubHead } from '../LandingAssets';
import Grid from '../grid';
import StudyBenefits from './StudyBenefits';

const HeartlineStudyExplanation = () => {
  return (
    <React.Fragment>
      <InnerGrid
        multiplier={11}
        px={[rem(20)]}
        py={[rem(75), rem(75), rem(75), rem(90)]}
      >
        <LandingSectionHeader>
          The Heartline Study:
          <br />
          Fun, convenient, and educational.
        </LandingSectionHeader>
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="left"
          mt={[rem(-16), rem(-20), rem(-20), rem(-20)]}
          mb={[rem(20), rem(40), rem(40), rem(20)]}
        >
          <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
            <SubHead>
              Participants gain valuable insights into heart health as 
              they contribute to important research, all from the comfort of 
              home in just 5-30 minutes each week.
            </SubHead>
          </Grid.Box>
        </Grid.Flex>
        <StudyBenefits />
      </InnerGrid>
    </React.Fragment>
  );
};

export default HeartlineStudyExplanation;
