import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';
import queryString from 'query-string';
import { isIOS, isChrome } from 'react-device-detect';

export const googleAnalyticsExternalRedirectTrackerName = 'ExternalRedirect';
export const facebookPixelDownloadAppEventName = 'Lead';

export const heartlineStagingHostname = 'stage.heartline.com';
export const heartlineAppStoreUrl = 'https://apps.apple.com/app/id1496449684';

export const HOME_ROUTE = `/`;
export const ABOUT_ROUTE = '/about';
export const TEAM_ROUTE = '/team';
export const PROVIDERS_ROUTE = '/providers';
export const FAQ_ROUTE = '/faq';
export const CONTACT_ROUTE = '/contact';
export const PRIVACY_ROUTE = '/privacy';
export const APP_PRIVACY_ROUTE = '/app-privacy';
export const TERMS_ROUTE = '/terms';
export const UNSUBSCRIBE_ROUTE = '/unsubscribe';
export const WAITLIST_WITHDRAWAL_ROUTE = '/waitlist_withdraw';
export const UNLOCK_ACCOUNT_ROUTE = '/unlock_account';
export const REPORT_LOST_WATCH_ROUTE = '/report_lost_watch';
export const SHIPPING_INSTRUCTIONS_ROUTE = '/print_shipping_label';

// Contact Us Route and Subroutes
export const CONTACT_US_ROUTE = '/contact-us';
export const TELL_US_MORE_SUBROUTE = '/form';
export const HCP_SUBROUTE = '/providers';
export const PRESS_SUBROUTE = '/press';

// UTM vanity url paths. These must be ALL LOWER CASE.
export const UTM_LEARN_MORE_ROUTE = '/learnmore';
export const UTM_VISIT_ROUTE = '/visit';
export const UTM_INFO_ROUTE = '/info';
export const UTM_THE_TALK_ROUTE = '/thetalk';
export const UTM_FACEBOOK_ROUTE = '/facebook';
export const UTM_TWITTER_ROUTE = '/twitter';
export const UTM_PINTEREST_ROUTE = '/pinterest';
export const UTM_LINKEDIN_ROUTE = '/linkedin';
export const UTM_EMAIL_ROUTE = '/email';
export const UTM_LIVE_HEART_HEALTHY_ROUTE = '/livehearthealthy';
export const UTM_ADVANCE_HEART_HEALTH_ROUTE = '/advancehearthealth';
export const UTM_YMCA_ROUTE = '/ymca';
export const UTM_LCS_ROUTE = '/lcs';
export const UTM_DEVOTED_ROUTE = '/devoted';
export const UTM_MI_DOA_ROUTE = '/midoa';
export const UTM_APPLE_WEB_ROUTE = '/appleweb';
export const UTM_US_ROWING_ROUTE = '/usrowing';
export const UTM_SILVER_SNEAKERS_ROUTE = '/silversneakers';
export const UTM_ABC_ROUTE = '/abc';
export const UTM_HEART_STUDY_ROUTE = '/heartstudy';
export const UTM_NIFS_ROUTE = '/nifs';
export const UTM_AAN_ROUTE = '/aan';
export const UTM_FRIEND_ROUTE = '/friend';
export const UTM_HEARTBEAT_HCP_ROUTE = '/heartbeat';
export const UTM_JNJ_ROUTE = '/jnj';
export const UTM_CVS_ROUTE = '/cvs';
export const UTM_LEARN_ROUTE = '/learn';

// External Redirects
export const UTM_HEARTLINE_APP_ROUTE = '/app';

export const UTM_LEARN_MORE_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'learnmore',
  utm_medium: 'redirect',
  utm_campaign: 'print_AARP',
});
export const UTM_VISIT_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'visit',
  utm_medium: 'redirect',
  utm_campaign: 'radio',
});
export const UTM_INFO_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'info',
  utm_medium: 'redirect',
  utm_campaign: 'tv',
});
export const UTM_THE_TALK_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'thetalk',
  utm_medium: 'redirect',
  utm_campaign: 'tv',
});
export const UTM_FACEBOOK_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'website_share',
  utm_medium: 'facebook',
  utm_campaign: 'website_share',
});
export const UTM_TWITTER_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'website_share',
  utm_medium: 'twitter',
  utm_campaign: 'website_share',
});
export const UTM_PINTEREST_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'website_share',
  utm_medium: 'pinterest',
  utm_campaign: 'website_share',
});
export const UTM_LINKEDIN_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'website_share',
  utm_medium: 'linkedin',
  utm_campaign: 'website_share',
});
export const UTM_EMAIL_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'website_share',
  utm_medium: 'email',
  utm_campaign: 'website_share',
});
export const UTM_YMCA_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'ymca',
  utm_medium: 'redirect',
  utm_campaign: 'ymca',
});
export const UTM_LCS_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'lcs',
  utm_medium: 'redirect',
  utm_campaign: 'lcs',
});
export const UTM_DEVOTED_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'devoted',
  utm_medium: 'email',
  utm_campaign: 'devoted',
});
export const UTM_MI_DOA_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'mi_doa',
  utm_medium: 'redirect',
  utm_campaign: 'mi_doa',
});
export const UTM_APPLE_WEB_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'apple_web',
  utm_medium: 'redirect',
  utm_campaign: 'apple_web',
});
export const UTM_US_ROWING_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'usrowing',
  utm_medium: 'redirect',
  utm_campaign: 'usrowing',
});
export const UTM_SILVER_SNEAKERS_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'silversneakers',
  utm_medium: 'redirect',
  utm_campaign: 'silversneakers',
});
export const UTM_ABC_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'abc',
  utm_medium: 'redirect',
  utm_campaign: 'webinar',
});
export const UTM_HEART_STUDY_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'biopharm',
  utm_medium: 'redirect',
  utm_campaign: 'heartstudy',
});
export const UTM_NIFS_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'nifs',
  utm_medium: 'various',
  utm_campaign: 'outreach',
});
export const UTM_AAN_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'aan',
  utm_medium: 'other',
  utm_campaign: 'outreach',
});
export const UTM_FRIEND_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'referral',
  utm_medium: 'email',
  utm_campaign: 'invite-a-friend',
});
export const UTM_HEARTBEAT_HCP_PARAMS_STR = queryString.stringify({
  utm_source: 'heartbeat',
  utm_medium: 'redirect',
  utm_campaign: 'hcp',
});
export const UTM_JNJ_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'jnj',
  utm_medium: 'redirect',
  utm_campaign: 'erg_referral',
  utm_content: 'sep_2021',
});
export const UTM_CVS_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'CVS',
  utm_medium: 'directmail',
  utm_campaign: 'DM',
});
export const UTM_LEARN_URL_PARAMS_STR = queryString.stringify({
  utm_source: 'Walgreens',
  utm_medium: 'directmail',
  utm_campaign: '44734',
});
export const UTM_HEARTLINE_APP_URL_PARAMS_STR = queryString.stringify({
  pt: '120985198',
  ct: 'referralemailinvite-a-friend',
  mt: '8',
});

export const serif = `'IBM Plex Serif', serif`;
const sansBuiltIns = 'Arial, Helvetica, sans-serif';
export const sans = `-apple-system, BlinkMacSystemFont, ${sansBuiltIns}`;

export const grayBackground = 'rgb(239, 239, 244)';
export const yellowBgColor = 'rgb(254, 248, 236)';
export const whiteBgColor = 'white';
export const lightGreenBgColor = 'rgb(238, 249, 247)';
export const lightGrayBgColor = '#EFEFF4';
export const beigeBgColor = '#FEF4ED';
export const inputTextColor = 'rgb(88, 88, 88)';
export const redTextColor = 'red';
export const inputPlaceholderColor = 'rgb(155, 155, 155)';
export const darkGrayTextColor = 'rgb(88, 88, 88)';
export const lessDarkGrayTextColor = '#6C6C6C';
export const almostBlackTextColor = 'rgb(62, 65, 86)';
export const blackTextColor = '#000000';
export const slateColor = '#3E4156';
export const slateLight30Color = '#787A89';
export const disabledGrayBackgroundColor = 'hsl(0,0%,95%)';
export const disabledGrayBorderColor = 'hsl(0,0%,90%)';
export const lightBlueTextColor = '#5994C6';
export const linkBlueTextColor = '#0D6EC9';

export const inputFontSize = rem(19);
export const inputLineHeight = rem(24);
export const inputLetterSpacing = rem(-0.49);
export const inputMarginBottom = '0.5rem';

// FAQ Header Scroll Names
export const potentialParticipantsHeaderScrollName = 'potentialParticipants';
export const aboutHeaderScrollName = 'about';
export const dataPrivacyHeaderScrollName = 'dataPrivacy';
export const aboutAppleWatchHeaderScrollName = 'aboutAppleWatch';
export const forHCProvidersHeaderScrollName = 'forHCProviders';
// FAQ Question Scroll Names
export const paidToParticipateScrollName = 'paidToParticipate';
export const alreadyOwnAppleWatchScrollName = 'alreadyOwnAppleWatch';
export const whatIPhoneModelScrollName = 'whatIPhoneModel';

export const HiddenPagesCss = createGlobalStyle`
  section[role=main] {
    text-align: center;

    svg {
      margin: 0 auto;
    }

    .status-header {
      margin: 2.5rem 0 1rem;
    }

    .subheader, .subheader ul li {
      color: rgb(88, 88, 88);
      font-family: Helvetica;
    }

    .subheader ul {
      display:inline-block
      padding: 0;
    }

    .subheader ul li {
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
`;

export const urlForiPhone6siOS12_2 = 'https://support.apple.com/en-us/HT201685';
export const urlUnderstandingMedicare =
  'https://www.aarp.org/health/medicare-qa-tool/understanding-medicare';
export const urlWhatIsOriginalMedicare =
  'https://www.aarp.org/health/medicare-qa-tool/what-is-original-medicare';
export const mHealthStudyUrl =
  'https://jamanetwork.com/journals/jama/fullarticle/2687353';
export const appleHeartStudyUrl =
  'https://www.nejm.org/doi/full/10.1056/NEJMoa1901183';
export const afibScreeningStudyUrl =
  'https://www.ahajournals.org/doi/10.1161/CIRCULATIONAHA.116.026693';
export const learnMoreAppleWatchUrl =
  'https://www.apple.com/apple-watch-series-5/';
export const learnMoreAppleWatchHealthUrl =
  'https://www.apple.com/healthcare/apple-watch/';
export const cdcAtrialFibrillationUrl =
  'https://www.cdc.gov/heartdisease/atrial_fibrillation.htm';
export const urlMedicareGov = 'https://www.medicare.gov';
export const removePersonalInformationAppleWatchUrl =
  'https://support.apple.com/en-us/HT204568';

export const utmSourceKey = 'utm_source';
export const utmMediumKey = 'utm_medium';
export const utmCampaignKey = 'utm_campaign';
export const utmContentKey = 'utm_content';
export const downloadAppFromAppStoreUrl = () => {
  // Checking the query string to pick up the utm_source on initial page load
  // after a redirect.
  const queryStringValues = queryString.parse(window.location.search);
  const utmSourceQueryString = queryStringValues[utmSourceKey];
  const utmMediumQueryString = queryStringValues[utmMediumKey];
  const utmCampaignQueryString = queryStringValues[utmCampaignKey];
  const utmContentQueryString = queryStringValues[utmContentKey];

  const ctValue = [
    utmSourceQueryString || window.sessionStorage.getItem(utmSourceKey),
    utmMediumQueryString || window.sessionStorage.getItem(utmMediumKey),
    utmCampaignQueryString || window.sessionStorage.getItem(utmCampaignKey),
    utmContentQueryString || window.sessionStorage.getItem(utmContentKey),
  ]
    .join('')
    .substring(0, 40);

  const paramsForAppStoreStr = queryString.stringify({
    pt: '120985198',
    ct: ctValue,
    mt: '8',
  });

  return `${heartlineAppStoreUrl}?${paramsForAppStoreStr}`;
};
export const downloadAppFromAppStoreTarget = isIOS && isChrome ? '' : '_blank';

const getAssetHost = () => {
  let subdomain = 'prod';
  if (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname === heartlineStagingHostname
  ) {
    subdomain = 'stage';
  }
  return `https://assets.${subdomain}.heartline.com/`;
};
const assetUrlHost = getAssetHost();

export const hcpStudyMaterialsPDFFilename = 'HCP_Heartline_Study_Materials.pdf';
export const participantToolkitPDFFilename =
  'Heartline_Participant_Toolkit.pdf';
export const heartlineOverviewVideoIdentifier =
  'Heartline_Overview_Video_Youtube_https://www.youtube.com/watch?v=NgCWC1P_4pU';

export const hcpStudyMaterialsPDF = `${assetUrlHost}${hcpStudyMaterialsPDFFilename}`;

export const AboutHeroImage1024 = `${assetUrlHost}@1x/about-hero-1024w.png`;
export const AboutHeroImage320 = `${assetUrlHost}@1x/about-hero-320w.png`;
export const badgeEnrollmentClosed108 = `${assetUrlHost}@1x/badge-enrollment-closed-108w.png`;
export const badgeEnrollmentClosed150 = `${assetUrlHost}@1x/badge-enrollment-closed-150w.png`;
export const badgeEnrollmentClosed204 = `${assetUrlHost}@1x/badge-enrollment-closed-204w.png`;
export const contactUsHorn215 = `${assetUrlHost}@1x/contact-215w.png`;
export const contactUsHorn350 = `${assetUrlHost}@1x/contact-350w.png`;
export const downloadOnAppStore108 = `${assetUrlHost}@1x/button-downloadApp-108w.png`;
export const downloadOnAppStore150 = `${assetUrlHost}@1x/button-downloadApp-150w.png`;
export const downloadOnAppStore204 = `${assetUrlHost}@1x/button-downloadApp-204w.png`;
export const earnRewards = `${assetUrlHost}@1x/earn-rewards-250w.png`;
export const eligibilityPhone = `${assetUrlHost}@1x/03-Eligible-IconPhones-200x200-Desktop@1x.png`;
export const enrollInStudyIcon = `${assetUrlHost}@1x/enroll-in-study-50w.png`;
export const enrollOnYourPhone = `${assetUrlHost}@1x/04-Enroll-Phones-01-270x530@1x.png`;
export const forProvidersHeroImage = `${assetUrlHost}@1x/phone-in-hand-1440px.jpg`;
export const getReadyIcon = `${assetUrlHost}@1x/get-ready-50w.png`;
export const getTheAppIcon = `${assetUrlHost}@1x/get-the-app-50w.png`;
export const getTheAppIcon140 = `${assetUrlHost}@1x/app-icon-140w.png`;
export const heartHealthyLife = `${assetUrlHost}@1x/heart-healthy-life-250w.png`;
export const heartHealthResearch = `${assetUrlHost}@1x/heart-health-research-250w.png`;
export const heartlineLogo = `${assetUrlHost}@1x/heartline-logo.jpg`;
export const jnjLogo = `${assetUrlHost}@1x/j-j-logosignature-rgb-red.png`;
export const linkExpired = `${assetUrlHost}@1x/link_expired.png`;
export const medicareImage = `${assetUrlHost}@1x/original-medicare-200w.png`;
export const participateIcon = `${assetUrlHost}@1x/participate-50w.png`;
export const privacyPhotoDesktop = `${assetUrlHost}@1x/06-Privacy-Photo-740x500@1x.png`;
export const privacyPhotoMobile = `${assetUrlHost}@1x/06-Privacy-Photo-622x420@1x.png`;
export const reminder200 = `${assetUrlHost}@1x/reminder-200w.png`;
export const reviews4_5StarsSmall = `${assetUrlHost}@1x/05-Reviews-4_5Stars-Small-96x20-Both@1x.png`;
export const reviews5StarsSmall = `${assetUrlHost}@1x/05-Reviews-5Stars-Small-96x20-Both@1x.png`;
export const stayInformedIcon = `${assetUrlHost}@1x/stay-informed-50w.png`;
export const studyPhonesHeartHealthyLife = `${assetUrlHost}@1x/02-Study-Phones-01-270x530@1x.png`;
export const studyPhonesEarnRewards = `${assetUrlHost}@1x/02-Study-Phones-02-270x530@1x.png`;
export const studyPhonesAdvanceResearch = `${assetUrlHost}@1x/02-Study-Phones-03-270x530@1x.png`;
export const supportTeamNo = `${assetUrlHost}@1x/support_team_no.png`;
export const supportTeamYes = `${assetUrlHost}@1x/support_team_yes.png`;
export const technology250 = `${assetUrlHost}@1x/technology-250w.png`;
export const technology280 = `${assetUrlHost}@1x/technology-280w.png`;
export const usResidents = `${assetUrlHost}@1x/US-residents-200w.png`;

// Executive Committee Members
export const ECMichaelGibson = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-gibson-200w.png`;
export const ECJohnSpertus = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-spertus-200w.png`;
export const ECJaredBunch = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-bunch-200w.png`;
export const ECAnneCurtis = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-curtis-200w.png`;
export const ECSchuylerJones = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-jones-200w.png`;
export const ECDJLakkireddy = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-lakkireddy-200w.png`;
export const ECRodPassman = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-passman-200w.png`;
export const ECEricPeterson = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-peterson-200w.png`;
export const ECJeremyRuskin = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-ruskin-200w.png`;
export const ECStevenSteinhubl = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-steinhubl-200w.png`;
export const ECKhaldounTarakji = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-tarakji-200w.png`;
export const ECMichaelTarino = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-tarino-200w.png`;
export const ECMintuTurakhia = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-turakhia-200w.png`;
export const ECNassirMarrouche = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-marrouche-200w.png`;
export const ECLeslieSaxon = `${assetUrlHost}@1x/Executive Committee Photos/circle crop/EC-saxon-200w.png`;

// Social Sharing Buttons
export const facebookImage = `${assetUrlHost}@1x/Heartline_Icons_facebook.png`;
export const twitterImage = `${assetUrlHost}@1x/Heartline_Icons_twitter.png`;
export const pinterestImage = `${assetUrlHost}@1x/Heartline_Icons_pinterest.png`;
export const linkedinImage = `${assetUrlHost}@1x/Heartline_Icons_linkedin.png`;
export const emailImage = `${assetUrlHost}@1x/Heartline_Icons_Mail.png`;
