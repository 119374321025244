import React from 'react';
import { Redirect } from '@reach/router';

import {
  HOME_ROUTE,
  UTM_LEARN_MORE_ROUTE,
  UTM_LEARN_MORE_URL_PARAMS_STR,
  UTM_VISIT_ROUTE,
  UTM_VISIT_URL_PARAMS_STR,
  UTM_INFO_ROUTE,
  UTM_INFO_URL_PARAMS_STR,
  UTM_THE_TALK_ROUTE,
  UTM_THE_TALK_URL_PARAMS_STR,
  UTM_FACEBOOK_ROUTE,
  UTM_FACEBOOK_URL_PARAMS_STR,
  UTM_TWITTER_ROUTE,
  UTM_TWITTER_URL_PARAMS_STR,
  UTM_PINTEREST_ROUTE,
  UTM_PINTEREST_URL_PARAMS_STR,
  UTM_LINKEDIN_ROUTE,
  UTM_LINKEDIN_URL_PARAMS_STR,
  UTM_EMAIL_ROUTE,
  UTM_EMAIL_URL_PARAMS_STR,
  UTM_LIVE_HEART_HEALTHY_ROUTE,
  UTM_ADVANCE_HEART_HEALTH_ROUTE,
  UTM_YMCA_ROUTE,
  UTM_YMCA_URL_PARAMS_STR,
  UTM_LCS_ROUTE,
  UTM_LCS_URL_PARAMS_STR,
  UTM_DEVOTED_ROUTE,
  UTM_DEVOTED_URL_PARAMS_STR,
  UTM_MI_DOA_ROUTE,
  UTM_MI_DOA_URL_PARAMS_STR,
  UTM_APPLE_WEB_ROUTE,
  UTM_APPLE_WEB_URL_PARAMS_STR,
  UTM_US_ROWING_ROUTE,
  UTM_US_ROWING_URL_PARAMS_STR,
  UTM_SILVER_SNEAKERS_ROUTE,
  UTM_SILVER_SNEAKERS_URL_PARAMS_STR,
  UTM_ABC_ROUTE,
  UTM_ABC_URL_PARAMS_STR,
  UTM_HEART_STUDY_ROUTE,
  UTM_HEART_STUDY_URL_PARAMS_STR,
  UTM_NIFS_ROUTE,
  UTM_NIFS_URL_PARAMS_STR,
  UTM_AAN_ROUTE,
  UTM_AAN_URL_PARAMS_STR,
  UTM_FRIEND_ROUTE,
  UTM_FRIEND_URL_PARAMS_STR,
  UTM_JNJ_ROUTE,
  UTM_JNJ_URL_PARAMS_STR,
  UTM_CVS_ROUTE,
  UTM_CVS_URL_PARAMS_STR,
  UTM_LEARN_ROUTE,
  UTM_LEARN_URL_PARAMS_STR,
  UTM_HEARTBEAT_HCP_ROUTE,
  UTM_HEARTBEAT_HCP_PARAMS_STR,
} from '../constants';

// This file handles redirecting from a vanity url to a URL which includes UTM parameters, which we then track.

// The noThrow prop in the Redirect component can be removed if you prefer to view
// Create React App’s error overlay. See: https://reach.tech/router/api/Redirect.
// Removing it shouldn't impact production behavior, despite the visible error.

const RedirectAndDefaultRoutes = props => {
  let path = props.location && props.location.pathname.toLowerCase();

  switch (path) {
    case UTM_LEARN_MORE_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_LEARN_MORE_URL_PARAMS_STR}`}
          noThrow
        />
      );
    case UTM_VISIT_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_VISIT_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_INFO_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_INFO_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_THE_TALK_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_THE_TALK_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_FACEBOOK_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_FACEBOOK_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_TWITTER_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_TWITTER_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_PINTEREST_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_PINTEREST_URL_PARAMS_STR}`}
          noThrow
        />
      );
    case UTM_LINKEDIN_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_LINKEDIN_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_EMAIL_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_EMAIL_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_LIVE_HEART_HEALTHY_ROUTE:
      return <Redirect to={`${HOME_ROUTE}?${path}`} noThrow />;
    case UTM_ADVANCE_HEART_HEALTH_ROUTE:
      return <Redirect to={`${HOME_ROUTE}?${path}`} noThrow />;
    case UTM_YMCA_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_YMCA_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_LCS_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_LCS_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_DEVOTED_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_DEVOTED_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_MI_DOA_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_MI_DOA_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_APPLE_WEB_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_APPLE_WEB_URL_PARAMS_STR}`}
          noThrow
        />
      );
    case UTM_US_ROWING_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_US_ROWING_URL_PARAMS_STR}`}
          noThrow
        />
      );
    case UTM_SILVER_SNEAKERS_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_SILVER_SNEAKERS_URL_PARAMS_STR}`}
          noThrow
        />
      );
    case UTM_ABC_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_ABC_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_HEART_STUDY_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_HEART_STUDY_URL_PARAMS_STR}`}
          noThrow
        />
      );
    case UTM_NIFS_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_NIFS_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_AAN_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_AAN_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_FRIEND_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_FRIEND_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_JNJ_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_JNJ_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_CVS_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_CVS_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_LEARN_ROUTE:
      return (
        <Redirect to={`${HOME_ROUTE}?${UTM_LEARN_URL_PARAMS_STR}`} noThrow />
      );
    case UTM_HEARTBEAT_HCP_ROUTE:
      return (
        <Redirect
          to={`${HOME_ROUTE}?${UTM_HEARTBEAT_HCP_PARAMS_STR}`}
          noThrow
        />
      );
    default:
      return <Redirect to={HOME_ROUTE} noThrow />;
  }
};

export default RedirectAndDefaultRoutes;
