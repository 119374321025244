import { rem } from 'polished';
import React from 'react';
import { Element as ScrollElement } from 'react-scroll';

import { SubHead } from '../LandingAssets';
import Grid from '../../components/grid';
import UpDownArrow from './UpDownArrow';

class QuestionAndAnswer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      open: !state.open,
    }));
  }

  render() {
    const { question, answer, scrollName } = this.props.qa;
    const { open } = this.state;

    return (
      <React.Fragment>
        <Grid.Flex
          flexWrap="wrap"
          alignItems="flex-start"
          justifyContent="space-between"
          pb={rem(0)}
          px={rem(14)}
          onClick={this.handleClick}
          className="hover--light-red-bg utils--cursor-pointer"
        >
          <Grid.Box width={4 / 5}>
            <ScrollElement name={scrollName}>
              <SubHead style={{ margin: '1rem 0' }}>{question}</SubHead>
            </ScrollElement>
          </Grid.Box>
          <Grid.Box width={1 / 5} textAlign="right">
            <UpDownArrow open={open} />
          </Grid.Box>
        </Grid.Flex>
        <Grid.Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          {open && (
            <Grid.Box width={1} px={rem(14)} py={rem(20)}>
              {answer}
            </Grid.Box>
          )}
        </Grid.Flex>
      </React.Fragment>
    );
  }
}

export default QuestionAndAnswer;
