import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { width } from 'styled-system';

import { isLargeScreenQuery } from '../breakpoint';

const StyledImage = styled.img`
  ${width}
`;

// This component defaults images we use to 2x resolution, unless the `
// `dontUse2x` parameter is set to true. You must make sure the 2x resolution image
// is available on AWS S3.
const Image = props => {
  const isLargeScreen = useMediaQuery(isLargeScreenQuery);

  if (props.dontUse2x) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <StyledImage {...props} />;
  }

  let the2xUrl;
  if (props.srcSet2xUrl) {
    the2xUrl = props.srcSet2xUrl;
  } else {
    the2xUrl = props.src.replace(/@1x/g, '@2x');

    // Insert @2x before the file extension if no resolution was present already.
    if ((the2xUrl.match(/2x/g) || []).length === 1) {
      const extensionPeriodIndex = the2xUrl.lastIndexOf('.');
      the2xUrl =
        the2xUrl.slice(0, extensionPeriodIndex) +
        '@2x' +
        the2xUrl.slice(extensionPeriodIndex);
    }
  }

  let imgSrc = props.src;
  let srcSet = `${props.src}, ${the2xUrl} 2x`;
  if (isLargeScreen) {
    imgSrc = the2xUrl;
    srcSet = '';
  }

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <StyledImage {...props} src={imgSrc} srcSet={srcSet} />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  dontUse2x: PropTypes.bool,
  srcSet2xUrl: PropTypes.string,
};

export default Image;
