import { rem } from 'polished';
import React from 'react';
import { Link } from '@reach/router';
import { Helmet } from 'react-helmet';

import {
  InnerGrid,
  Raised,
  SubHead,
  SectionHeader,
  PageHeader,
  GridStats,
  SubHeadList,
  Footnote,
  GridGroupHeader,
  GridGroupSubHeader,
} from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import Grid from '../components/grid';
import MediaQuery, { Breakpoint } from '../components/breakpoint';
import Image from '../components/images/Image';
import {
  trackPageViewForCurrentPath,
  trackGoogleAnalyticsEvent,
} from '../tracking';

import {
  AboutHeroImage1024,
  AboutHeroImage320,
  alreadyOwnAppleWatchScrollName,
  FAQ_ROUTE,
  learnMoreAppleWatchHealthUrl,
  paidToParticipateScrollName,
  technology250,
  technology280,
  whatIPhoneModelScrollName,
  urlUnderstandingMedicare,
} from '../constants';

const About = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <Wrapper>
      <Helmet>
        <title>
          Introducing the Heartline Study by Johnson & Johnson and Apple
        </title>
        <meta
          name="description"
          content="Join the study that analyzes the impact of a heart health program with Apple Watch on the early detection of irregular heart beats consistent with AFib, which is a leading cause of stroke."
        />
      </Helmet>
      <React.Fragment>
        <Raised bg="white" role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            py={[rem(20), rem(30), rem(40), rem(60)]}
          >
            <PageHeader textAlign="center">
              About the Heartline Study
            </PageHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(60)}
            >
              <Grid.Box width={[1, 8 / 9, 7 / 9, 6 / 9]} mb={rem(10)}>
                <SubHead>
                  The Heartline™ Study is a research study from Johnson &
                  Johnson in collaboration with Apple. The study aims to analyze
                  the impact of an app-based heart health program with Apple
                  Watch on the early detection of irregular heart rhythms
                  consistent with atrial fibrillation (AFib), and the potential
                  to improve heart health outcomes, including reducing the risk
                  of stroke and other cardiovascular conditions.
                </SubHead>
              </Grid.Box>
              <Grid.Box width={1} textAlign="center">
                <MediaQuery maxWidth={Breakpoint.SMALL}>
                  <Image
                    src={AboutHeroImage320}
                    alt="About Hero"
                    style={{ width: '90%' }}
                  />
                </MediaQuery>
                <MediaQuery minWidth={Breakpoint.SMALL}>
                  <Image
                    src={AboutHeroImage1024}
                    alt="About Hero"
                    style={{ width: '100%', maxWidth: rem(1024) }}
                  />
                </MediaQuery>
              </Grid.Box>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
        <Raised bg="white" role="main">
          <InnerGrid
            multiplier={11}
            px={[rem(20)]}
            pb={[rem(30), rem(10), rem(40), rem(80)]}
          >
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mb={[rem(60), rem(40), 0, 0]}
            >
              <SectionHeader>
                AFib: an important and growing health issue
              </SectionHeader>
            </Grid.Flex>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={[0, 0, rem(10), rem(-10)]}
              mb={rem(120)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead>
                  Atrial fibrillation (AFib) is a type of an irregular heart
                  rhythm or arrhythmia. It occurs when the upper chambers of the
                  heart beat out of sync with the lower chambers and this
                  creates an irregular pattern. According to the Centers for
                  Disease Control (CDC), AFib contributes to 166,000 deaths and
                  is the primary diagnosis for 454,000 hospitalizations each
                  year in the US. The condition is believed to affect 3 to 6
                  million people. It's a leading cause of stroke, but can be
                  difficult to detect because AFib is often not associated with
                  any symptoms. Early diagnosis and treatment can prevent
                  complications associated with AFib.
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="center"
              mb={[rem(60), rem(100), rem(120), rem(80)]}
            >
              <GridStats
                heading={
                  <React.Fragment>
                    <GridGroupHeader>6</GridGroupHeader>
                    <br />
                    <GridGroupSubHeader>seconds</GridGroupSubHeader>
                  </React.Fragment>
                }
                body={`Worldwide, stroke claims a life every six seconds.`}
              />
              <GridStats
                heading={
                  <React.Fragment>
                    <GridGroupHeader>5x</GridGroupHeader>
                    <br />
                    <GridGroupSubHeader>greater risk</GridGroupSubHeader>
                  </React.Fragment>
                }
                body={`AFib is the most common arrhythmia. People who have AFib are five times more likely to have a stroke, and the condition accounts for almost 33 percent of all strokes.`}
              />
              <GridStats
                last
                heading={
                  <React.Fragment>
                    <GridGroupHeader>30%</GridGroupHeader>
                    <br />
                    <GridGroupSubHeader>undiagnosed</GridGroupSubHeader>
                  </React.Fragment>
                }
                body={`Up to 30 percent of people living with AFib aren't diagnosed because AFib is often not associated with any symptoms.`}
              />
            </Grid.Flex>
          </InnerGrid>
        </Raised>
        <Raised bg="rgb(238, 244, 249)" role="main">
          <InnerGrid
            multiplier={11}
            px={[rem(20)]}
            pt={[rem(60), rem(60), rem(60), rem(120)]}
            pb={[rem(30), rem(10), rem(40), rem(40)]}
          >
            <SectionHeader>Study Details</SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(60)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead style={{ paddingBottom: '2rem' }}>
                  Heartline is a virtual, randomized, controlled research study.
                  The primary objective is to assess if everyday technology,
                  such as iPhone and Apple Watch features, with a heart health
                  engagement program, can help with early detection of AFib and
                  potentially improve clinical outcomes.
                </SubHead>
                <SubHead>Other study goals include:</SubHead>
                <SubHeadList
                  addPaddingAfterListItems
                  style={{ paddingBottom: '2rem' }}
                >
                  <li>
                    Determining the impact of a heart health engagement program
                    paired with a medication adherence intervention for
                    participants taking an oral anticoagulant medication who
                    have been previously diagnosed with AFib
                  </li>
                  <li>
                    Finding new patterns in the study data that may help to
                    identify, prevent, or treat other medical conditions beyond
                    those that involve the heart
                  </li>
                  <li>
                    Supporting other research and health-related product
                    development purposes, including development of new clinical
                    studies and improving the design of future clinical studies
                  </li>
                </SubHeadList>
                <SubHead>
                  To be eligible for this study, participants must*:
                </SubHead>
                <SubHeadList addPaddingAfterListItems>
                  <li>Be age 65 or older</li>
                  <li>
                    Be a resident of the United States for the duration of the
                    study
                  </li>
                  <li>
                    Be a user of an iPhone 6s or later, with iOS 15 or later{' '}
                    <Link
                      to={FAQ_ROUTE}
                      state={{ scrollTarget: whatIPhoneModelScrollName }}
                      onClick={() =>
                        trackGoogleAnalyticsEvent({
                          category: 'faqs',
                          action: 'click',
                          label: 'location:study_details_iPhone_model',
                        })
                      }
                    >
                      (Learn more)
                    </Link>
                  </li>
                  <li>
                    Have Medicare coverage{' '}
                    <a
                      href={urlUnderstandingMedicare}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        trackGoogleAnalyticsEvent({
                          category: 'learn_more_medicare',
                          action: 'click',
                          label: `link:${urlUnderstandingMedicare};location:study_details`,
                        })
                      }
                    >
                      (any type of Medicare plan — including Original Medicare
                      and Medicare Advantage)
                    </a>
                  </li>
                </SubHeadList>
                <SubHead>
                  For the purpose of this research study, eligible participants were randomly 
                  assigned to a group (like flipping a coin). One group participated by only 
                  using the Heartline app on their iPhone. Another group was asked to
                  obtain an Apple Watch in addition to participating using the app on their 
                  iPhone. There is also a group participating using their own Apple Watches 
                  and the Heartline app on their iPhone.
                </SubHead>
                <SubHead>
                  <Link
                    to={FAQ_ROUTE}
                    state={{ scrollTarget: alreadyOwnAppleWatchScrollName }}
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'faqs',
                        action: 'click',
                        label: 'location:study_details_already_own_apple_watch',
                      })
                    }
                  >
                    Learn more &rsaquo;
                  </Link>
                </SubHead>
                <SubHead>
                  Participants receive heart health education and wellness tips in the app 
                  each week. Depending on the participant’s study group, these points may be 
                  redeemed for $150 or more in monetary rewards. See{' '}
                  <Link
                    to={FAQ_ROUTE}
                    state={{ scrollTarget: paidToParticipateScrollName }}
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'faqs',
                        action: 'click',
                        label: 'location:study_details_monetary_rewards',
                      })
                    }
                  >
                    Frequently Asked Questions
                  </Link>{' '}
                  for further details on point redemption.
                </SubHead>
                <SubHead>
                  The Heartline Study lasts 3 years for each participant. This will 
                  consist of 2 phases. The first phase lasts 2 years, and the second 
                  phase lasts 1 year.
                </SubHead>
                <SubHeadList>
                  <li>
                    During the first phase, participants are asked to complete actions 
                    in the Heartline app, and Medicare healthcare claims and iPhone and 
                    Apple Watch data (if applicable) is collected to assess clinical outcomes.
                  </li>
                  <li>
                    During the second phase, participants are not required to complete any 
                    actions, but Medicare healthcare claims and iPhone and Apple Watch data 
                    (if applicable) will be collected to assess clinical outcomes.
                  </li>
                </SubHeadList>
                <Footnote>
                  *Key participation requirements only; other eligibility
                  participation requirements will apply. You can check your
                  eligibility in the Heartline app.
                </Footnote>
              </Grid.Box>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
        <Raised bg="white" role="main">
          <InnerGrid
            multiplier={11}
            px={[rem(20)]}
            pt={[rem(80), rem(80), rem(80), rem(120)]}
            pb={[rem(30), rem(10), rem(40), rem(40)]}
          >
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-30)}
              mb={rem(30)}
            >
              <Grid.Box width={1} textAlign="center">
                <MediaQuery maxWidth={Breakpoint.MEDIUM}>
                  <Image
                    src={technology250}
                    alt="iPhone and Apple Watch"
                    style={{ width: rem(250) }}
                  />
                </MediaQuery>
                <MediaQuery minWidth={Breakpoint.MEDIUM}>
                  <Image
                    src={technology280}
                    alt="iPhone and Apple Watch"
                    style={{ width: rem(280) }}
                  />
                </MediaQuery>
              </Grid.Box>
            </Grid.Flex>
            <SectionHeader>The Technology</SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={[rem(-20), rem(-10), rem(-10), rem(-10)]}
              mb={rem(60)}
            >
              <Grid.Box width={[8 / 9, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead>
                  All Heartline participants use the Heartline app right from their 
                  iPhone. Some participants also use an Apple Watch.
                </SubHead>
                <SubHead style={{ paddingTop: '1rem' }}>
                  <a
                    href={learnMoreAppleWatchHealthUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'learn_more_watch_heart_health',
                        action: 'click',
                        label: `link:${learnMoreAppleWatchHealthUrl};location:about_page_the_technology_bottom`,
                      })
                    }
                  >
                    Learn more about the Apple Watch and heart health &rsaquo;
                  </a>
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default About;
