import React, { useState, useEffect } from 'react';
import jsonp from 'jsonp';
import { rem } from 'polished';

import Grid from '../grid';
import { SubHead, LargeStatistic } from '../LandingAssets';
import {
  sans,
  reviews4_5StarsSmall,
  reviews5StarsSmall,
} from '../../constants';
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
  twelveHours,
} from '../../storageUtils';
import { roundToNearestHalf } from '../../mathUtils';
import Image from '../images/Image';

const AppStoreRating = () => {
  const [rating, setRating] = useState(null);
  const [numRatings, setNumRatings] = useState(null);

  useEffect(() => {
    fetchRatingInformation();
  }, []);

  const fetchRatingInformation = async () => {
    const storedAverageUserRating = getLocalStorageWithExpiry(
      'averageUserRating',
    );
    const storedNumRatings = getLocalStorageWithExpiry('userRatingCount');

    if (storedAverageUserRating && storedNumRatings) {
      setRating(storedAverageUserRating);
      setNumRatings(storedNumRatings);
      return;
    }

    // Running into CORS issues using the iTunes Search API. Using jsonp appears to resolve the issue.
    // https://stackoverflow.com/questions/29452334/how-can-i-query-the-itunes-search-api-in-javascript
    jsonp(
      'https://itunes.apple.com/lookup?id=1496449684',
      null,
      (err, data) => {
        if (err) {
          console.error(err.message);
        } else {
          const { averageUserRating, userRatingCount } = data.results[0];

          // Storing the rating and number of ratings on the user's machine for 12 hours,
          // as this information doesn't change much in the short term.
          setRating(averageUserRating);
          setLocalStorageWithExpiry(
            'averageUserRating',
            averageUserRating,
            twelveHours,
          );

          setNumRatings(userRatingCount);
          setLocalStorageWithExpiry(
            'userRatingCount',
            userRatingCount,
            twelveHours,
          );
        }
      },
    );
  };

  // Code for showing number of ratings.
  /*const displayedNumRatings = num => {
    let roundedNum;
    if (num < 10000) {
      roundedNum = Math.round(num / 100) * 100;
    } else {
      roundedNum = Math.round(num / 1000) * 1000;
    }

    return roundedNum / 1000 + 'K';
  };*/

  const starRatingImgSrc = () => {
    if (!rating) {
      return;
    }

    if (roundToNearestHalf(rating) === 5) {
      return reviews5StarsSmall;
    } else {
      return reviews4_5StarsSmall;
    }
  };

  const starRatingSrc = starRatingImgSrc();

  if (!rating || !numRatings) {
    return null;
  }

  return (
    <Grid.Flex
      flexWrap="wrap"
      alignItems="center"
      justifyContent="left"
      mt={[rem(60), rem(60), 0, 0]}
      mb={[rem(30), rem(20), 0, 0]}
    >
      <Grid.Box width={[1 / 2, 1, 1, 1]}>
        <Grid.Flex flexWrap="wrap" alignItems="center" justifyContent="left">
          <Grid.Box width={1}>
            <LargeStatistic mb={0} style={{ display: 'inline-block' }}>
              {rating && rating.toFixed(1)}
            </LargeStatistic>{' '}
            <SubHead
              mb={0}
              fontFamily={sans}
              style={{ display: 'inline-block' }}
              bold
            >
              out of 5
            </SubHead>
          </Grid.Box>
          <Grid.Box width={1}>
            {starRatingSrc && (
              <Image src={starRatingSrc} alt="App store rating" />
            )}
          </Grid.Box>
        </Grid.Flex>
      </Grid.Box>
      {/*<Grid.Box
        width={[1 / 2, 1, 1, 1]}
        textAlign={['right', 'left', 'left', 'left']}
      >
        <SidebarItem color={lessDarkGrayTextColor}>
          {displayedNumRatings(numRatings)} ratings
        </SidebarItem>
      </Grid.Box>*/}
    </Grid.Flex>
  );
};

export default AppStoreRating;
