import React from 'react';
import { Link } from '@reach/router';

import Grid from '../grid';
import { SubHead } from '../LandingAssets';

const ContactUsCTA = props => {
  const { path, questionText = 'Questions or Comments?' } = props;

  return (
    <Grid.Box width={[1, 3 / 4, 1 / 2, 2 / 5]} style={{ ...props.style }}>
      <Grid.Flex flexWrap="wrap" alignItems="center" justifyContent="center">
        <Grid.Box width={[1 / 2, 3 / 5, 3 / 5, 3 / 5]}>
          <SubHead>{questionText}</SubHead>
        </Grid.Box>
        <Grid.Box width={[1 / 2, 2 / 5, 2 / 5, 2 / 5]}>
          <Link to={path}>
            <button>Contact Us</button>
          </Link>
        </Grid.Box>
      </Grid.Flex>
    </Grid.Box>
  );
};

export default ContactUsCTA;
