import React from 'react';
import { rem } from 'polished';

import Grid from '../grid';
import { InnerGrid } from '../LandingAssets';
import HomeHeroTextContainer from './HomeHeroTextContainer';
import SocialButtonsCollection from '../social_media/SocialButtonsCollection';
import MediaQuery, { Breakpoint } from '../breakpoint';
import { technology250, technology280 } from '../../constants';
import Image from '../images/Image';

const SHOW_SOCIAL_BUTTONS = false;

const HomeHero = () => {
  const imageContainer = (
    <Grid.Box width={[1, 1, 2 / 5, 2 / 5]} mb={[rem(20), rem(20), 0, 0]}>
      <Grid.Flex>
        <Grid.Box width={1} textAlign="center">
          <MediaQuery maxWidth={Breakpoint.MEDIUM}>
            <Image
              src={technology250}
              alt="iPhone and Apple Watch"
              width={['80%', '80%', '100%', '100%']}
            />
          </MediaQuery>
          <MediaQuery minWidth={Breakpoint.MEDIUM}>
            <Image
              src={technology280}
              alt="iPhone and Apple Watch"
              width={['80%', '80%', '100%', '100%']}
            />
          </MediaQuery>
        </Grid.Box>
        {SHOW_SOCIAL_BUTTONS && (
          <SocialButtonsCollection
            vertical
            placementIdentifierForAnalytics={'homeHero'}
          />
        )}
      </Grid.Flex>
    </Grid.Box>
  );

  return (
    <InnerGrid
      multiplier={11}
      px={[rem(20), rem(20), rem(20), rem(20)]}
      pt={[0, 0, rem(10), rem(40)]}
      pb={rem(60)}
    >
      <Grid.Flex
        flexWrap="wrap"
        alignItems="center"
        my={[rem(0), rem(10), rem(40), rem(60)]}
      >
        <HomeHeroTextContainer />
        {imageContainer}
      </Grid.Flex>
      {SHOW_SOCIAL_BUTTONS && (
        <MediaQuery maxWidth={Breakpoint.MEDIUM}>
          <Grid.Flex alignItems="center" justifyContent="center" mt={rem(20)}>
            <SocialButtonsCollection
              placementIdentifierForAnalytics={'footer'}
            />
          </Grid.Flex>
        </MediaQuery>
      )}
    </InnerGrid>
  );
};

export default HomeHero;
