import { rem } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { Breakpoint, getBreakpoint } from './breakpoint';
import {
  sans,
  serif,
  darkGrayTextColor,
  inputPlaceholderColor,
  inputTextColor,
  inputFontSize,
  inputLineHeight,
  inputLetterSpacing,
  inputMarginBottom,
  disabledGrayBackgroundColor,
  disabledGrayBorderColor,
} from '../constants';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "LL Circular Black Web";
    src: url("fonts/lineto-circular-black.eot");
    src: url("fonts/lineto-circular-black.eot?#iefix") format("embedded-opentype"),
        url("fonts/lineto-circular-black.woff2") format("woff2"),
        url("fonts/lineto-circular-black.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "LL Circular Bold Web";
    src: url("fonts/lineto-circular-bold.eot");
    src: url("fonts/lineto-circular-bold.eot?#iefix") format("embedded-opentype"),
        url("fonts/lineto-circular-bold.woff2") format("woff2"),
        url("fonts/lineto-circular-bold.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "LL Circular Book Web";
    src: url("fonts/lineto-circular-book.eot");
    src: url("fonts/lineto-circular-book.eot?#iefix") format("embedded-opentype"),
        url("fonts/lineto-circular-book.woff2") format("woff2"),
        url("fonts/lineto-circular-book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }

  :root {
    font-size: 100%;
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: ${() => sans};
    background-color: #fff;
    margin: 0;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #010B19;
    margin-top: 0;
  }

  h1 {
    font-family: ${() => serif};
    font-weight: 600;
    font-size: ${rem(55)};
    color: #010B19;
    letter-spacing: ${rem(-0.66)};
    line-height: ${rem(51)};

    @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      font-size: ${rem(45)};
    }
  

    span {
      text-transform: uppercase
    }

    sup {
      font-size: 50%;
      font-weight: normal;
      font-family: ${() => sans};
    }
  }

  h2 {
    font-family: "LL Circular Bold Web";
    font-size: ${rem(24)};
    text-transform: uppercase;
    margin-bottom: ${rem(45)}

    @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      font-size: ${rem(20)};
    }
  }

  h3 {
    font-family: 'LL Circular Black Web';
    font-size: ${rem(34)};
    letter-spacing: ${rem(-0.48)};
    margin-bottom: ${rem(10)};
    font-size: ${rem(34)};

    @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      font-size: ${rem(25)};
    }
  }

  h4 {
    font-family: ${() => serif};
    font-weight: 600;
    font-size: ${rem(23)};
    color: #010B19;
    letter-spacing: ${rem(-0.32)};
    line-height: ${rem(34)};
    margin-bottom: ${rem(55)};

    @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      font-size: ${rem(20)};
    }

  }

  h5 {
    font-size: ${rem(28)};
    margin-bottom: 0;
  }

  ol,
  p,
  ul {
    margin-top: 0;
  }

  p,
  li {
    font-family: 'LL Circular Book Web', sans-serif;
    font-size: ${rem(16)}
    line-height: ${rem(28)};
    color: #030b18;
  }

  a {
    color: #040c19;
    text-decoration: none;
    cursor: pointer;
  }

  svg {
    display: block;
  }

  header {
    p {
      margin: 0;
      font-size: ${rem(17)};
    }

    a, .header-link {
      font-family: 'Helvetica-Bold', 'LL Circular Book Web', sans-serif;
      color: rgb(62, 65, 86);
      text-decoration: none;
      font-weight: bold;
      letter-spacing: 0.3px;
  
      &:hover {
        text-decoration: underline;
      }
    }

    .header-link {
      cursor: pointer;
    }
  
  }

  small {
    font-size: 70%;
  }

  footer {
    p {
      line-height: 1em;
      color: #040c19;
    }

  }

  hr {
   border: ${rem(0.5)} solid rgb(177, 179, 187);
  }

  input, button, textarea {
    width: 100%;
    height: 52px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-family: ${() => sans};
    font-size: ${() => inputFontSize};
    letter-spacing: ${() => inputLetterSpacing};
    line-height: ${() => inputLineHeight};
  }

  input, textarea {
    margin-bottom: ${() => inputMarginBottom};
    border: 0.5px solid rgb(151, 151, 151, 0.5);
    padding-left: 20px;
    color: ${() => inputTextColor};

    ::placeholder {
      color: ${() => inputPlaceholderColor};
    }

    :focus::-webkit-input-placeholder,
    :focus:-moz-placeholder,
    :focus::-moz-placeholder,
    :focus:-ms-input-placeholder {
      color: ${() => darkGrayTextColor};
    }

    &:disabled {
      background-color: ${() => disabledGrayBackgroundColor};
      border-color: ${() => disabledGrayBorderColor};
    }
  }

  textarea {
    padding-top: 14px;
  }

  input.error, textarea.error, .select-dropdown.error > div {
    border: 0.5px solid red;
  }

  button {
    color: white;
    font-weight: bold;
    background-color: rgb(62, 65, 86);
    cursor: pointer;
    margin-bottom: 1.5rem;

    &:disabled {
      cursor: not-allowed;
      background-color: rgb(62, 65, 86, 0.4);
    }
  }

  .bold-text {
    color: rgb(62, 65, 86);
    font-family: Helvetica-Bold;
    font-size: ${rem(17)};
    font-weight: bold;
    height: ${rem(22)};
    letter-spacing: ${rem(0.3)};
    line-height: ${rem(22)};
  }

  .padding-top-small {
    padding-top: 1.6rem;
  }

  .padding-bottom-small {
    padding-bottom: 1.6rem;
  }

  .text-color--black {
    color: black;
  }

  .text-color--white {
    color: white;
  }

  .hero-text-container {
    width: 93%;
  }

  .hover--light-red-bg {
    border-radius: ${rem(3)};
    &:hover {
      background-color: rgb(253, 238, 237);
    }
  }

  .utils--bold {
    font-weight: bold;
  }

  .validation-errors {
      color: red;
  }

  .utils--cursor-pointer {
    cursor: pointer;
  }

  .ReactModal__Content {
    // Purposely using !important to override react-modal's inline-styles.
    @media screen and (max-width: ${getBreakpoint(Breakpoint.SMALL)}) {
      width: 95%;
      padding: ${rem(14)} !important;
    }
    @media screen and (min-width: ${getBreakpoint(
      Breakpoint.SMALL,
    )}) and (max-width: ${getBreakpoint(Breakpoint.MEDIUM)}) {
      width: 80%;
      padding: ${rem(40)} !important;
    }
    @media screen and (min-width: ${getBreakpoint(
      Breakpoint.MEDIUM,
    )}) and (max-width: ${getBreakpoint(Breakpoint.LARGE)}) {
      width: 60%;
      padding: ${rem(48)} !important;
    }
    @media screen and (min-width: ${getBreakpoint(Breakpoint.LARGE)}) {
      width: 40%;
      padding: ${rem(48)} !important;
    }
  }
`;

export default GlobalStyle;
