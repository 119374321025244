import { rem } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';

import {
  InnerGrid,
  Raised,
  SubHead,
  SectionHeader,
  TextGroupHeader,
} from '../components/LandingAssets';
import Wrapper from '../components/Wrapper';
import Grid from '../components/grid';
import ForProvidersHero from '../components/for_providers/ForProvidersHero';
import ContactUsCTA from '../components/footer/ContactUsCTA';
import {
  trackPageViewForCurrentPath,
  trackGoogleAnalyticsEvent,
} from '../tracking';

import {
  yellowBgColor,
  grayBackground,
  whiteBgColor,
  CONTACT_US_ROUTE,
  HCP_SUBROUTE,
  FAQ_ROUTE,
  forHCProvidersHeaderScrollName,
  hcpStudyMaterialsPDF,
  hcpStudyMaterialsPDFFilename,
  mHealthStudyUrl,
  appleHeartStudyUrl,
  afibScreeningStudyUrl,
  learnMoreAppleWatchHealthUrl,
} from '../constants';

const ForProviders = () => {
  React.useEffect(() => {
    trackPageViewForCurrentPath();
  });

  return (
    <Wrapper>
      <Helmet>
        <title>
          Healthcare Providers: Learn More about the Heartline Study{' '}
        </title>
        <meta
          name="description"
          content="The Heartline Study is a pragmatic randomized controlled virtual research study. If you have patients who might be interested in enrolling, click to learn more and download informational materials."
        />
      </Helmet>
      <React.Fragment>
        <Raised bg={yellowBgColor} role="main">
          <ForProvidersHero />
        </Raised>
        <Raised bg={grayBackground} role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            pt={[rem(60), rem(60), rem(70), rem(70)]}
            pb={rem(10)}
          >
            <SectionHeader>The Technology</SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(60)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead>
                  All Heartline participants use the Heartline app right from
                  their iPhone. Some participants also use an Apple Watch.
                </SubHead>
                <SubHead>
                  Participants wearing an Apple Watch may receive an irregular
                  rhythm notification (IRN) that indicates that the participant
                  had an irregular heart rhythm suggestive of AFib. If
                  participants receive an IRN, the Heartline app prompts them to
                  complete an ECG on their Apple Watch. Patients are recommended
                  to share their ECG reading and details of their IRN with
                  providers through the iPhone Health app.
                </SubHead>
                <SubHead style={{ padding: '1rem 0' }}>
                  <a
                    href={learnMoreAppleWatchHealthUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'learn_more_watch_heart_health',
                        action: 'click',
                        label: `link:${learnMoreAppleWatchHealthUrl};location:hcp_the_technology_bottom`,
                      })
                    }
                  >
                    Learn more about the heart health features on Apple Watch
                    &rsaquo;
                  </a>
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
          </InnerGrid>
        </Raised>
        <Raised bg={whiteBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            py={[rem(30), rem(10), rem(40), rem(80)]}
          >
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(80)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <TextGroupHeader lineHeight={32} style={{ paddingTop: '2rem' }}>
                  What to do if your patient comes in with an irregular rhythm
                  notification (IRN)
                </TextGroupHeader>
                <SubHead>
                  Participants wearing an Apple Watch may receive an (IRN) that
                  indicates an irregular heart rhythm suggestive of AFib.
                </SubHead>
                <SubHead>
                  If participants receive an IRN, the Heartline app prompts them
                  to complete an ECG on their Apple Watch. This ECG is similar
                  to a Lead I ECG.
                </SubHead>
                <SubHead>
                  Patients can share their ECG reading and details of their IRN
                  with providers through the iPhone Health app.
                </SubHead>
                <SubHead>
                  This study is not designed nor intended to direct medical
                  care; physicians should continue to provide care to study
                  participants as physicians determine is clinically indicated.
                </SubHead>
                <SubHead style={{ padding: '1rem 0 0' }}>
                  <Link
                    className="bold-text padding-top-small"
                    to={FAQ_ROUTE}
                    state={{ scrollTarget: forHCProvidersHeaderScrollName }}
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'faqs',
                        action: 'click',
                        label: 'location:heartline_and_your_patients',
                      })
                    }
                  >
                    Learn more &rsaquo;
                  </Link>
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
            <hr />
          </InnerGrid>
        </Raised>
        <Raised bg={whiteBgColor} role="main">
          <InnerGrid
            multiplier={11}
            px={rem(20)}
            pb={[rem(30), rem(10), rem(40), rem(80)]}
          >
            <SectionHeader>Additional Resources</SectionHeader>
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
              mt={rem(-10)}
              mb={rem(80)}
            >
              <Grid.Box width={[1, 8 / 9, 6 / 9, 5 / 9]}>
                <SubHead>
                  The Heartline Study is the next step in a series of clinical
                  studies sponsored by Johnson & Johnson and Apple. Johnson &
                  Johnson and the Scripps Research Translational Institute
                  showed in the mSToPs trial that a wearable patch can increase
                  AFib detection in an asymptomatic patient population. Data
                  from the Apple Heart Study, conducted by Apple in
                  collaboration with researchers from the Stanford University
                  School of Medicine and other researchers, demonstrated that a
                  feature on Apple Watch can detect irregular heart rhythms such
                  as AFib. This new study should provide additional evidence on
                  the impact of routine AFib screening.
                </SubHead>
                <SubHead style={{ padding: '1rem 0 0' }}>
                  <a
                    href={mHealthStudyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'additional_resources',
                        action: 'click',
                        label: mHealthStudyUrl,
                      })
                    }
                  >
                    mHealth Screening To Prevent Strokes Trial (mSToPs) &rsaquo;
                  </a>
                </SubHead>
                <SubHead style={{ padding: '1rem 0 0' }}>
                  <a
                    href={appleHeartStudyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'additional_resources',
                        action: 'click',
                        label: appleHeartStudyUrl,
                      })
                    }
                  >
                    Apple Heart Study &rsaquo;
                  </a>
                </SubHead>
                <SubHead style={{ padding: '1rem 0' }}>
                  <a
                    href={afibScreeningStudyUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'additional_resources',
                        action: 'click',
                        label: afibScreeningStudyUrl,
                      })
                    }
                  >
                    Screening for Atrial Fibrillation White Paper &rsaquo;
                  </a>
                </SubHead>
                <SubHead>
                  Looking for additional information and resources? Please take
                  a look at our study materials.
                </SubHead>
                <SubHead style={{ padding: '1rem 0 0' }}>
                  <a
                    href={hcpStudyMaterialsPDF}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() =>
                      trackGoogleAnalyticsEvent({
                        category: 'downloads',
                        action: 'click',
                        label: `file:${hcpStudyMaterialsPDFFilename};location:additional_resources`,
                      })
                    }
                  >
                    Download study materials &rsaquo;
                  </a>
                </SubHead>
              </Grid.Box>
            </Grid.Flex>
            <hr />
            <Grid.Flex
              flexWrap="wrap"
              alignItems="center"
              justifyContent="flex-end"
              mt={rem(42)}
              mb={[rem(100), rem(40), rem(10), rem(0)]}
            >
              <ContactUsCTA path={`${CONTACT_US_ROUTE}${HCP_SUBROUTE}`} />
            </Grid.Flex>
          </InnerGrid>
        </Raised>
      </React.Fragment>
    </Wrapper>
  );
};

export default ForProviders;
