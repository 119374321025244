import React, { Component } from 'react';

import CheckConfirmation from '../assets/check-confirmation';
import { HiddenPagesCss } from '../constants';
import { InnerGrid } from '../components/LandingAssets';
import _ from 'lodash';
import axios from 'axios';
import queryString from 'query-string';
import { rem } from 'polished';
import { wrapComponent } from '../utils';
import { trackPageViewForCurrentPath } from '../tracking';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSubmitted: false,
      submitting: false,
      success: false,
      error: false,
      password: '',
      confirm_password: '',
      validationErrors: [],
    };

    this.passwordChanged = this.passwordChanged.bind(this);
    this.confirmPasswordChanged = this.confirmPasswordChanged.bind(this);
    this.submitPassword = this.submitPassword.bind(this);
  }

  componentDidMount() {
    trackPageViewForCurrentPath();
  }

  passwordChanged(e) {
    this.setState({
      password: e.target.value,
      validationErrors: [],
    });
  }

  confirmPasswordChanged(e) {
    this.setState({
      confirm_password: e.target.value,
      validationErrors: [],
    });
  }

  runValidations() {
    if (this.state.password !== this.state.confirm_password) {
      return ['Passwords do not match'];
    }

    const MIN_PASSWORD_LENGTH = 8;
    if (this.state.password.length < MIN_PASSWORD_LENGTH) {
      return ['Password must be at least 8 characters.'];
    }

    if (!this.hasUpperCase(this.state.password)) {
      return ['Password must have at least 1 uppercase character.'];
    }

    if (!this.hasSpecialCharacter(this.state.password)) {
      return ['Password must include a special character.'];
    }

    if (!this.hasNumber(this.state.password)) {
      return ['Password must include a number.'];
    }

    if (this.hasSpaces(this.state.password)) {
      return ['Password cannot include spaces.'];
    }
  }

  hasUpperCase(str) {
    // Doing it to way since it appears to be inclusive of non-English characters.
    return str.toLowerCase() !== str;
  }

  hasNumber(str) {
    return /\d/.test(str);
  }

  hasSpecialCharacter(str) {
    return /(?=.*\W)/.test(str);
  }

  hasSpaces(str) {
    return /\s/.test(str);
  }

  async submitPassword(e) {
    e.preventDefault();

    const validationErrors = this.runValidations();
    if (!_.isEmpty(validationErrors)) {
      this.setState({
        validationErrors,
      });
      return;
    }

    const queryStringValues = queryString.parse(this.props.location.search);
    const token = queryStringValues.token;

    this.setState({
      submitting: true,
    });
    try {
      const response = await axios.put('/user/reset_password', {
        token,
        password: this.state.password,
      });

      if (response.status === 200) {
        this.setState({
          success: response.data.success,
        });
      }
    } catch {
      this.setState({
        error: true,
      });
    } finally {
      this.setState({
        hasSubmitted: true,
        submitting: false,
      });
    }
  }

  textAndImageToDisplay() {
    if (this.state.error) {
      return {
        header: 'Oops! There was a problem.',
        subheader: 'Please try refreshing, or try again in a few minutes.',
      };
    }

    if (this.state.success) {
      return {
        header: 'Your password has been updated',
        subheader: 'Sign in to the Heartline app again to continue.',
        image: <CheckConfirmation style={{ marginTop: '-1.3rem' }} />,
      };
    }
  }

  render() {
    const contentToDisplay = this.textAndImageToDisplay();

    return (
      <React.Fragment>
        <HiddenPagesCss />
        <section role="main">
          <InnerGrid
            multiplier={4}
            px={rem(10)}
            my={
              this.state.error
                ? [rem(100), rem(150), rem(175)]
                : [rem(40), rem(60), rem(70)]
            }
          >
            {!this.state.hasSubmitted && !this.state.error && (
              <React.Fragment>
                <h4 className="status-header">Reset Password</h4>
                <div className="subheader">
                  <ul>
                    <li>• Minimum 8 characters</li>
                    <li>• 1 uppercase letter</li>
                    <li>• 1 special character</li>
                    <li>• 1 number</li>
                  </ul>
                </div>
                <p className="validation-errors">
                  {this.state.validationErrors}
                </p>
                <form onSubmit={this.submitPassword}>
                  <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    placeholder="Enter a new password"
                    onChange={this.passwordChanged}
                  />
                  <input
                    type="password"
                    name="confirm_password"
                    value={this.state.confirm_password}
                    placeholder="Re-enter the new password"
                    onChange={this.confirmPasswordChanged}
                  />
                  <button
                    type="submit"
                    disabled={
                      !this.state.password ||
                      !this.state.confirm_password ||
                      this.state.submitting
                    }
                  >
                    Submit
                  </button>
                </form>
              </React.Fragment>
            )}
            {(this.state.hasSubmitted || this.state.error) && (
              <React.Fragment>
                {contentToDisplay.image}
                <h4 className="status-header">{contentToDisplay.header}</h4>
                <p>{contentToDisplay.subheader}</p>
              </React.Fragment>
            )}
          </InnerGrid>
        </section>
      </React.Fragment>
    );
  }
}

export default wrapComponent(ResetPassword);
