import React from 'react';
import { Link } from '@reach/router';

import { SubHead, SubHeadList } from '../LandingAssets';
import { PROVIDERS_ROUTE } from '../../constants';

export const forHCProvidersHeaderText =
  'Frequently Asked Questions for Healthcare Providers';
export const forHCProvidersQuestions = [
  {
    question: 'Why is this research study being conducted?',
    answer: (
      <React.Fragment>
        <SubHead>
          Heartline is the next step in a series of clinical studies that
          Johnson & Johnson and/or Apple have conducted to help understand
          whether wearable technologies, such as the Apple Watch, can aid in
          early detection of irregular heart rhythms and atrial fibrillation
          (AFib), as well as potentially improve clinical outcomes, such as
          reducing the risk of stroke.
        </SubHead>
        <SubHead>
          <a
            href="https://jamanetwork.com/journals/jama/fullarticle/2687353"
            target="_blank"
            rel="noopener noreferrer"
          >
            mSToPs
          </a>{' '}
          (mHealth Screening to Prevent Strokes), conducted by Scripps Research
          Translational Institute, funded by Johnson & Johnson and the National
          Institutes of Health (NIH), and in collaboration with Aetna and
          iRhythm Technologies, Inc., found that a wearable continuous ECG
          monitoring patch can identify people with asymptomatic AFib earlier
          and more efficiently than routine care. The ECG monitoring patch also
          helped to lower the numbers of emergency room visits and
          hospitalizations by shifting patients to outpatient cardiology
          settings.
        </SubHead>
        <SubHead>
          Preliminary data from the{' '}
          <a
            href="https://www.sciencedirect.com/science/article/pii/S0002870318302710?via%3Dihub"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Heart Study
          </a>{' '}
          conducted by Apple in collaboration with researchers from the Stanford
          University School of Medicine and other researchers, demonstrated that
          the Apple Watch algorithms can detect irregular heart rhythms such as
          AFib.
        </SubHead>
        <SubHead>
          If you would like additional information or materials for potential
          participants, please visit our{' '}
          <Link to={PROVIDERS_ROUTE}>Healthcare Provider page</Link>.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'How are you collecting Medicare information?',
    answer: (
      <React.Fragment>
        <SubHead>
          To qualify for this study, participants must be enrolled in Medicare
          and agree to share their Medicare healthcare claims data. Data sharing
          depends on the type of Medicare (Original Medicare or Medicare
          Advantage).
        </SubHead>
        <SubHead>
          All participants with coverage through Original Medicare, authorize
          the release of Medicare healthcare claims data through the Blue Button
          2.0 authorization process through MyMedicare.gov. Participants
          enrolled in a Medicare Advantage Plan, also complete an additional
          authorization process to authorize the release of data by their
          Medicare Advantage Insurance Provider. The Heartline app guides
          participants through the processes required based on their insurance
          coverage.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question:
      'What are the irregular heart rhythm notifications (IRN) my patient is getting from the Apple Watch?',
    answer: (
      <React.Fragment>
        <SubHead>
          Participants wearing an Apple Watch may receive an irregular rhythm
          notification (IRN) that suggests a patient had an irregular heart
          rhythm consistent with AFib.
        </SubHead>
        <SubHead>
          If participants receive an irregular rhythm notification, the
          Heartline app prompts them to complete an ECG on their Apple Watch.
          Patients can share their ECG reading and details of their IRN with
          providers through the iPhone Health app.
        </SubHead>
        <SubHead>
          Want to learn more? Visit{' '}
          <a
            href="https://support.apple.com/en-us/HT208931"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Support
          </a>{' '}
          for details.
        </SubHead>
      </React.Fragment>
    ),
  },
  {
    question: 'What is the ECG report my patient has shown me?',
    answer: (
      <React.Fragment>
        <SubHead>
          The ECG app on Apple Watch Series 4 or later generates an ECG that is
          similar to a single-lead (or Lead I) ECG. The ECG app will provide one
          of the following
        </SubHead>
        <SubHeadList>
          <li>Sinus rhythm: Sinus rhythm between 50-100 BPM</li>
          <li>Atrial fibrillation: AFib between 50-120 BPM</li>
          <li>
            Low or high heartrate: A heart rate under 50 BPM or over 120 BPM
            affects the ECG app’s ability to check for AFib, and the recording
            is considered inconclusive
          </li>
          <li>
            Inconclusive: An inconclusive result means the recording can’t be
            classified. This can happen for many reasons, such as not resting
            your arms on a table during a recording, or wearing your Apple Watch
            too loose
          </li>
        </SubHeadList>
        <SubHead>
          Want to learn more? Visit{' '}
          <a
            href="https://support.apple.com/en-us/HT208955"
            target="_blank"
            rel="noopener noreferrer"
          >
            Apple Support
          </a>{' '}
          for details.
        </SubHead>
      </React.Fragment>
    ),
  },
];
